<vex-page-layout mode="card">

    <vex-page-layout-header class="pb-16 flex flex-col items-start justify-center">
        <div [class.container]="layoutCtrl.value === 'boxed'"
             [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
             class="w-full flex flex-col sm:flex-row justify-between">
            <div>
                <h1 class="title mt-0 mb-1">{{ "MENUS.HOURS_INSERT" | translate }}</h1>
                <vex-breadcrumbs
                        [crumbs]="['MENUS.ADMINISTRACION', 'MENUS.HOURS_INSERT']"></vex-breadcrumbs>
            </div>

        </div>
    </vex-page-layout-header>

    <vex-page-layout-content class="-mt-6">

        <div>
            <form [formGroup]="searchForm">
                <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">

                    <div class="bg-foreground rounded-full px-4 h-10 max-w-[500px] flex-auto flex items-center border border-gray-300">

                        <input type="text"
                               aria-label="Number"
                               matInput
                               formControlName="user"

                               placeholder="{{ 'USUARIO.SELECCIONAR' | translate }}"
                               [matAutocomplete]="autoUser"
                               style="width: 100% !important;">

                        <mat-autocomplete #autoUser="matAutocomplete" [displayWith]="displayUserFn"
                                          (optionSelected)="onSelectionUserChange($event)">
                            <mat-option *ngFor="let option of filteredUserList | async" [value]="option">
                                {{ option.name }}
                            </mat-option>
                        </mat-autocomplete>

                        <button type="button"  matSuffix mat-icon-button
                                aria-label="Clear" (click)="resetUser() ">
                            <mat-icon>close</mat-icon>
                        </button>

                    </div>


                    <button
                            class="ml-4 flex-none"
                            color="primary"
                            mat-mini-fab
                            [matTooltip]="'ACTIONS.BUSCAR_USUARIOS' | translate "
                            type="button"
                            (click)="buscar()">
                        <mat-icon svgIcon="mat:search"></mat-icon>
                    </button>

                    <span class="flex-1"></span>

                    <button
                            class="ml-4 flex-none"
                            color="primary"
                            mat-mini-fab
                            [matTooltip]="'ACTIONS.AGREGAR_HORAS' | translate "
                            (click)="create()"
                            type="button">
                        <mat-icon svgIcon="mat:add"></mat-icon>
                    </button>



                </div>
            </form>

            <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>

                <!-- Text Columns -->
                <ng-container *ngFor="let column of columns; trackBy: trackByProperty">

                    <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
                        <th *matHeaderCellDef class="uppercase" mat-header-cell
                            mat-sort-header> {{ column.label | translate }}</th>
                        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] }}</td>
                    </ng-container>

                </ng-container>

                <ng-container matColumnDef="actions">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
                    <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
                        <button (click)="$event.stopPropagation()"
                                [matMenuTriggerData]="{ user: row }"
                                [matMenuTriggerFor]="actionsMenu"
                                mat-icon-button
                                type="button">
                            <mat-icon svgIcon="mat:more_horiz"></mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
                <tr (click)="updateProject(row)"
                    *matRowDef="let row; columns: visibleColumns;"
                    @fadeInUp
                    class="hover:bg-hover trans-ease-out cursor-pointer"
                    mat-row></tr>

            </table>

            <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" (page)="pageChanged($event)"
                           class="sticky left-0"></mat-paginator>

        </div>

    </vex-page-layout-content>


</vex-page-layout>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
    <ng-template let-user="user" matMenuContent>
        <button (click)="updateProject(user)" mat-menu-item>
            <mat-icon svgIcon="mat:edit"></mat-icon>
            <span>{{ "ACTIONS.EDITAR" | translate }}</span>
        </button>
        <button (click)="deleteProject(user)" mat-menu-item>
            <mat-icon svgIcon="mat:delete"></mat-icon>
            <span>{{ "ACTIONS.ELIMINAR" | translate }}</span>
        </button>
    </ng-template>
</mat-menu>
