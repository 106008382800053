import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'vex-dialog', template: `
    <div mat-dialog-title class="flex items-center justify-between">
      <div>{{ title }}</div>
      <button type="button" mat-icon-button (click)="close(false)" tabindex="-1">
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>

    <mat-dialog-content>
      <p>{{ message }}</p>
    </mat-dialog-content>


    <mat-dialog-actions align="end">
      <button mat-button (click)="close(false)">{{ "LABELS.CANCELAR" | translate }}</button>
      <button mat-button color="primary" (click)="close(true)">{{ "LABELS.ACEPTAR" | translate }}</button>
    </mat-dialog-actions>
  `
})
export class DialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<DialogComponent>,
              @Inject(MAT_DIALOG_DATA) public defaults: any,) {

    this.title = this.defaults.title;
    this.message = this.defaults.message;
  }

  close(answer: boolean) {
    this.dialogRef.close(answer);
  }

  ngOnInit(): void {
  }

  protected readonly title;
  protected readonly message;
}
