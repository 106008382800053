import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Project, ProjectsData} from "@core/services/backend/common/interface/projects";
import {ProjectsApi} from "@core/services/backend/common/api/projects.api";
import {Responsible, ResponsibleData} from "@core/services/backend/common/interface/responsible";
import {ResponsibleApi} from "@core/services/backend/common/api/responsible.api";


@Injectable()
export class ResponsibleService extends ResponsibleData {

    constructor(private api: ResponsibleApi) {
        super();
    }

    list(search: string, pageNumber: number, pageSize: number, orderBy: string): Observable<any[]> {
        return this.api.list(search, pageNumber, pageSize, orderBy);
    }

    get(id: number): Observable<Responsible> {
        return this.api.get(id);
    }

    // tslint:disable-next-line:no-shadowed-variable
    create(responsible: any): Observable<Responsible> {
        return this.api.add(responsible);
    }

    // tslint:disable-next-line:no-shadowed-variable
    update(responsible: any): Observable<Responsible> {
        return this.api.update(responsible);
    }

    delete(responsible: any): Observable<Responsible> {
        return this.api.delete(responsible);
    }

}
