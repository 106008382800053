<div #originRef
     (click)="showPopover(originRef)"
     [class.bg-hover]="dropdownOpen"
     class="flex items-center rounded cursor-pointer  trans-ease-out select-none py-1 pr-1 pl-3"
     matRipple>

    <div class="vex-sidenav-user__content flex-auto mr-3 ml-3">
        <div class="vex-sidenav-user__title body-1 font-bold leading-snug ltr:mr-3 rtl:ml-3 hidden sm:block">{{ activeUser }}</div>
        <div class="vex-sidenav-user__subtitle font-normal " >{{ "ROLES." + activeRole | translate }}</div>
    </div>

<!--    <div class="body-1 font-semibold leading-snug ltr:mr-3 rtl:ml-3 hidden sm:block">{{ activeUser }}</div>-->
<!--    <div>Admin</div>-->
    <div class="rounded-full h-9 w-9 flex items-center justify-center text-primary bg-primary/10">
        <mat-icon svgIcon="mat:person"></mat-icon>
    </div>
</div>
