import {Observable} from 'rxjs';

export interface Role {
    name: string;
    id: number;
}

export abstract class RoleData {

    abstract list(search: string, pageNumber: number, pageSize: number, orderBy: string): Observable<any>;

    abstract get(id: number, cloudId: number): Observable<Role>;

    abstract update(user: Role): Observable<Role>;

    abstract create(user: Role): Observable<Role>;

    abstract delete(user: Role): Observable<Role>;

}
