import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class JWTTokenService {

    jwtToken: string;
    decodedToken: { [key: string]: string };

    constructor() {
    }

    setToken(token: string) {
        if (token) {
            this.jwtToken = token;
            this.decodeToken();
        }
    }

    decodeToken() {
        if (this.jwtToken) {
            this.decodedToken = jwt_decode(this.jwtToken);
            // console.log(this.decodedToken);
        }
    }

    getDecodeToken() {
        return jwt_decode(this.jwtToken);
    }

    getUser() {
        this.decodeToken();
        return this.decodedToken ? this.decodedToken.firstname + ' ' + this.decodedToken.lastname : null;
    }

    getUserId() {
        this.decodeToken();
        return this.decodedToken ? this.decodedToken.id : null;
    }

    getRole() {
        this.decodeToken();
        return this.decodedToken ? this.decodedToken.Role : null;
    }

    getEmailId() {
        this.decodeToken();
        return this.decodedToken ? this.decodedToken.email : null;
    }

    getExpiryTime() {
        this.decodeToken();
        return this.decodedToken ? this.decodedToken.exp : null;
    }

    getUserGroups() {
        this.decodeToken();
        return this.decodedToken ? this.decodedToken.Groups : null;
    }

    getUserData() {
        this.decodeToken();
        return this.decodedToken ? this.decodedToken.userdata : null;
    }

    isTokenExpired(): boolean {
        const expiryTime: string = this.getExpiryTime();
        if (expiryTime) {
            return ((1000 * parseFloat(expiryTime)) - (new Date()).getTime()) < 5000;
        } else {
            return false;
        }
    }

}
