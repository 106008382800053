import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, UntypedFormBuilder} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {LinksService} from "@core/services/backend/common/service/links.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {User} from "@core/services/backend/common/interface/users";
import {Subject, takeUntil} from "rxjs";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {map, startWith} from "rxjs/operators";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {MatChipInputEvent} from "@angular/material/chips";
import {UsersService} from "@core/services/backend/common/service/users.service";
import {GroupsService} from '@app/core/services/backend/common/service/groups.service';

@Component({
    selector: 'vex-groups-create-update',
    templateUrl: './groups-create-update.component.html',
    styleUrls: ['./groups-create-update.component.scss']
})
export class GroupsCreateUpdateComponent implements OnInit {

    private readonly destroying$ = new Subject<void>();
    static id = 100;

    form: FormGroup;
    mode: 'create' | 'update' = 'create';
    userList: User[] = [];
    separatorKeysCodes: number[] = [ENTER, COMMA];
    usersCtrl = new FormControl('');
    selectedUsers: User[] = [];
    filteredUsers;

    @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;


    constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
                private dialogRef: MatDialogRef<GroupsCreateUpdateComponent>,
                private fb: UntypedFormBuilder,
                private translateService: TranslateService,
                private snackbar: MatSnackBar,
                private users: UsersService,
                private groups: GroupsService) {


        console.log(defaults)
        if (defaults != null) {
            defaults.UsersGroups.forEach(data => {
                // @ts-ignore
                this.selectedUsers.push(data.Users)
            });
        }

    }

    ngOnInit() {
        if (this.defaults) {
            this.mode = 'update';
        } else {
            this.defaults = {} as User;
        }
        this.initializeData().then(() => {
            this.filteredUsers = this.usersCtrl.valueChanges.pipe(
                startWith<string | any>(''),
                //map(value => typeof value === 'string' ? value : value.firstName + ' ' + value.lastName),
                map(user => (user ? this._filter(user) : this.userList.slice())),
            );
        })

        this.form = this.fb.group({
            id: [GroupsCreateUpdateComponent.id++],
            name: [this.defaults.name || ''],
            users: [this.defaults.users || ''],
            active: [this.defaults.active || false],
        });


    }

    async initializeData() {
        const that = this;
        return await new Promise<void>((resolve, reject) => {
            const list = this.users.list('', 0, 100000, '', false);
            list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
                // @ts-ignore
                that.userList = data.data;
                // @ts-ignore
                that.notSelectedList = data.data
                resolve();
            });
        });
    }

    save() {
        // console.log(this.mode)
        if (this.mode === 'create') {
            this.create();
        } else if (this.mode === 'update') {
            this.update();
        }
    }

    create() {
        const that = this;
        console.log(this.mode)
        if (this.form.valid) {
            const group = {
                id: 0,
                name: this.form.value.name,
                active: this.form.value.active,
                users: this.selectedUsers
            }
            this.groups.create(group).subscribe({
                next(result) {
                    that.handleSuccessResponse();
                    that.dialogRef.close(group);
                },
                error(err) {
                    that.handleWrongResponse(err);
                }
            });
        }

    }

    update() {
        const that = this;
        if (this.form.valid) {
            const group = {
                id: this.defaults.ID,
                name: this.form.value.name,
                active: this.form.value.active,
                users: this.selectedUsers
            }
            this.groups.update(group).subscribe({
                next(result) {
                    that.handleSuccessResponse();
                    that.dialogRef.close(group);
                },
                error(err) {
                    that.handleWrongResponse(err);
                }
            });
        }
    }

    isCreateMode() {
        return this.mode === 'create';
    }

    isUpdateMode() {
        return this.mode === 'update';
    }

    handleSuccessResponse() {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            const message = this.isCreateMode() ? res.GRUPOS.SUCCESS : res.GRUPOS.UPDATE
            this.snackbar.open(message, '', {
                duration: 3000
            });
        });
    }

    handleWrongResponse(err) {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            const message = this.isCreateMode() ? res.GRUPOS.ERROR : res.GRUPOS.UPDATE_ERROR
            this.snackbar.open(message, '', {
                duration: 3000
            });
        });
    }

    add(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        console.log(value);
        if (value) {
            this.selectedUsers.push(null);
        }
        event.chipInput!.clear();
        this.usersCtrl.setValue(null);
    }

    remove(fruit: User): void {
        const index = this.selectedUsers.indexOf(fruit);
        if (index >= 0) {
            this.selectedUsers.splice(index, 1);
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        if ( !this.selectedUsers.includes(event.option.value)) {
            this.selectedUsers.push(event.option.value);
        }
        this.userInput.nativeElement.value = '';
        this.usersCtrl.setValue(null);
    }

    private _filter(value: string): any[] {
        console.log('aca')
        // @ts-ignore
        const filterValue = typeof value === 'string' ? value.toLowerCase() : value.firstName.toLowerCase();
        return this.userList.filter(option => option.firstName.toLowerCase().indexOf(filterValue) != -1
            || option.lastName.toLowerCase().indexOf(filterValue) != -1);
    }

    displayUserFn(user: User) {
        if (user) {
            return user.lastName + ' ' + user.firstName;
        }
    }
}
