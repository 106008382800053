import {Observable} from 'rxjs';

export interface WorkedHour {
    ID: number;
    projectId: number;
    userId: number;
    date: string;
    count: number;
    extras: number;
    comments: string;
    status: string;
    selected: boolean;
    statusComment: string;

}

export abstract class WorkedHoursData {

    abstract list(search: string, pageNumber: number, pageSize: number, orderBy: string): Observable<any>;

    abstract get(user: WorkedHour): Observable<WorkedHour>;

    abstract update(user: WorkedHour): Observable<WorkedHour>;

    abstract create(user: WorkedHour): Observable<WorkedHour>;

    abstract details(search: string, pageNumber: number, pageSize: number, orderBy: string): Observable<any>;

    abstract pendingStatus(search: string, pageNumber: number, pageSize: number, orderBy: string): Observable<any>;

}
