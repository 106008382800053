<div class="dropdown">
  <div class="dropdown-header flex items-center justify-between">
    <div>
      <div class="dropdown-heading">{{ "LABELS.NOTIFICATIONS" | translate }}</div>
      <div class="dropdown-subheading">{{ "LABELS.YOU_HAVE" | translate }} {{ length }} {{ "LABELS.NEW_NOTIFICATIONS" | translate }}.</div>
    </div>

  </div>

  <div class="dropdown-content">
    <a *ngFor="let notification of notifications;"
       [class.read]="notification.read"
       [routerLink]="[]"
       (click)="goNotification(notification)"
       class="notification flex items-center"
       matRipple>
      <mat-icon [ngClass]="notification.colorClass"
                [svgIcon]="notification.icon"
                class="notification-icon flex-none"></mat-icon>
      <div class="flex-auto">
        <div class="notification-label" [ngClass]="notification.colorClass">{{ "USUARIO." + notification.label | translate }}</div>
        <div class="notification-description">{{ notification.datetime | relativeDateTime }}</div>
      </div>
    </a>
  </div>

  <div class="dropdown-footer flex items-center justify-center">
    <button color="primary" mat-button type="button" (click)="markAllAsRead()">{{ "LABELS.MARK_ALL_AS_READ" | translate }}</button>
  </div>
</div>

