import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {fadeInUp400ms} from "@vex/animations/fade-in-up.animation";
import {stagger40ms} from "@vex/animations/stagger.animation";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {FormControl, FormGroup, UntypedFormControl} from "@angular/forms";
import {Subject, takeUntil} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {UsersService} from "@core/services/backend/common/service/users.service";
import {User} from "@core/services/backend/common/interface/users";
import * as XLSX from "xlsx";
import {SubAreasService} from "@core/services/backend/common/service/subAreas.service";
import {RolesService} from "@core/services/backend/common/service/roles.service";
import {AuthService} from "@core/guard/auth-service";

@Component({
    selector: 'vex-bulk-users',
    templateUrl: './bulk-users.component.html',
    styleUrls: ['./bulk-users.component.scss'],
    animations: [
        fadeInUp400ms,
        stagger40ms,
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class BulkUsersComponent implements OnInit {

    layoutCtrl = new UntypedFormControl('boxed');
    private readonly destroying$ = new Subject<void>();

    form: FormGroup;
    insertList: User[] = [];
    updateList: User[] = [];
    deleteList: User[] = [];
    userList: User[] = [];
    subAreasList = []
    rolesList = []
    reader: FileReader = new FileReader();

    displayedColumns: string[] = [
        'id',
        'Rut',
        'Nombres',
        'Apellidos',
        'Email',
        'SubArea',
        'Rol',
    ];

    constructor(
        private authService: AuthService,
        private translateService: TranslateService,
        private snackbar: MatSnackBar,
        private users: UsersService,
        private subAreasService: SubAreasService,
        private rolesService: RolesService,
        private changeDetectorRefs: ChangeDetectorRef) {
    }

    ngOnInit(): void {

        const that = this;

        this.form = new FormGroup({
            name: new FormControl({value: "", disabled: true})
        });

        let list = this.subAreasService.list('', 0, 10000, '');
        list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
            // @ts-ignore
            that.subAreasList = data.data;

            list = this.rolesService.list('', 0, 10000, '');
            list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
                // @ts-ignore
                that.rolesList = data.data;

                list = this.users.list('', 0, 100000000, 'rut, email', true);
                list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
                    // @ts-ignore
                    that.userList = data.data;
                    that.userList.forEach((user) => {
                        // @ts-ignore
                        let subArea = that.subAreasList.filter(subarea => subarea.ID === user.subAreasId)
                        if (subArea.length != 0) {
                            // @ts-ignore
                            user.subArea = subArea[0].name;
                        }

                        // @ts-ignore
                        let role = that.rolesList.filter(role => role.ID === user.usersRoleId)
                        if (role.length != 0) {
                            // @ts-ignore
                            user.role = role[0].name;
                        }
                    })
                });

            });
        });
    }

    upload() {

    }

    download() {

    }

    openFile() {
        // @ts-ignore
        document.querySelector('input.arch').click()
    }

    handle(e) {
        const target: DataTransfer = <DataTransfer>(e.target);
        this.form.setValue({"name": e.target.files[0].name});

        if (target.files.length > 1) {
            alert('Multiple files are not allowed');
            return;
        } else {

            this.reader.onload = (e: any) => {
                const bstr: string = e.target.result;
                const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});
                const wsname = wb.SheetNames[0];
                const ws: XLSX.WorkSheet = wb.Sheets[wsname];
                let data = (XLSX.utils.sheet_to_json(ws, {header: 1}));
                // Print the Excel Data
                console.log(data);
                const headerValid = [
                    'id',
                    'Rut',
                    'Nombres',
                    'Apellidos',
                    'Email',
                    'SubArea',
                    'Rol',
                ];
                const header = data[0];

                if (!this.compareHeaders(headerValid, header)) {
                    this.handleNotMatchResponse();
                    return;
                }

                const updateList: User[] = [];
                const insertList: User[] = [];
                const deleteList: User[] = [];

                data = data.slice(1);
                data.forEach((user) => {
                    // @ts-ignore
                    const p = this.userList.filter(p => user[1].replace('-', '').replaceAll(".", "") === p.rut.replace('-', '').replaceAll(".", "")
                        && user[4].toUpperCase() === p.email.toUpperCase());
                    if (p.length > 0) {
                        // @ts-ignore
                        p[0].rut = String(p[0].rut).replaceAll(".","")
                        if (p[0].rut.indexOf("-") === -1) {
                            p[0].rut = p[0].rut.substring(0, p[0].rut.length-1) + "-" + p[0].rut.slice(-1)
                        }
                        updateList.push(p[0]);
                    } else {

                        const subAreaId = this.subAreasList.filter(area => area.name === user[5])[0].ID;
                        const roleId = this.rolesList.filter(rol => rol.name === user[6])[0].ID;
                        // @ts-ignore
                        const newUser = {
                            "lastName": user[3],
                            "firstName": user[2],
                            "email": user[4],
                            "rut": user[1].replaceAll(".", ""),
                            "subArea": user[5],
                            'role': user[6],
                            "subAreaID": user[5] !== '' ? subAreaId : 0,
                            "roleID": user[6] !== '' ? roleId : 0,
                        }
                        // @ts-ignore
                        insertList.push(newUser);
                    }
                });

                this.userList.forEach((user) => {
                    // @ts-ignore
                    const p = data.filter(p => user.rut.replace('-', '').replaceAll(".", "")  === p[1].replace('-', '').replaceAll(".", "")
                        && p[4].toUpperCase() === user.email.toUpperCase());
                    if (p.length === 0) {
                        // @ts-ignore
                        const deleteUser = {
                            "ID": user.ID,
                            "lastName": user.lastName,
                            "firstName": user.firstName,
                            "email": user.email,
                            "subArea": user.subArea,
                            "role": user.role
                        }
                        // @ts-ignore
                        deleteList.push(deleteUser);
                    }
                });

                this.insertList = [...insertList];
                this.updateList = [...updateList];
                this.deleteList = [...deleteList];
                this.changeDetectorRefs.detectChanges();
                this.changeDetectorRefs.markForCheck();

            }
            this.reader.readAsBinaryString(target.files[0]);
        }

    }

    handleNotMatchResponse() {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            const message = res.EXPORT.FILE_NOT_MATCH;
            this.snackbar.open(message, '', {
                duration: 3000
            });
        });
    }


    compareHeaders = (first, second) => {
        return (
            first.length === second.length &&
            first.every((element_1) =>
                second.some((element_2) =>
                    Object.keys(element_1).every((key) => element_1[key] === element_2[key])
                )
            )
        );
    };


    getBadgeUpdate() {
        return Number(this.updateList.length);
    }

    getBadgeInsert() {
        return Number(this.insertList.length);
    }

    getBadgeDelete() {
        return Number(this.deleteList.length);
    }

    Procesar() {
        const that = this;
        const users = {
            userId: this.authService.UserId,
            inserts: this.insertList,
            updates: this.updateList,
            deletes: this.deleteList
        }
        this.users.bulk(users).subscribe({
            next(result) {
                that.handleSuccessResponse();
            },
            error(err) {
                that.handleWrongResponse(err);
            }
        });
    }

    handleSuccessResponse() {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            const message =  res.USUARIO.BULK_SUCCESS
            this.snackbar.open(message, '', {
                duration: 3000
            });
        });
    }

    handleWrongResponse(err) {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            const message =  res.USUARIO.BULK_ERROR
            this.snackbar.open(message, '', {
                duration: 3000
            });
        });
    }

    reset() {
        this.form.reset();
        this.reader = new FileReader();
        this.updateList = [];
        this.insertList = [];
        this.deleteList = [];
    }
}
