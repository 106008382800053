import {Directive, Inject, Input} from "@angular/core";
import {MAT_DATE_RANGE_SELECTION_STRATEGY} from "@angular/material/datepicker";
import {RangeSelectionStrategyService} from "@vex/services/range-selection-strategy.service";

@Directive({
    selector: "[maxRange]",
    providers: [
        {
            provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
            useClass: RangeSelectionStrategyService
        }
    ]
})
export class MaxRangeDirective {
    constructor(
        @Inject(MAT_DATE_RANGE_SELECTION_STRATEGY)
        private maxRangeStrategy: RangeSelectionStrategyService<any>
    ) {
    }

    @Input() set maxRange(value: string) {
        this.maxRangeStrategy.delta = +parseInt(value) || 7;
    }
}
