import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormGroup, UntypedFormBuilder, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {User} from "@core/services/backend/common/interface/users";
import {TranslateService} from "@ngx-translate/core";
import {UsersService} from "@core/services/backend/common/service/users.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SubArea} from "@core/services/backend/common/interface/subAreas";
import {SubAreasService} from "@core/services/backend/common/service/subAreas.service";
import {Subject, takeUntil} from "rxjs";
import {RolesService} from "@core/services/backend/common/service/roles.service";
import {CustomValidators} from "@vex/utils/ConfirmPasswordValidator";
import {AuthService} from "@core/guard/auth-service";
import {RutService} from "rut-chileno";
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
    selector: 'vex-user-create-update',
    templateUrl: './user-create-update.component.html',
    styleUrls: ['./user-create-update.component.scss']
})
export class UserCreateUpdateComponent implements OnInit {
    private readonly destroying$ = new Subject<void>();
    static id = 100;

    noRegistraHoras: boolean = false;
    noRequiereValidacionHoras: boolean = false;

    form: FormGroup;
    mode: 'create' | 'update' = 'create';
    subAreasList: SubArea[] = []
    rolesList = []
    inputType = 'password';
    visible = false;

    constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
                private dialogRef: MatDialogRef<UserCreateUpdateComponent>,
                private fb: UntypedFormBuilder,
                private translateService: TranslateService,
                private userService: UsersService,
                private subAreasService: SubAreasService,
                private rolesService: RolesService,
                private cd: ChangeDetectorRef,
                private authService: AuthService,
                private rutService: RutService,
                private snackbar: MatSnackBar) {
    }

    ngOnInit() {
        if (this.defaults) {
            this.mode = 'update';
        } else {
            this.defaults = {} as User;
        }

        let list = this.subAreasService.list('', 0, 10000, '');
        list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
            // @ts-ignore
            this.subAreasList = data.data;
        });

        list = this.rolesService.list('', 0, 10000, '');
        list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
            // @ts-ignore
            this.rolesList = data.data;

            let newList = [];
            this.rolesList.forEach(role => {
                if (role.name === 'SUPER_ADMIN' && this.authService.Role === 'SUPER_ADMIN') {
                    newList.push(role)
                } else if (role.name != 'SUPER_ADMIN') {
                    newList.push(role)
                }
            });

            this.rolesList = newList;
        });

        console.log(this.defaults)

        this.form = this.fb.group({
                id: [UserCreateUpdateComponent.id++],
                firstName: [this.defaults.firstName || ''],
                lastName: [this.defaults.lastName || ''],
                rut: [this.rutService.rutFormat(this.defaults.rut)  || '', [Validators.required, this.rutService.validaRutForm]],
                email: [this.defaults.email || ''],
                subArea: [this.defaults.subAreasId || ''],
                roles: [this.defaults.usersRoleId || false],
                password: [
                    '',
                    [
                        Validators.minLength(8),
                        Validators.maxLength(100),
                    ],
                ],
                confirm: ['',
                    [
                        Validators.minLength(8),
                        Validators.maxLength(100),
                    ],
                ],
            },

            {validator: CustomValidators.MatchingPasswords}
        );
        this.noRequiereValidacionHoras = this.defaults.notNeedHoursApproval;
        this.noRegistraHoras = this.defaults.notRegisterHours;
    }

    save() {
        console.log(this.mode)
        if (this.mode === 'create') {
            this.create();
        } else if (this.mode === 'update') {
            this.update();
        }
    }

    create() {
        const that = this;
        if (this.form.valid) {
            const user = {
                firstName: this.form.value.firstName,
                lastName: this.form.value.lastName,
                email: this.form.value.email,
                subAreasID: this.form.value.subArea,
                password: this.form.value.password,
                roleId: this.form.value.roles,
                notRegisterHours: this.noRegistraHoras, 
                notNeedHoursApproval: this.noRequiereValidacionHoras,
            }
            // @ts-ignore
            user.rut= this.form.value.rut.replaceAll(".", "");
            this.userService.create(user).subscribe({
                next(result) {
                    that.handleSuccessResponse();
                    that.dialogRef.close(user);
                },
                error(err) {
                    that.handleWrongResponse(err);
                }
            });
        }

    }

    update() {
        const that = this;
        if (this.form.valid) {
            const user = {
                id: this.defaults.ID,
                firstName: this.form.value.firstName,
                lastName: this.form.value.lastName,
                email: this.form.value.email,
                subAreasID: this.form.value.subArea,
                password: this.form.value.password,
                roleId: this.form.value.roles,
                notRegisterHours: this.noRegistraHoras, 
                notNeedHoursApproval: this.noRequiereValidacionHoras,
            }
            console.log('user update', user);
            // @ts-ignore
            user.rut= this.form.value.rut.replaceAll(".", "");
            this.userService.create(user).subscribe({
                next(result) {
                    that.handleSuccessResponse();
                    that.dialogRef.close(user);
                },
                error(err) {
                    that.handleWrongResponse(err);
                }
            });
        }
    }

    isCreateMode() {
        return this.mode === 'create';
    }

    isUpdateMode() {
        return this.mode === 'update';
    }

    handleSuccessResponse() {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            const message = this.isCreateMode() ? res.USUARIO.SUCCESS : res.USUARIO.UPDATE
            this.snackbar.open(message, '', {
                duration: 3000
            });
        });
    }

    handleWrongResponse(err) {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            if (err.error.code === 500 && err.error.message === 'user has been registered error') {
                this.snackbar.open(res.REGISTER.YA_REGISTRADO, '', {
                    duration: 3000
                });
            } else {
                const message = this.isCreateMode() ? res.USUARIO.ERROR : res.USUARIO.UPDATE_ERROR
                this.snackbar.open(message, '', {
                    duration: 3000
                });
            }
        });
    }

    toggleVisibility() {
        if (this.visible) {
            this.inputType = 'password';
            this.visible = false;
            this.cd.markForCheck();
        } else {
            this.inputType = 'text';
            this.visible = true;
            this.cd.markForCheck();
        }
    }

    inputEvent($event: Event) {
        let rut = this.rutService.getRutChileForm(1, (event.target as HTMLInputElement).value)
        if (rut)
            this.form.controls['rut'].patchValue(rut, {emitEvent :false});

    }

    registraHoras(event: MatSlideToggleChange) {
        this.noRegistraHoras = event.checked;
    }

    requiereValidacionHoras(event: MatSlideToggleChange) {
        this.noRequiereValidacionHoras = event.checked;
    }
}
