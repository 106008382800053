<vex-page-layout mode="card">

    <vex-page-layout-header class="pb-16 flex flex-col items-start justify-center">
        <div [class.container]="layoutCtrl.value === 'boxed'"
             [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
             class="w-full flex flex-col sm:flex-row justify-between">
            <div>
                <h1 class="title mt-0 mb-1">{{ "MENUS.HORAS_TAREAS" | translate }}</h1>
                <vex-breadcrumbs
                    [crumbs]="['MENUS.GESTION_DE_ACTIVIDADES', 'MENUS.HORAS_TAREAS']"></vex-breadcrumbs>
            </div>

        </div>
    </vex-page-layout-header>

    <vex-page-layout-content class="-mt-6">


        <div>
            <form [formGroup]="searchForm">
                <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">

                    <div
                        class="bg-foreground rounded-full px-4 h-10 max-w-[400px] flex-auto flex items-center border border-gray-300">
                        <mat-date-range-input [rangePicker]="picker" [min]="minDate()">
                            <input matStartDate formControlName="startDate" #start readonly
                                   placeholder="{{ 'LABELS.FECHA_INICIO' | translate }}">
                            <input matEndDate formControlName="endDate" #end readonly
                                   (dateChange)="dateRangeChange(start.value, end.value)"
                                   placeholder="{{ 'LABELS.FECHA_FIN' | translate }}">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker maxRange=6 #picker></mat-date-range-picker>
                    </div>

                    <button
                        #btnPrev
                        class="ml-4 flex-none"
                        color="primary"
                        [disabled]="!btnPrevDisabled"
                        mat-mini-fab
                        [matTooltip]="'ACTIONS.PREV_WEEK' | translate "
                        type="button"
                        (click)="BackWeek()">
                        <mat-icon svgIcon="mat:arrow_left"></mat-icon>
                    </button>

                    <button
                        class="ml-4 flex-none"
                        color="primary"
                        mat-mini-fab
                        [matTooltip]="'ACTIONS.NEXT_WEEK' | translate "
                        type="button"
                        (click)="NextWeek()">
                        <mat-icon svgIcon="mat:arrow_right"></mat-icon>
                    </button>

                    <div
                        class="bg-foreground rounded-full px-4 h-10 ml-4 max-w-[500px] flex-auto flex items-center border border-gray-300">
                        <input type="text"
                               aria-label="Number"
                               matInput
                               formControlName="project"
                               placeholder="{{ 'PROYECTO.SELECCIONAR' | translate }}"
                               [matAutocomplete]="auto"
                               style="width: 100% !important;">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayProjectFn"
                                          (optionSelected)="onSelectionChange($event)">
                            <mat-option *ngFor="let option of filteredProjectList | async" [value]="option">
                                {{ option.name }}
                            </mat-option>
                        </mat-autocomplete>

                        <button type="button" *ngIf="searchForm.get('project').value" matSuffix mat-icon-button
                                aria-label="Clear" (click)="reset() ">
                            <mat-icon>close</mat-icon>
                        </button>

                    </div>

                </div>

                <div class="bg-app-bar px-6 h-16 border-b left-0 flex items-center"  *ngIf="IS_SUPER_ADMIN">
                    <div class="bg-foreground rounded-full px-4 h-10 max-w-[500px] flex-auto flex items-center border border-gray-300">

                        <input type="text"
                               aria-label="Number"
                               matInput
                               formControlName="user"

                               placeholder="{{ 'USUARIO.SELECCIONAR' | translate }}"
                               [matAutocomplete]="autoUser"
                                style="width: 100% !important;">

                        <mat-autocomplete #autoUser="matAutocomplete" [displayWith]="displayUserFn"
                                          (optionSelected)="onSelectionUserChange($event)">
                            <mat-option *ngFor="let option of filteredUserList | async" [value]="option">
                                {{ option.name }}
                            </mat-option>
                        </mat-autocomplete>

                        <button type="button" *ngIf="IS_SUPER_ADMIN && searchForm.get('user').value" matSuffix mat-icon-button
                                aria-label="Clear" (click)="resetUser() ">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </div>

            </form>

            <div class="pt-6 flex">
                <h3 class="headline m-0">{{ "LABELS.HORAS_TRABAJADAS_SEMANA" | translate }} : {{ weekTotalHours }}</h3>
                <span class="flex-1"></span>
                <h3 class="headline m-0">{{ "LABELS.HORAS_EXTRAS_TRABAJADAS_SEMANA" | translate }}
                    : {{ weekTotalExtraHours }}</h3>
            </div>
            <div class="pb-6 flex">
                <p class="caption m-0 opacity-50"
                   *ngIf="weekTotalHours > 39">{{ "LABELS.TOTAL_HORAS_TRABAJADAS" | translate }}</p>
                <p class="caption m-0 opacity-50"
                   *ngIf="weekTotalHours < 40">{{ "LABELS.PENDIENTES_HORAS_TRABAJADAS" | translate }}</p>
            </div>

            <div>
                <table class="table table table-striped w-full border-b-8">
                    <tr class="bg-app-bar">
                        <th colspan="3" style="text-align: center">{{ "LABELS.LUNES" | translate }} {{ days[0].date }}</th>
                        <th colspan="3" style="text-align: center">{{ "LABELS.MARTES" | translate }} {{ days[1].date }}</th>
                        <th colspan="3" style="text-align: center">{{ "LABELS.MIERCOLES" | translate }} {{ days[2].date }}</th>
                        <th colspan="3" style="text-align: center">{{ "LABELS.JUEVES" | translate }} {{ days[3].date }}</th>
                        <th colspan="3" style="text-align: center">{{ "LABELS.VIERNES" | translate }} {{ days[4].date }}</th>
                        <th colspan="3" style="text-align: center">{{ "LABELS.SABADO" | translate }} {{ days[5].date }}</th>
                        <th colspan="3" style="text-align: center">{{ "LABELS.DOMINGO" | translate }} {{ days[6].date }}</th>
                    </tr>
                    <tr class="bg-app-bar">
                        <td style="text-align: center">{{ "LABELS.NORMALES" | translate }}</td>
                        <td style="text-align: center">{{ "LABELS.EXTRAS" | translate }}</td>
                        <td style="text-align: center">{{ "LABELS.RECHAZADAS_RESUMEN" | translate }}</td>
                        <td style="text-align: center">{{ "LABELS.NORMALES" | translate }}</td>
                        <td style="text-align: center">{{ "LABELS.EXTRAS" | translate }}</td>
                        <td style="text-align: center">{{ "LABELS.RECHAZADAS_RESUMEN" | translate }}</td>
                        <td style="text-align: center">{{ "LABELS.NORMALES" | translate }}</td>
                        <td style="text-align: center">{{ "LABELS.EXTRAS" | translate }}</td>
                        <td style="text-align: center">{{ "LABELS.RECHAZADAS_RESUMEN" | translate }}</td>
                        <td style="text-align: center">{{ "LABELS.NORMALES" | translate }}</td>
                        <td style="text-align: center">{{ "LABELS.EXTRAS" | translate }}</td>
                        <td style="text-align: center">{{ "LABELS.RECHAZADAS_RESUMEN" | translate }}</td>
                        <td style="text-align: center">{{ "LABELS.NORMALES" | translate }}</td>
                        <td style="text-align: center">{{ "LABELS.EXTRAS" | translate }}</td>
                        <td style="text-align: center">{{ "LABELS.RECHAZADAS_RESUMEN" | translate }}</td>
                        <td style="text-align: center">{{ "LABELS.NORMALES" | translate }}</td>
                        <td style="text-align: center">{{ "LABELS.EXTRAS" | translate }}</td>
                        <td style="text-align: center">{{ "LABELS.RECHAZADAS_RESUMEN" | translate }}</td>
                        <td style="text-align: center">{{ "LABELS.NORMALES" | translate }}</td>
                        <td style="text-align: center">{{ "LABELS.EXTRAS" | translate }}</td>
                        <td style="text-align: center">{{ "LABELS.RECHAZADAS_RESUMEN" | translate }}</td>
                    </tr>
                    <tr>
                        <td style="text-align: center">{{ days[0].count }}</td>
                        <td style="text-align: center">{{ days[0].extras }}</td>
                        <td style="text-align: center" [ngClass]="days[0].rechazadas > 0 ? 'is-bold' : '' " >{{ days[0].rechazadas }}</td>
                        <td style="text-align: center">{{ days[1].count }}</td>
                        <td style="text-align: center">{{ days[1].extras }}</td>
                        <td style="text-align: center" [ngClass]="days[1].rechazadas > 0 ? 'is-bold' : '' " >{{ days[1].rechazadas }}</td>
                        <td style="text-align: center">{{ days[2].count }}</td>
                        <td style="text-align: center">{{ days[2].extras }}</td>
                        <td style="text-align: center" [ngClass]="days[2].rechazadas > 0 ? 'is-bold' : '' " >{{ days[2].rechazadas }}</td>
                        <td style="text-align: center">{{ days[3].count }}</td>
                        <td style="text-align: center">{{ days[3].extras }}</td>
                        <td style="text-align: center" [ngClass]="days[3].rechazadas > 0 ? 'is-bold' : '' " >{{ days[3].rechazadas }}</td>
                        <td style="text-align: center">{{ days[4].count }}</td>
                        <td style="text-align: center">{{ days[4].extras }}</td>
                        <td style="text-align: center" [ngClass]="days[4].rechazadas > 0 ? 'is-bold' : '' " >{{ days[4].rechazadas }}</td>
                        <td style="text-align: center">{{ days[5].count }}</td>
                        <td style="text-align: center">{{ days[5].extras }}</td>
                        <td style="text-align: center" [ngClass]="days[5].rechazadas > 0 ? 'is-bold' : '' " >{{ days[5].rechazadas }}</td>
                        <td style="text-align: center">{{ days[6].count }}</td>
                        <td style="text-align: center">{{ days[6].extras }}</td>
                        <td style="text-align: center" [ngClass]="days[6].rechazadas > 0 ? 'is-bold' : '' " >{{ days[6].rechazadas }}</td>
                    </tr>
                </table>
            </div>

            <form [formGroup]="VOForm" autocomplete="off">
                <ng-container formArrayName="VORows">

                    <table [dataSource]="dataSource" mat-table matSort matTableResponsive>

                        <!-- Position Column -->
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef> No.</th>
                            <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
                                <mat-form-field>
                                    <input matInput type="text" formControlName="id" [readonly]="true">
                                </mat-form-field>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef> {{ "GRIDS.FECHA" | translate }}</th>
                            <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">

                                <mat-form-field style="width: 100%;" appearance="legacy">
                                    <input
                                        matInput
                                        type="text"
                                        formControlName="date"
                                        [readonly]="true">

                                </mat-form-field>

                            </td>
                        </ng-container>

                        <ng-container matColumnDef="project">
                            <th mat-header-cell *matHeaderCellDef> {{ "GRIDS.PROYECTO" | translate }}</th>
                            <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">

                                <div style="width: 100%;">
                                    {{ getProjectValue(VOForm, i) }}
                                </div>

                            </td>
                        </ng-container>

                        <!-- Weight Column -->
                        <ng-container matColumnDef="count">
                            <th mat-header-cell *matHeaderCellDef> {{ "GRIDS.CANTIDAD" | translate }}</th>
                            <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
                                <mat-form-field style="width: 100%;" [appearance]="getAppearance">
                                    <input matInput type="number" formControlName="count" max="12" min="0" [readonly]="isEditable"
                                    >
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="extras">
                            <th mat-header-cell *matHeaderCellDef> {{ "GRIDS.HHEE" | translate }}</th>
                            <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
                                <mat-form-field style="width: 100%;" [appearance]="getAppearance">
                                    <input matInput type="number" formControlName="extras" min="0" [readonly]="isEditable"
                                           >
                                </mat-form-field>

                            </td>
                        </ng-container>

                        <!-- Symbol Column -->
                        <ng-container matColumnDef="observation">
                            <th mat-header-cell *matHeaderCellDef> {{ "GRIDS.OBSERVACION" | translate }}</th>
                            <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
                                <mat-form-field style="width: 100%;" [appearance]="getAppearance">
                                    <input matInput type="text" formControlName="comments" [readonly]="isEditable">
                                    <mat-error matErrorMessages></mat-error>
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <!-- Action Column -->
                        <!--                        -->
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef> {{ "GRIDS.ACCIONES" | translate }}</th>
                            <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
                                <button mat-icon-button class="material-icons app-toolbar-menu delete-button mr-2"
                                        *ngIf="statusControl(VOForm, i)"
                                        matTooltip="Delete" color="warn" (click)="DeleteSVO(VOForm, element, i)">
                                    <mat-icon svgIcon="mat:clear" [ngClass]="element.value.status === 'RECHAZADA' ? 'text-primary-contrast': '' "></mat-icon>
                                </button>
                                <button mat-icon-button class="material-icons app-toolbar-menu delete-button"
                                        *ngIf="hasComments(VOForm, i)"
                                        matTooltip="Comentarios" color="primary" (click)="showComments(VOForm, i)">
                                    <mat-icon svgIcon="mat:comment" [ngClass]="element.value.status === 'RECHAZADA' ? 'text-primary-contrast': '' "></mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]=" row.value.status === 'RECHAZADA' ? 'is-danger': '' "></tr>

                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">{{ "GRIDS.NO_DATA" | translate }}</td>
                        </tr>

                    </table>

                </ng-container>
            </form>

            <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">

                <span class="flex-1"></span>
                <button class="ml-4 flex-none " mat-button type="button"
                        (click)="reset()">{{ "ACTIONS.CANCELAR" | translate }}
                </button>
                <button color="primary" mat-flat-button type="button"  [class.spinner]="loading" [disabled]="loading"
                        (click)="save()">{{ "ACTIONS.GUARDAR" | translate }}
                </button>


            </div>
        </div>


    </vex-page-layout-content>


</vex-page-layout>


<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
    <ng-template let-customer="customer" matMenuContent>
        <button mat-menu-item>
            <mat-icon  svgIcon="mat:edit"></mat-icon>
            <span>Modify</span>
        </button>
        <button mat-menu-item>
            <mat-icon  svgIcon="mat:delete"></mat-icon>
            <span>Delete</span>
        </button>
    </ng-template>
</mat-menu>
