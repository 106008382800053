<vex-page-layout mode="card">

    <vex-page-layout-header class="pb-16 flex flex-col items-start justify-center">
        <div [class.container]="layoutCtrl.value === 'boxed'"
             [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
             class="w-full flex flex-col sm:flex-row justify-between">
            <div>
                <h1 class="title mt-0 mb-1">{{ "MENUS.CARGA_MASIVA_PROYECTOS" | translate }}</h1>
                <vex-breadcrumbs
                    [crumbs]="['MENUS.ADMINISTRACION', 'MENUS.PROCESOS']"></vex-breadcrumbs>
            </div>

        </div>
    </vex-page-layout-header>

    <vex-page-layout-content class="-mt-6">
        <form [formGroup]="form">
            <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">

                <div
                    class="bg-foreground rounded-full px-4 h-10 max-w-[600px] flex-auto flex items-center border border-gray-300">
                    <mat-icon class="icon-sm text-secondary" svgIcon="mat:attach_file"></mat-icon>
                    <input formControlName="name"
                           class="px-4 py-2 border-0 outline-none w-full bg-transparent"
                           [placeholder]="'ACTIONS.ARCHIVO_SELECCIONADO' | translate"
                           type="search">
                </div>

                <span class="flex-1"></span>

                <button
                    class="ml-4 flex-none"
                    color="primary"
                    mat-mini-fab
                    [matTooltip]="'ACTIONS.BUSCAR_LINKS' | translate "
                    type="button"
                    (click)="openFile()">
                    <input type="file" class="arch" style="display:none" (change)="handle($event)">
                    <mat-icon svgIcon="mat:attach_file"></mat-icon>
                </button>

            </div>
        </form>

        <mat-tab-group mat-stretch-tabs>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span [matBadge]="getBadgeInsert()"  matBadgeOverlap="false">{{'EXPORT.CREATE_PROJECTS' | translate }}</span>
                </ng-template>
                <div class="body table-responsive">
                    <table mat-table [dataSource]="insertList" class="w-full">
                        <!-- No Column -->
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef> {{ "PROYECTO.ID" | translate }}</th>
                            <td mat-cell *matCellDef="let element"> {{element.ID}} </td>
                        </ng-container>
                        <ng-container matColumnDef="proyecto">
                            <th mat-header-cell *matHeaderCellDef> {{ "PROYECTO.NOMBRE" | translate }}</th>
                            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                        </ng-container>
                        <!-- Name Column -->
                        <ng-container matColumnDef="codigo">
                            <th mat-header-cell *matHeaderCellDef> {{ "PROYECTO.CODIGO" | translate }} </th>
                            <td mat-cell *matCellDef="let element"> {{element.code}} </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span [matBadge]="getBadgeUpdate()" matBadgeOverlap="false">{{'EXPORT.UPDATE_PROJECTS' | translate }}</span>
                </ng-template>
                <div class="body table-responsive">
                    <table mat-table [dataSource]="updateList" class="w-full">
                        <!-- No Column -->
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef> {{ "PROYECTO.ID" | translate }}</th>
                            <td mat-cell *matCellDef="let element"> {{element.ID}} </td>
                        </ng-container>
                        <ng-container matColumnDef="proyecto">
                            <th mat-header-cell *matHeaderCellDef> {{ "PROYECTO.NOMBRE" | translate }}</th>
                            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                        </ng-container>
                        <!-- Name Column -->
                        <ng-container matColumnDef="codigo">
                            <th mat-header-cell *matHeaderCellDef> {{ "PROYECTO.CODIGO" | translate }} </th>
                            <td mat-cell *matCellDef="let element"> {{element.code}} </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span [matBadge]="getBadgeDelete()" matBadgeOverlap="false">{{'EXPORT.DELETE_PROJECTS' | translate }}</span>
                </ng-template>
                <div class="body table-responsive">
                    <table mat-table [dataSource]="deleteList" class="w-full">
                        <!-- No Column -->
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef> {{ "PROYECTO.ID" | translate }}</th>
                            <td mat-cell *matCellDef="let element"> {{element.ID}} </td>
                        </ng-container>
                        <ng-container matColumnDef="proyecto">
                            <th mat-header-cell *matHeaderCellDef> {{ "PROYECTO.NOMBRE" | translate }}</th>
                            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                        </ng-container>
                        <!-- Name Column -->
                        <ng-container matColumnDef="codigo">
                            <th mat-header-cell *matHeaderCellDef> {{ "PROYECTO.CODIGO" | translate }} </th>
                            <td mat-cell *matCellDef="let element"> {{element.code}} </td>
                        </ng-container>
                        <!-- Gender Column -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
            </mat-tab>
        </mat-tab-group>


        <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">

            <span class="flex-1"></span>
            <button color="primary" mat-flat-button type="button" disabled="{{!processing}}"
                    (click)="Procesar()">{{ "ACTIONS.PROCESAR" | translate }}</button>

        </div>


    </vex-page-layout-content>
</vex-page-layout>
