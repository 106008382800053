import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, UntypedFormControl} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {Project} from "@core/services/backend/common/interface/projects";
import {TableColumn} from "@vex/interfaces/table-column.interface";
import {fadeInUp400ms} from "@vex/animations/fade-in-up.animation";
import {stagger40ms} from "@vex/animations/stagger.animation";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Subject, takeUntil} from "rxjs";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {ProjectsService} from "@core/services/backend/common/service/projects.service";
import {
    ProjectCreateUpdateComponent
} from "@app/pages/administration/project-create-update/project-create-update.component";
import {TableUtil} from "@vex/services/tableUtil";
import {SelectionModel} from '@angular/cdk/collections';
import {DialogComponent} from '@vex/components/dialog/dialog.component';

@Component({
    selector: 'vex-projects',
    templateUrl: './projects.component.html',
    styleUrls: ['./projects.component.scss'],
    animations: [
        fadeInUp400ms,
        stagger40ms
    ]
})
export class ProjectsComponent implements OnInit {

    layoutCtrl = new UntypedFormControl('boxed');
    private readonly destroying$ = new Subject<void>();

    projectList: Project[] = [];
    dataSource: MatTableDataSource<Project> | null;
    searchForm: FormGroup;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    selection = new SelectionModel<Project>(true, []);
    notSelection = false;

    columns: TableColumn<Project>[] = [
        {label: 'GRIDS.ID', property: 'id', type: 'text', visible: false},
        {label: 'GRIDS.NOMBRE', property: 'name', type: 'text', visible: true, cssClasses: ['font-medium']},
        {label: 'GRIDS.CODIGO', property: 'code', type: 'text', visible: true},
        {label: 'GRIDS.ACTIVO', property: 'active', type: 'image', visible: true},
        {label: 'GRIDS.FORZAR', property: 'force_comments', type: 'checkbox', visible: true},
        {label: 'GRIDS.SELECTED', property: 'selected', type: 'badge', visible: true},
        {label: 'GRIDS.ACCIONES', property: 'actions', type: 'button', visible: true}
    ];

    currentPage = 0;
    pageSize = 200;
    pageSizeOptions: number[] = [5, 10, 20, 50, 200];
    isLoading: boolean;

    constructor(private projects: ProjectsService,
                private dialog: MatDialog,
                private translateService: TranslateService,
                private snackbar: MatSnackBar
    ) {
    }

    ngOnInit(): void {

        this.dataSource = new MatTableDataSource();

        this.searchForm = new FormGroup({
            user: new FormControl()
        })
        this.loadData()

    }

    loadData() {
        const that = this
        this.initializeData().then(() => {
            this.dataSource.data = that.projectList;
        })
    }

    private getSearch() {
        return {
            name: this.searchForm.get("user").value ? '%' + this.searchForm.get("user").value.toString().toUpperCase() + '%' : ''
        }
    }

    async initializeData() {
        const that = this;
        return await new Promise<void>((resolve, reject) => {
            const list = this.projects.list(JSON.stringify(that.getSearch()), that.currentPage, that.pageSize, 'code', true);
            list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
                // @ts-ignore
                that.projectList = data.data;
                that.paginator.pageIndex = that.currentPage;
                // @ts-ignore
                that.paginator.length = data.total_records;
                resolve();
            });
        });
    }

    pageChanged(event: PageEvent) {
        console.log({event});
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        this.loadData();
    }

    trackByProperty<T>(index: number, column: TableColumn<T>) {
        return column.property;
    }

    get visibleColumns() {
        return this.columns.filter(column => column.visible).map(column => column.property);
    }

    buscar() {
        this.loadData();
    }

    createProject() {
        this.dialog.open(ProjectCreateUpdateComponent, {
            maxWidth: '100vw',
            maxHeight: '100vh',
            //height: '50%',
            width: '60%',
            panelClass: 'full-screen-modal'
        }).afterClosed().subscribe((project: Project) => {
            this.loadData();
        });
    }

    updateProject(project: Project) {
        console.log(project)
        this.dialog.open(ProjectCreateUpdateComponent, {
            data: project,
            maxWidth: '100vw',
            maxHeight: '100vh',
            //height: '50%',
            width: '60%',
            panelClass: 'full-screen-modal'
        }).afterClosed().subscribe(updatedCustomer => {
            if ( this.selection.selected.length === 0 ) {
                this.loadData();
            }
        });
    }

    deleteProject(project: Project) {
        const that = this;
        this.projects.delete(project).subscribe({
            next(result) {
                that.handleSuccessResponse();
                that.loadData();
            },
            error(err) {
                that.handleWrongResponse(err);
            }
        });
    }

    handleSuccessResponse() {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            this.snackbar.open(res.USUARIO.DELETE, '', {
                duration: 3000
            });
        });
    }

    handleWrongResponse(err) {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            this.snackbar.open(res.USUARIO.DELETE_ERROR, '', {
                duration: 3000
            });

        });
    }

    export() {
        const list = this.projects.list('', 0, 100000, 'ID', true);
        list.pipe(takeUntil(this.destroying$)).subscribe((data) => {

            console.log(data);
            const result = [];
            // @ts-ignore
            data.data.forEach((project) => {

                const data = {
                    ID: project.ID,
                    Proyecto: project.name,
                    Codigo: project.code,
                    Activo: project.active,
                    ForzarComentarios: project.force_comments,
                    HorasNormales: project.normal_hours,
                    HorasExtras: project.extra_hours
                }
                result.push(data)
            })
            TableUtil.exportArrayToExcel(result, "Projects");

        });

    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }


    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    collapseAll() {
        this.dataSource.data.forEach((row) => {
            this.dataSource.filter = performance.now().toString();
        });
    }

    async rechazarSelected() {
        const data = {
            title: "Rechazar forzado de comentarios",
            message: "Esta seguro de rechazar los comentarios..."
        }
        await this.chngeForceSelected(data, false);
        this.loadData();
    }

    async aprobarSelected() {
        const data = {
            title: "Forzar comentarios",
            message: "Esta seguro de forzar los comentarios..."
        }
        await this.chngeForceSelected(data, true);
        this.loadData();
    }

    async chngeForceSelected(data, bool): Promise<void> {
        const that = this;
        const dialogRef = this.dialog.open(DialogComponent, {
            data: data,
            disableClose: bool,
            width: '400px'
        })

        const result = await dialogRef.afterClosed().toPromise();

        if (!result) {
            this.selection.clear();
            return;
        }

        return new Promise((resolve) => {

            let projects = [];
            this.selection.selected.forEach((project) => {
                const project_forced_comment = {
                    name: project.name,
                    code: project.code,
                    force_comments: bool
                };

                // @ts-ignore
                project_forced_comment.id = project.ID;
                // @ts-ignore
                project_forced_comment.active= project.active

                projects.push(project_forced_comment)

            });

            this.projects.update(projects).subscribe({
                next(result) {
                    //console.log('result', result);
                },
                error(err) {
                    console.error(err);
                },
                complete() {
                    that.selection.clear();
                    resolve();
                }
            });

        });
    }

    isDeleted(row) {
        // @ts-ignore
        return row.DeletedAt !== null;
    }
}
