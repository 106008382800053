import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {PopoverService} from '../../../components/popover/popover.service';
import {
    ToolbarNotificationsDropdownComponent
} from './toolbar-notifications-dropdown/toolbar-notifications-dropdown.component';
import {Subject, takeUntil, tap, timer} from "rxjs";
import {NotificationsService} from "@core/services/backend/common/service/notifications.service";
import {AuthService} from "@core/guard/auth-service";
import {map, switchMap} from "rxjs/operators";

@Component({
    selector: 'vex-toolbar-notifications',
    templateUrl: './toolbar-notifications.component.html',
    styleUrls: ['./toolbar-notifications.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarNotificationsComponent implements OnInit {

    private readonly destroying$ = new Subject<void>();
    @ViewChild('originRef', {static: true, read: ElementRef}) originRef: ElementRef;
    length: number;
    dropdownOpen: boolean;
    private unsub = new Subject<void>();

    constructor(private popover: PopoverService,
                private notificationsService: NotificationsService,
                private authService: AuthService,
                private cd: ChangeDetectorRef) {

    }

    private getSearch() {
        return {
            userId: this.authService.UserId
        }
    }

    loadData() {

        let list = this.notificationsService.list(JSON.stringify(this.getSearch()), 0, 10000, 'id desc');
        return list.pipe(map((response) => {
            console.log(response)
            this.length = 0;
              // @ts-ignore
              response.data.forEach(reg => {
                  if (this.authService.UserId === reg.user_id) {
                      if (!reg.read_it) {
                          this.length++;
                          this.cd.detectChanges();
                          this.cd.markForCheck();
                      }
                  }
              });
        }));
    }

    ngOnInit() {

       timer(0, 60000).pipe(
            tap((x) => console.log(x)),
            takeUntil(this.unsub),
            switchMap(() => this.loadData())
        ).subscribe();

    }

    showPopover() {
        this.dropdownOpen = true;
        this.cd.markForCheck();

        const popoverRef = this.popover.open({
            content: ToolbarNotificationsDropdownComponent,
            origin: this.originRef,
            offsetY: 12,
            position: [
                {
                    originX: 'center',
                    originY: 'top',
                    overlayX: 'center',
                    overlayY: 'bottom'
                },
                {
                    originX: 'end',
                    originY: 'bottom',
                    overlayX: 'end',
                    overlayY: 'top',
                },
            ]
        });

        popoverRef.afterClosed$.subscribe(() => {
            this.dropdownOpen = false;
            this.cd.markForCheck();
        });
    }
}
