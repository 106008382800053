<vex-page-layout mode="card">

    <vex-page-layout-header class="pb-16 flex flex-col items-start justify-center">
        <div [class.container]="layoutCtrl.value === 'boxed'"
             [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
             class="w-full flex flex-col sm:flex-row justify-between">
            <div>
                <h1 class="title mt-0 mb-1">{{ "MENUS.RESPONSABLES" | translate }}</h1>
                <vex-breadcrumbs
                        [crumbs]="['MENUS.ADMINISTRACION', 'MENUS.RESPONSABLES']"></vex-breadcrumbs>
            </div>

        </div>
    </vex-page-layout-header>


    <div [@stagger]="true" class="p-gutter container">

        <div @fadeInUp class="card flex-auto">

            <mat-tab-group mat-stretch-tabs>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span [matBadge]="dataSourceNotResponsible.data.length"
                              matBadgeOverlap="false">{{ 'LABELS.USERS_WITHOUT_RESPONSIBLE' | translate }}</span>
                    </ng-template>
                    <div class="body table-responsive">

                        <table @stagger [dataSource]="dataSourceNotResponsible" class="w-full" mat-table matSort>

                            <!-- Text Columns -->
                            <ng-container *ngFor="let column of columns; trackBy: trackByProperty">

                                <ng-container *ngIf="column.type === 'text' && column.property !== 'roleName' "
                                              [matColumnDef]="column.property">
                                    <th *matHeaderCellDef class="uppercase" mat-header-cell
                                        mat-sort-header> {{ column.label | translate }}
                                    </th>
                                    <td *matCellDef="let row" [ngClass]="column.cssClasses"
                                        mat-cell>{{ row[column.property] }}
                                    </td>
                                </ng-container>
                            </ng-container>

                            <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
                            <tr *matRowDef="let row; columns: visibleColumns;"
                                @fadeInUp
                                class="hover:bg-hover trans-ease-out cursor-pointer"
                                mat-row></tr>

                        </table>

                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span [matBadge]="dataSourceTodosResponsible.data.length"
                              matBadgeOverlap="false">{{ 'LABELS.USERS_FULL_RESPONSIBLE' | translate }}</span>
                    </ng-template>
                    <div class="body table-responsive">


                        <table @stagger [dataSource]="dataSourceTodosResponsible" class="w-full" mat-table matSort>

                            <!-- Text Columns -->
                            <ng-container *ngFor="let column of columns; trackBy: trackByProperty">

                                <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
                                    <th *matHeaderCellDef class="uppercase" mat-header-cell
                                        mat-sort-header> {{ column.label | translate }}
                                    </th>
                                    <td *matCellDef="let row" [ngClass]="column.cssClasses"
                                        mat-cell>{{ row[column.property] }}
                                    </td>
                                </ng-container>

                            </ng-container>

                            <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
                            <tr *matRowDef="let row; columns: visibleColumns;"
                                @fadeInUp
                                class="hover:bg-hover trans-ease-out cursor-pointer"
                                mat-row></tr>

                        </table>
                    </div>
                </mat-tab>

            </mat-tab-group>
        </div>


        <div class="mt-5 flex flex-col sm:flex-row gap-6">

            <div @fadeInUp class="card flex-auto">

                <div class="px-6 py-4 border-b flex items-center">
                    <h2 class="title m-0">Responsables</h2>
                </div>

                <form [formGroup]="searchForm">
                    <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">

                        <div
                                class="bg-foreground rounded-full px-4 h-10 max-w-[400px] flex-auto flex items-center border border-gray-300">
                            <mat-icon class="icon-sm text-secondary" svgIcon="mat:person"></mat-icon>
                            <input formControlName="user"
                                   class="px-4 py-2 border-0 outline-none w-full bg-transparent"
                                   [placeholder]="'ACTIONS.BUSCAR_USUARIOS' | translate"
                                   type="search">
                        </div>

                        <button
                                class="ml-4 flex-none"
                                color="primary"
                                mat-mini-fab
                                [matTooltip]="'ACTIONS.BUSCAR_USUARIOS' | translate "
                                type="button"
                                (click)="buscar()">
                            <mat-icon svgIcon="mat:search"></mat-icon>
                        </button>


                        <button
                                class="ml-4 flex-none"
                                color="primary"
                                mat-mini-fab
                                [matTooltip]="'ACTIONS.RESET' | translate "
                                type="button"
                                (click)="reset()">
                            <mat-icon svgIcon="mat:refresh"></mat-icon>
                        </button>


                    </div>
                </form>
                <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>

                    <!-- Text Columns -->
                    <ng-container *ngFor="let column of columns; trackBy: trackByProperty">

                        <ng-container *ngIf="column.type === 'text' && column.property !== 'roleName' "
                                      [matColumnDef]="column.property">
                            <th *matHeaderCellDef class="uppercase" mat-header-cell
                                mat-sort-header> {{ column.label | translate }}
                            </th>
                            <td *matCellDef="let row" [ngClass]="column.cssClasses"
                                mat-cell>{{ row[column.property] }}
                            </td>
                        </ng-container>
                    </ng-container>

                    <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
                    <tr (click)="selectResponsible(row)"
                        *matRowDef="let row; columns: visibleColumns;"
                        @fadeInUp
                        [ngClass]="{'selected': isResponsible(row)}"
                        class="hover:bg-hover trans-ease-out cursor-pointer"
                        mat-row></tr>

                </table>
                <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" (page)="pageChanged($event)"
                               class="sticky left-0"></mat-paginator>
            </div>

            <div @fadeInUp class="card flex-auto">
                <div class="px-6 py-4 border-b flex items-center">
                    <h2 class="title m-0">Usuarios</h2>
                    <span class="flex-1"></span>

                    <button
                            class="mr-4 flex-none"
                            color="primary"
                            mat-mini-fab
                            [matTooltip]="'ACTIONS.EXPORTAR' | translate "
                            type="button"
                            (click)="export()">
                        <mat-icon svgIcon="mat:cloud_download"></mat-icon>
                    </button>

                    <button color="primary" mat-flat-button (click)="guardar()"
                            [disabled]="!responsible">{{ "ACTIONS.GUARDAR" | translate }}
                    </button>
                </div>

                <form [formGroup]="searchFormUsers">
                    <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">

                        <div
                                class="bg-foreground rounded-full px-4 h-10 max-w-[250px] flex-auto flex items-center border border-gray-300">
                            <mat-icon class="icon-sm text-secondary" svgIcon="mat:person"></mat-icon>
                            <input formControlName="user"
                                   class="px-4 py-2 border-0 outline-none w-full bg-transparent"
                                   [placeholder]="'ACTIONS.BUSCAR_USUARIOS' | translate"
                                   type="search">
                        </div>


                        <button
                                class="ml-4 flex-none"
                                color="primary"
                                mat-mini-fab
                                [matTooltip]="'ACTIONS.BUSCAR_USUARIOS' | translate "
                                type="button"
                                (click)="buscarUsers()">
                            <mat-icon svgIcon="mat:search"></mat-icon>
                        </button>


                        <span class="flex-1"></span>

                        <button
                                class="ml-4 flex-none"
                                color="warn"
                                mat-mini-fab
                                [matTooltip]="'ACTIONS.LIMPIAR_TODOS' | translate "
                                [disabled]="!responsible"
                                type="button"
                                (click)="deselectAll()">
                            <mat-icon svgIcon="mat:clear"></mat-icon>
                        </button>

                        <button
                                class="ml-4 flex-none"
                                color="primary"
                                mat-mini-fab
                                [matTooltip]="'ACTIONS.APLICAR_TODOS' | translate "
                                [disabled]="!responsible"
                                type="button"
                                (click)="selectAll()">
                            <mat-icon svgIcon="mat:check"></mat-icon>
                        </button>

                        <button
                                class="ml-4 flex-none"
                                color="primary"
                                mat-mini-fab
                                [disabled]="!responsible"
                                [matTooltip]="'ACTIONS.FILTRAR_USUARIOS' | translate "
                                (click)="$event.stopPropagation()"
                                [matMenuTriggerFor]="actionsMenu"
                                type="button">
                            <mat-icon svgIcon="mat:more_horiz"></mat-icon>
                        </button>

                    </div>
                    <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
                        <div
                                class="bg-foreground rounded-full px-4 h-10 max-w-[250px] flex-auto flex items-center border border-gray-300">
                            <mat-select formControlName="subArea"
                                        placeholder="{{ 'GRIDS.SELECCIONE_SUB_AREA' | translate }}">
                                <mat-option *ngFor="let subarea of subAreasList" [value]="subarea.ID">
                                    {{ subarea.name }}
                                </mat-option>
                            </mat-select>

                            <button type="button" *ngIf="searchFormUsers.get('subArea').value" matSuffix mat-icon-button
                                    aria-label="Clear" (click)="reset() ">
                                <mat-icon>close</mat-icon>
                            </button>

                        </div>

                        <span class="flex-1"></span>

                        <button
                                class="ml-4 flex-none"
                                mat-mini-fab
                                [matTooltip]="'ACTIONS.SELECCIONAR_ALL_SUBAREA' | translate "
                                [disabled]="!searchFormUsers.get('subArea').value"
                                type="button"
                                (click)="selectAllSubArea()">
                            <mat-icon svgIcon="mat:dns"></mat-icon>
                        </button>

                    </div>
                </form>

                <table @stagger [dataSource]="dataSourceUsers" class="w-full" mat-table matSort>

                    <!-- Text Columns -->
                    <ng-container *ngFor="let column of columnsSelected; trackBy: trackByProperty">

                        <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
                            <th *matHeaderCellDef class="uppercase" mat-header-cell
                                mat-sort-header> {{ column.label | translate }}
                            </th>
                            <td *matCellDef="let row" [ngClass]="column.cssClasses"
                                mat-cell>{{ row[column.property] }}
                            </td>
                        </ng-container>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
                        <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
                            <mat-slide-toggle (change)="selectUser($event, row)"
                                              [checked]="getChecked(row)"></mat-slide-toggle>
                        </td>
                    </ng-container>


                    <tr *matHeaderRowDef="visibleColumnsSelected" mat-header-row></tr>
                    <tr
                            *matRowDef="let row; columns: visibleColumnsSelected;"
                            @fadeInUp
                            class="hover:bg-hover trans-ease-out cursor-pointer"
                            mat-row></tr>

                </table>


                <mat-paginator #MatPaginatorUsers [pageSizeOptions]="pageSizeOptionsUsers" [pageSize]="pageSizeUsers"
                               (page)="pageChangedUsers($event)"
                               class="sticky left-0"></mat-paginator>
            </div>
        </div>

        <div class="mt-2" align="end">
            <button color="primary" mat-flat-button (click)="guardar()"
                    [disabled]="!responsible">{{ "ACTIONS.GUARDAR" | translate }}
            </button>
        </div>
    </div>
</vex-page-layout>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
    <ng-template let-user="user" matMenuContent>
        <button (click)="mostrar('ALL')" mat-menu-item>
            <span>{{ "ACTIONS.TODOS" | translate }}</span>
        </button>
        <button (click)="mostrar('SELECTED')" mat-menu-item>
            <span>{{ "ACTIONS.SELECCIONADOS" | translate }}</span>
        </button>
        <button (click)="mostrar('NOT_SELECTED')" mat-menu-item>
            <span>{{ "ACTIONS.NO_SELECCIONADOS" | translate }}</span>
        </button>
    </ng-template>
</mat-menu>
