import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {WorkedHour, WorkedHoursData} from "@core/services/backend/common/interface/workedHours";
import {WorkedHoursApi} from "@core/services/backend/common/api/workedHours.api";


@Injectable()
export class WorkedHoursService extends WorkedHoursData {

  constructor(private api: WorkedHoursApi) {
    super();
  }

  list(search: string, pageNumber: number, pageSize: number, orderBy: string): Observable<any[]> {
    return this.api.list(search, pageNumber, pageSize, orderBy);
  }

  get(workedHour: any): Observable<WorkedHour> {
    return this.api.get(workedHour);
  }

  // tslint:disable-next-line:no-shadowed-variable
  create(workedHour: any): Observable<WorkedHour> {
    return this.api.add(workedHour);
  }

  // tslint:disable-next-line:no-shadowed-variable
  update(workedHour: any): Observable<WorkedHour> {
    return this.api.update(workedHour);
  }

  details(search: string, pageNumber: number, pageSize: number, orderBy: string): Observable<any[]> {
    return this.api.details(search, pageNumber, pageSize, orderBy);
  }

  pendingStatus(search: string, pageNumber: number, pageSize: number, orderBy: string): Observable<any[]> {
    return this.api.pendingStatus(search, pageNumber, pageSize, orderBy);
  }


}
