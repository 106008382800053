<div>
    <div mat-dialog-title>
        <h2 class="headline m-0 flex-auto">{{ userName }}</h2>
        <h3 class="headline m-0 flex-auto">{{ projectName }}</h3>
        <h3 class="headline m-0 flex-auto">{{ date }}</h3>
    </div>
    <mat-divider class="-mx-6 text-border"></mat-divider>
    <mat-dialog-content class="flex flex-col">

        <table @stagger [dataSource]="dataSource" class="w-full" mat-table>

            <!-- Text Columns -->
            <ng-container *ngFor="let column of columns; trackBy: trackByProperty">

                <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
                    <th *matHeaderCellDef class="uppercase" mat-header-cell
                    > {{ column.label | translate }}
                    </th>
                    <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] }}</td>
                </ng-container>

            </ng-container>

            <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
            <tr
                    *matRowDef="let row; columns: visibleColumns;"
                    @fadeInUp
                    class="hover:bg-hover trans-ease-out cursor-pointer"
                    mat-row></tr>

        </table>

    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close type="button">{{ "LINKS.CANCELAR" | translate }}</button>
    </mat-dialog-actions>

</div>
