import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";

@Injectable()
export class RedirectGuard implements CanActivate {
    constructor(private router: Router) {
        console.log("on")
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        console.log(route, state)
        window.open(route.data['externalUrl'], "_blank");
        return true;
    }
}
