import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, UntypedFormControl} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {Project} from "@core/services/backend/common/interface/projects";
import {TableColumn} from "@vex/interfaces/table-column.interface";
import {fadeInUp400ms} from "@vex/animations/fade-in-up.animation";
import {stagger40ms} from "@vex/animations/stagger.animation";
import {Subject, takeUntil} from "rxjs";
import {UsersService} from "@core/services/backend/common/service/users.service";
import {User} from "@core/services/backend/common/interface/users";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatDialog} from "@angular/material/dialog";
import {UserCreateUpdateComponent} from "@app/pages/administration/user-create-update/user-create-update.component";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SubAreasService} from "@core/services/backend/common/service/subAreas.service";
import {RolesService} from "@core/services/backend/common/service/roles.service";
import {AuthService} from "@core/guard/auth-service";
import {TableUtil} from "@vex/services/tableUtil";

@Component({
    selector: 'vex-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    animations: [
        fadeInUp400ms,
        stagger40ms
    ]
})
export class UsersComponent implements OnInit {

    private readonly destroying$ = new Subject<void>();
    layoutCtrl = new UntypedFormControl('boxed');

    userList: User[] = [];
    subAreasList = []
    rolesList = []
    dataSource: MatTableDataSource<User> | null;
    searchForm: FormGroup;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    // displayedColumns: string[] = ['firstName', 'lastName', 'email', 'isAdmin', 'actions'];
    data = {
        projectSelected: ''
    }
    isSuperAdmin = false;
    currentPage = 0;
    pageSize = 5;
    pageSizeOptions: number[] = [5, 10, 20, 50];

    columns: TableColumn<Project>[] = [
        {label: 'GRIDS.ID', property: 'ID', type: 'text', visible: false},
        {
            label: 'GRIDS.NOMBRE_USUARIO',
            property: 'firstName',
            type: 'text',
            visible: true,
            cssClasses: ['font-medium']
        },
        {label: 'GRIDS.APELLIDOS', property: 'lastName', type: 'text', visible: true},
        {label: 'GRIDS.EMAIL', property: 'email', type: 'text', visible: true},
        {label: 'GRIDS.SUBAREA', property: 'subAreaName', type: 'text', visible: true},
        {label: 'GRIDS.ROL', property: 'roleName', type: 'text', visible: true},
        {label: 'GRIDS.ACCIONES', property: 'actions', type: 'button', visible: true}
    ];

    constructor(private users: UsersService,
                private dialog: MatDialog,
                private translateService: TranslateService,
                private snackbar: MatSnackBar,
                private subAreasService: SubAreasService,
                private rolesService: RolesService,
                private authService: AuthService
    ) {
console.log('users', users);
        this.isSuperAdmin = authService.Role === 'SUPER_ADMIN';
        const that = this
        let list = this.subAreasService.list('', 0, 10000, '');
        list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
            // @ts-ignore
            that.subAreasList = data.data;
        });
        list = this.rolesService.list('', 0, 10000, '');
        list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
            // @ts-ignore
            that.rolesList = data.data;
        });
    }

    ngOnInit(): void {

        this.dataSource = new MatTableDataSource();

        this.searchForm = new FormGroup({
            user: new FormControl()
        })
        this.loadData()

    }

    loadData() {
        const that = this
        this.initializeData().then(() => {
            this.dataSource.data = that.userList;
        })

    }

    private getSearch() {
        return {
            name: this.searchForm.get("user").value ? '%' + this.searchForm.get("user").value.toString().toUpperCase() + '%' : ''
        }
    }

    async initializeData() {
        const that = this;
        return await new Promise<void>((resolve, reject) => {
            const list = this.users.list(JSON.stringify(that.getSearch()), that.currentPage, that.pageSize, '', true);
            list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
                // @ts-ignore
                that.userList = data.data;


                that.userList.forEach(user => {
                    // @ts-ignore
                    let subArea = that.subAreasList.filter(subarea => subarea.ID === user.subAreasId)
                    if (subArea.length != 0) {
                        // @ts-ignore
                        user.subAreaName = subArea[0].name;
                    }

                    // @ts-ignore
                    let role = that.rolesList.filter(role => role.ID === user.usersRoleId)
                    if (role.length != 0) {
                        // @ts-ignore
                        user.roleName = role[0].name;
                    }

                })
                that.paginator.pageIndex = that.currentPage;
                // @ts-ignore
                that.paginator.length = data.total_records;
                console.log(that.userList)
                resolve();
            });
        });
    }

    pageChanged(event: PageEvent) {
        console.log({event});
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        this.loadData();
    }

    trackByProperty<T>(index: number, column: TableColumn<T>) {
        return column.property;
    }

    get visibleColumns() {
        return this.columns.filter(column => column.visible).map(column => column.property);
    }

    buscar() {
        this.loadData();
    }

    createUser() {
        this.dialog.open(UserCreateUpdateComponent, {
            maxWidth: '100vw',
            maxHeight: '100vh',
            //height: '50%',
            width: '60%',
            panelClass: 'full-screen-modal'
        }).afterClosed().subscribe((user: User) => {
            this.loadData();
        });
    }

    updateUser(user: User) {
        // @ts-ignore
        if ((user.roleName === 'SUPER_ADMIN' && this.isSuperAdmin) || user.roleName != 'SUPER_ADMIN') {
            this.dialog.open(UserCreateUpdateComponent, {
                data: user,
                maxWidth: '100vw',
                maxHeight: '100vh',
                //height: '50%',
                width: '60%',
                panelClass: 'full-screen-modal'
            }).afterClosed().subscribe(updatedCustomer => {
                this.loadData();
            });
        }
    }

    deleteUser(user: User) {
        const that = this;
        this.users.delete(user).subscribe({
            next(result) {
                that.handleSuccessResponse("USUARIO.DELETE");
                that.loadData();
            },
            error(err) {
                that.handleWrongResponse("USUARIO.DELETE_ERROR");
            }
        });
    }

    handleSuccessResponse(msg) {
        console.log("USUARIO")
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            this.snackbar.open(eval("res." + msg), '', {
                duration: 3000
            });
        });
    }

    handleWrongResponse(msg) {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            this.snackbar.open(eval("res." + msg), '', {
                duration: 3000
            });
        });
    }

    resetPassword(user: User) {
        const that = this;
        const userUpdate = {
            id: user.ID
        }
        this.users.reset(user).subscribe({
            next(result) {
                that.handleSuccessResponse("USUARIO.UPDATE");
            },
            error(err) {
                that.handleWrongResponse("USUARIO.UPDATE_ERROR");
            }
        });
    }

    reestablecer(user: User) {
        const that = this;
        const userUpdate = {
            id: user.ID
        }
        this.users.reset(user).subscribe({
            next(result) {
                that.handleSuccessResponse("USUARIO.UPDATE");
            },
            error(err) {
                that.handleWrongResponse("USUARIO.UPDATE_ERROR");
            }
        });
    }


    isDeleted(row) {
        // @ts-ignore
        return row.DeletedAt !== null;
    }

    export() {
        const that = this;
        const list = this.users.list('', 0, 100000, '', true);
        list.pipe(takeUntil(this.destroying$)).subscribe((data) => {

            const result = [];
            // @ts-ignore
            data.data.forEach((user) => {

                const data = {
                    id: user.ID,
                    Rut: user.rut,
                    Nombres: user.firstName,
                    Apellidos: user.lastName,
                    Email: user.email,
                }
                if ( data.Rut.indexOf('-') === -1) {
                    data.Rut = user.rut.substring(0,user.rut.length-1) + '-' + user.rut.substring(user.rut.length-1,user.rut.length);
                }
                // @ts-ignore
                data.Rut = data.Rut.replaceAll(".","");

                let subArea = that.subAreasList.filter(subarea => subarea.ID === user.subAreasId)
                if (subArea.length != 0) {
                    // @ts-ignore
                    data.SubArea = subArea[0].name;
                }

                // @ts-ignore
                let role = that.rolesList.filter(role => role.ID === user.usersRoleId)
                if (role.length != 0) {
                    // @ts-ignore
                    data.Rol = role[0].name;
                }
                result.push(data)
            })
            TableUtil.exportArrayToExcel(result, "users");

        });
    }
}
