import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, UntypedFormControl} from "@angular/forms";
import {Subject, takeUntil} from "rxjs";
import * as XLSX from "xlsx";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ProjectsService} from "@core/services/backend/common/service/projects.service";
import {Project} from "@core/services/backend/common/interface/projects";
import {AuthService} from "@core/guard/auth-service";

@Component({
    selector: 'vex-bulk-projects',
    templateUrl: './bulk-projects.component.html',
    styleUrls: ['./bulk-projects.component.scss']
})
export class BulkProjectsComponent implements OnInit {

    layoutCtrl = new UntypedFormControl('boxed');
    private readonly destroying$ = new Subject<void>();

    form: FormGroup;
    insertList: Project[] = [];
    updateList: Project[] = [];
    deleteList: Project[] = [];
    projectList: Project[] = [];

    processing: boolean;

    displayedColumns: string[] = [
        'id',
        'proyecto',
        'codigo',
    ];


    constructor(
        private authService: AuthService,
        private translateService: TranslateService,
        private snackbar: MatSnackBar,
        private projects: ProjectsService,
        private changeDetectorRefs: ChangeDetectorRef) {
    }

    ngOnInit(): void {

        const that = this;

        this.form = new FormGroup({
            name: new FormControl({value: "", disabled: true})
        })

        const list = this.projects.list('', 0, 100000000, 'code', true);
        list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
            // @ts-ignore
            that.projectList = data.data;
        });

        this.processing = false;

    }

    openFile() {
        // @ts-ignore
        document.querySelector('input.arch').click()
    }

    handle(e) {
        const target: DataTransfer = <DataTransfer>(e.target);
        this.form.setValue({"name": e.target.files[0].name});

        if (target.files.length > 1) {
            alert('Multiple files are not allowed');
            return;
        } else {
            const reader: FileReader = new FileReader();
            reader.onload = (e: any) => {
                const bstr: string = e.target.result;
                const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});
                const wsname = wb.SheetNames[0];
                const ws: XLSX.WorkSheet = wb.Sheets[wsname];
                let data = (XLSX.utils.sheet_to_json(ws, {header: 1}));
                // Print the Excel Data
                console.log(data);
                const headerValid = ['ID', 'Proyecto', 'Codigo']
                const header = data[0];

                if (!this.compareHeaders(headerValid, header)) {
                    this.handleNotMatchResponse();
                    return;
                }

                const updateList: Project[] = [];
                const insertList: Project[] = [];
                const deleteList: Project[] = [];

                data = data.slice(1);
                data.forEach((project) => {
                    // @ts-ignore
                    const p = this.projectList.filter(p => project[2] === p.code);
                    if (p.length > 0) {
                        updateList.push(p[0]);
                    } else {
                        // @ts-ignore
                        if (project.length !== 0) {
                            const newProject = {
                                'code': String(project[2]),
                                "name": project[1]
                            }
                            // @ts-ignore
                            insertList.push(newProject);
                        }
                    }
                });

                this.projectList.forEach((project) => {
                    // @ts-ignore
                    const p = data.filter(p => project.code === p[2]);
                    if (p.length === 0) {
                        // @ts-ignore
                        const deleteProject = {
                            'code': String(project.code),
                            "name": project.name
                        }
                        // @ts-ignore
                        deleteProject.ID = project.ID;
                        // @ts-ignore
                        deleteList.push(deleteProject);
                    }
                });

                this.insertList = [...insertList];
                this.updateList = [...updateList];
                this.deleteList = [...deleteList];
                this.changeDetectorRefs.detectChanges();
                this.changeDetectorRefs.markForCheck();

            }
            reader.readAsBinaryString(target.files[0]);
            this.processing = true;
        }

    }

    handleNotMatchResponse() {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            const message = res.EXPORT.FILE_NOT_MATCH;
            this.snackbar.open(message, '', {
                duration: 3000
            });
        });
    }


    compareHeaders = (first, second) => {
        return (
            first.length === second.length &&
            first.every((element_1) =>
                second.some((element_2) =>
                    Object.keys(element_1).every((key) => element_1[key] === element_2[key])
                )
            )
        );
    };


    getBadgeUpdate() {
        return Number(this.updateList.length);
    }

    getBadgeInsert() {
        return Number(this.insertList.length);
    }

    getBadgeDelete() {
        return Number(this.deleteList.length);
    }

    Procesar() {
        this.processing = true;
        const that = this;
        const projects = {
            userId: this.authService.UserId,
            inserts: this.insertList,
            updates: this.updateList,
            deletes: this.deleteList
        }
        this.projects.bulk(projects).subscribe({
            next(result) {
                that.handleSuccessResponse();
                this.processing = false;
            },
            error(err) {
                that.handleWrongResponse(err);
                this.processing = false;
            }
        });
    }

    handleSuccessResponse() {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            const message = res.PROYECTO.BULK_SUCCESS
            this.snackbar.open(message, '', {
                duration: 3000
            });
        });
    }

    handleWrongResponse(err) {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            const message = res.PROYECTO.BULK_ERROR
            this.snackbar.open(message, '', {
                duration: 3000
            });
        });
    }
}
