<vex-page-layout mode="card">

    <vex-page-layout-header class="pb-16 flex flex-col items-start justify-center">
        <div [class.container]="layoutCtrl.value === 'boxed'"
             [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
             class="w-full flex flex-col sm:flex-row justify-between">
            <div>
                <h1 class="title mt-0 mb-1">{{ "MENUS.USUARIOS" | translate }}</h1>
                <vex-breadcrumbs
                    [crumbs]="['MENUS.ADMINISTRACION', 'MENUS.USUARIOS']"></vex-breadcrumbs>
            </div>

        </div>
    </vex-page-layout-header>

    <vex-page-layout-content class="-mt-6">

        <div>
            <form [formGroup]="searchForm">
                <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">

                    <div
                        class="bg-foreground rounded-full px-4 h-10 max-w-[400px] flex-auto flex items-center border border-gray-300">
                        <mat-icon class="icon-sm text-secondary" svgIcon="mat:person"></mat-icon>
                        <input formControlName="user"
                               class="px-4 py-2 border-0 outline-none w-full bg-transparent"
                               [placeholder]="'ACTIONS.BUSCAR_USUARIOS' | translate"
                               type="search">
                    </div>

                    <button
                        class="ml-4 flex-none"
                        color="primary"
                        mat-mini-fab
                        [matTooltip]="'ACTIONS.BUSCAR_USUARIOS' | translate "
                        type="button"
                        (click)="buscar()">
                        <mat-icon svgIcon="mat:search"></mat-icon>
                    </button>

                    <span class="flex-1"></span>


                    <button
                        class="ml-4 flex-none"
                        color="primary"
                        mat-mini-fab
                        [matTooltip]="'ACTIONS.AGREGAR_USUARIO' | translate "
                        (click)="createUser()"
                        type="button">
                        <mat-icon svgIcon="mat:add"></mat-icon>
                    </button>

                    <button
                        class="ml-4 flex-none"
                        color="primary"
                        mat-mini-fab
                        [matTooltip]="'ACTIONS.EXPORTAR' | translate "
                        type="button"
                        (click)="export()">
                        <mat-icon svgIcon="mat:cloud_download"></mat-icon>
                    </button>

                </div>
            </form>

            <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>

                <!-- Text Columns -->
                <ng-container *ngFor="let column of columns; trackBy: trackByProperty">

                    <ng-container *ngIf="column.type === 'text' && column.property !== 'roleName' "
                                  [matColumnDef]="column.property">
                        <th *matHeaderCellDef class="uppercase" mat-header-cell
                            mat-sort-header> {{ column.label | translate }}</th>
                        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] }}</td>
                    </ng-container>
                    <ng-container *ngIf="column.property === 'roleName'" [matColumnDef]="column.property">
                        <th *matHeaderCellDef class="uppercase" mat-header-cell
                            mat-sort oka-header> {{ column.label | translate }}</th>
                        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
                            <mat-chip-list>
                             <mat-chip color="primary">
                                 {{ "ROLES." + row.roleName | translate }}</mat-chip>
                            </mat-chip-list>

                        </td>
                    </ng-container>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
                    <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
                        <button
                                *ngIf="( row.roleName === 'SUPER_ADMIN' && isSuperAdmin ) || row.roleName != 'SUPER_ADMIN'"
                                (click)="$event.stopPropagation()"
                                [matMenuTriggerData]="{ user: row }"
                                [matMenuTriggerFor]="actionsMenu"
                                mat-icon-button
                                type="button">
                            <mat-icon svgIcon="mat:more_horiz"></mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
                <tr (click)="updateUser(row)"
                    *matRowDef="let row; columns: visibleColumns;"
                    @fadeInUp
                    class="hover:bg-hover trans-ease-out cursor-pointer"
                    [ngClass]="{'selected': isDeleted(row)}"
                    mat-row></tr>

            </table>

            <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" (page)="pageChanged($event)"
                           class="sticky left-0"></mat-paginator>

        </div>

    </vex-page-layout-content>


</vex-page-layout>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
    <ng-template let-user="user" matMenuContent>
        <button (click)="updateUser(user)" mat-menu-item>
            <mat-icon svgIcon="mat:edit"></mat-icon>
            <span>{{ "ACTIONS.EDITAR" | translate }}</span>
        </button>
        <button (click)="deleteUser(user)" *ngIf="!isDeleted(user)"  mat-menu-item>
            <mat-icon svgIcon="mat:delete"></mat-icon>
            <span>{{ "ACTIONS.ELIMINAR" | translate }}</span>
        </button>
        <button (click)="resetPassword(user)" mat-menu-item>
            <mat-icon svgIcon="mat:refresh"></mat-icon>
            <span>{{ "ACTIONS.RESET_PASSWORD" | translate }}</span>
        </button>
        <button (click)="reestablecer(user)" *ngIf="isDeleted(user)" mat-menu-item>
            <mat-icon svgIcon="mat:undo"></mat-icon>
            <span>{{ "ACTIONS.REESTABLECER" | translate }}</span>
        </button>
    </ng-template>
</mat-menu>
