import {Observable} from 'rxjs';
import {Project} from "@core/services/backend/common/interface/projects";

export interface User {
    name: string;
    role: any;
    subArea: any;
    //name: string;
    ID: number;
    lastName: string;
    firstName: string;
    email: string;
    isAdmin: boolean;
    startDate: string;
    endDate: string;
    status: string;
    rut: string;
}

export abstract class UsersData {

    abstract list(search: string, pageNumber: number, pageSize: number, orderBy: string, scope: boolean): Observable<any>;

    abstract get(id: number, cloudId: number): Observable<User>;

    abstract update(user: User): Observable<User>;

    abstract create(user: User): Observable<User>;

    abstract delete(user: User): Observable<User>;

    abstract reset(user: User): Observable<User>;

    abstract bulk(user: { deletes: User[]; updates: User[]; inserts: User[] }): Observable<User>;

}
