import {Observable} from 'rxjs';

export interface Group {
    name: string;
    id: number;
}

export abstract class GroupsData {

    abstract list(search: string, pageNumber: number, pageSize: number, orderBy: string): Observable<any>;

    abstract get(id: number, cloudId: number): Observable<Group>;

    abstract update(user: Group): Observable<Group>;

    abstract create(user: Group): Observable<Group>;

    abstract delete(user: Group): Observable<Group>;

}
