import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {AuthGuard} from "@core/guard/auth.guard";
import {ProjectsComponent} from "@app/pages/administration/projects/projects.component";
import {QuicklinkModule} from "ngx-quicklink";
import {UsersComponent} from "@app/pages/administration/users/users.component";
import {ExternalLinksComponent} from "@app/pages/administration/external-links/external-links.component";
import {BulkUsersComponent} from "@app/pages/administration/process/bulk-users/bulk-users.component";
import {BulkProjectsComponent} from "@app/pages/administration/process/bulk-projects/bulk-projects.component";
import {GroupsComponent} from "@app/pages/administration/groups/groups.component";
import {ResponsibleUsersComponent} from "@app/pages/administration/responsible/users/responsible.users.component";
import {
    ResponsibleProjectsComponent
} from "@app/pages/administration/responsible/proyects/responsible-projects.component";
import {HoursAutoComponent} from "@app/pages/administration/hours-auto/hours-auto.component";


const routes: Routes = [
    {
        path: "projects",
        component: ProjectsComponent,
        canActivate: [AuthGuard],
        data: {role: 'SUPER_ADMIN,ADMIN'}
    },
    {
        path: "groups",
        component: GroupsComponent,
        canActivate: [AuthGuard],
        data: {role: 'SUPER_ADMIN,ADMIN'}
    },
    {
        path: "users",
        component: UsersComponent,
        canActivate: [AuthGuard],
        data: {role: 'SUPER_ADMIN,ADMIN'}
    },
    {
        path: "external-links",
        component: ExternalLinksComponent,
        canActivate: [AuthGuard],
        data: {role: 'SUPER_ADMIN,ADMIN'}
    },
    {
        path: "hours-auto",
        component: HoursAutoComponent,
        canActivate: [AuthGuard],
        data: {role: 'SUPER_ADMIN'}
    },
    {
        path: "responsible/users",
        component: ResponsibleUsersComponent,
        canActivate: [AuthGuard],
        data: {role: 'SUPER_ADMIN,ADMIN'}
    },
    {
        path: "responsible/projects",
        component: ResponsibleProjectsComponent,
        canActivate: [AuthGuard],
        data: {role: 'SUPER_ADMIN'}
    },
    {
        path: "process/bulk-users",
        component: BulkUsersComponent,
        canActivate: [AuthGuard],
        data: {role: 'SUPER_ADMIN,ADMIN'}
    },
    {
        path: "process/bulk-projects",
        component: BulkProjectsComponent,
        canActivate: [AuthGuard],
        data: {role: 'SUPER_ADMIN'}
    },
];


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule, QuicklinkModule]

})
export class AdministrationRoutingModule {
}
