import {Observable} from 'rxjs';

export interface Project {
    name: string;
    id: number;
    code: string;
    force: boolean;
}

export abstract class ProjectsData {

    abstract list(search: string, pageNumber: number, pageSize: number, orderBy: string, scope: boolean): Observable<any>;

    abstract get(id: number, cloudId: number): Observable<Project>;

    abstract update(project: Project): Observable<Project>;

    abstract create(project: Project): Observable<Project>;

    abstract delete(project: Project): Observable<Project>;

    abstract bulk(project: { deletes: Project[]; updates: Project[]; inserts: Project[] }): Observable<Project>;
}
