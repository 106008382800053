<div>
    <div mat-dialog-title>
        <h2 class="headline m-0 flex-auto">{{ userName }}</h2>
        <h3 class="m-0">{{ fecha }}</h3>
    </div>

    <mat-divider class="-mx-6 text-border"></mat-divider>

    <mat-dialog-content class="flex flex-col">

        <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>

            <!-- Text Columns -->
            <ng-container *ngFor="let column of columns; trackBy: trackByProperty">

                <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
                    <th *matHeaderCellDef class="uppercase" mat-header-cell
                        mat-sort-header> {{ column.label | translate }}
                    </th>
                    <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] }}</td>
                </ng-container>

                <ng-container *ngIf="column.type === 'button'" [matColumnDef]="column.property">
                    <th *matHeaderCellDef class="uppercase" mat-header-cell
                        mat-sort-header> {{ column.label | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element" style="text-align: center">
                        <select class="form-control buttondrop"
                                style="width:130px">
                            <option selected [ngValue]="item"
                                    *ngFor="let item of statusList">
                                {{ item }}
                            </option>
                        </select>

                    </td>
                </ng-container>

            </ng-container>

            <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
            <tr
                *matRowDef="let row; columns: visibleColumns;"
                @fadeInUp
                class="hover:bg-hover trans-ease-out cursor-pointer"
                mat-row></tr>

        </table>

    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button color="primary" mat-flat-button type="button"  >{{ "ACTIONS.GUARDAR" | translate }}
        </button>
        <button mat-button mat-dialog-close type="button">{{ "LINKS.CANCELAR" | translate }}</button>
    </mat-dialog-actions>
</div>

