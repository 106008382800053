import {Component, OnInit} from '@angular/core';
import {scaleIn400ms} from "@vex/animations/scale-in.animation";
import {fadeInRight400ms} from "@vex/animations/fade-in-right.animation";
import {Link} from "@vex/interfaces/link.interface";
import {AuthService} from "@core/guard/auth-service";

export interface FriendSuggestion {
    name: string;
    imageSrc: string;
    friends: number;
    added: boolean;
}

@Component({
    selector: 'vex-social',
    templateUrl: './social.component.html',
    styleUrls: ['./social.component.scss'],
    animations: [
        scaleIn400ms,
        fadeInRight400ms
    ]
})
export class SocialComponent implements OnInit {

    links: Link[] = [
        {
            label: "USUARIO.ACERCA_DE",
            route: './',
            routerLinkActiveOptions: {exact: true}
        },
        // {
        //     label: 'TIMELINE',
        //     route: './timeline'
        // },
        // {
        //     label: 'FRIENDS',
        //     route: '',
        //     disabled: true
        // },
        // {
        //     label: 'PHOTOS',
        //     route: '',
        //     disabled: true
        // }
    ];
    activeUser: any;

    constructor(private authService: AuthService) {
        this.activeUser = this.authService.User;
    }

    ngOnInit() {

    }
}
