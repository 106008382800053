import {Observable} from 'rxjs';

export interface Responsible {
    users: string;
    id: number;
    projects: string;
}

export abstract class ResponsibleData {

    abstract list(search: string, pageNumber: number, pageSize: number, orderBy: string): Observable<any>;

    abstract get(id: number, cloudId: number): Observable<Responsible>;

    abstract update(responsible: Responsible): Observable<Responsible>;

    abstract create(responsible: Responsible): Observable<Responsible>;

    abstract delete(responsible: Responsible): Observable<Responsible>;

}
