<vex-page-layout mode="card">

    <vex-page-layout-header class="pb-16 flex flex-col items-start justify-center">
        <div [class.container]="layoutCtrl.value === 'boxed'"
             [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
             class="w-full flex flex-col sm:flex-row justify-between">
            <div>
                <h1 class="title mt-0 mb-1">{{ "MENUS.CARGA_MASIVA_USUARIOS" | translate }}</h1>
                <vex-breadcrumbs
                    [crumbs]="['MENUS.ADMINISTRACION', 'MENUS.PROCESOS']"></vex-breadcrumbs>
            </div>

        </div>
    </vex-page-layout-header>

    <vex-page-layout-content class="-mt-6">
        <form [formGroup]="form">
            <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">

                <div
                    class="bg-foreground rounded-full px-4 h-10 max-w-[600px] flex-auto flex items-center border border-gray-300">
                    <mat-icon class="icon-sm text-secondary" svgIcon="mat:attach_file"></mat-icon>
                    <input formControlName="name"
                           class="px-4 py-2 border-0 outline-none w-full bg-transparent"
                           [placeholder]="'ACTIONS.ARCHIVO_SELECCIONADO' | translate"
                           type="search">
                </div>

                <span class="flex-1"></span>
                <button
                    class="ml-4 flex-none"
                    color="primary"
                    mat-mini-fab
                    [matTooltip]="'ACTIONS.BUSCAR_USUARIOS' | translate "
                    type="button"
                    (click)="openFile()">
                    <input type="file" class="arch" style="display:none" (change)="handle($event)">
                    <mat-icon svgIcon="mat:attach_file"></mat-icon>
                </button>

            </div>

            <mat-tab-group mat-stretch-tabs>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span [matBadge]="getBadgeInsert()"
                              matBadgeOverlap="false">{{'EXPORT.CREATE_USERS' | translate }}</span>
                    </ng-template>
                    <div class="body table-responsive">
                        <table mat-table [dataSource]="insertList" class="w-full">
                            <!-- No Column -->
                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef> {{ "USUARIO.ID" | translate }}</th>
                                <td mat-cell *matCellDef="let element"> {{element.ID}} </td>
                            </ng-container>
                            <!-- Name Column -->
                            <ng-container matColumnDef="Rut">
                                <th mat-header-cell *matHeaderCellDef> {{ "USUARIO.RUT" | translate }} </th>
                                <td mat-cell *matCellDef="let element"> {{element.rut}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Nombres">
                                <th mat-header-cell *matHeaderCellDef> {{ "USUARIO.NOMBRES" | translate }} </th>
                                <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Apellidos">
                                <th mat-header-cell *matHeaderCellDef> {{ "USUARIO.APELLIDOS" | translate }} </th>
                                <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Email">
                                <th mat-header-cell *matHeaderCellDef> {{ "USUARIO.EMAIL" | translate }} </th>
                                <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                            </ng-container>
                            <ng-container matColumnDef="SubArea">
                                <th mat-header-cell *matHeaderCellDef> {{ "USUARIO.SUB_AREA" | translate }} </th>
                                <td mat-cell *matCellDef="let element"> {{element.subArea}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Rol">
                                <th mat-header-cell *matHeaderCellDef> {{ "USUARIO.ROLES" | translate }} </th>
                                <td mat-cell *matCellDef="let element"> {{element.role}} </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span [matBadge]="getBadgeUpdate()"
                              matBadgeOverlap="false">{{'EXPORT.UPDATE_USERS' | translate }}</span>
                    </ng-template>
                    <div class="body table-responsive">
                        <table mat-table [dataSource]="updateList" class="w-full">
                            <!-- No Column -->
                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef> {{ "USUARIO.ID" | translate }}</th>
                                <td mat-cell *matCellDef="let element"> {{element.ID}} </td>
                            </ng-container>
                            <!-- Name Column -->
                            <ng-container matColumnDef="Rut">
                                <th mat-header-cell *matHeaderCellDef> {{ "USUARIO.RUT" | translate }} </th>
                                <td mat-cell *matCellDef="let element"> {{element.rut}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Nombres">
                                <th mat-header-cell *matHeaderCellDef> {{ "USUARIO.NOMBRES" | translate }} </th>
                                <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Apellidos">
                                <th mat-header-cell *matHeaderCellDef> {{ "USUARIO.APELLIDOS" | translate }} </th>
                                <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Email">
                                <th mat-header-cell *matHeaderCellDef> {{ "USUARIO.EMAIL" | translate }} </th>
                                <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                            </ng-container>
                            <ng-container matColumnDef="SubArea">
                                <th mat-header-cell *matHeaderCellDef> {{ "USUARIO.SUB_AREA" | translate }} </th>
                                <td mat-cell *matCellDef="let element"> {{element.subArea}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Rol">
                                <th mat-header-cell *matHeaderCellDef> {{ "USUARIO.ROLES" | translate }} </th>
                                <td mat-cell *matCellDef="let element"> {{element.role}} </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span [matBadge]="getBadgeDelete()"
                              matBadgeOverlap="false">{{'EXPORT.DELETE_USERS' | translate }}</span>
                    </ng-template>
                    <div class="body table-responsive">
                        <table mat-table [dataSource]="deleteList" class="w-full">
                            <!-- No Column -->
                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef> {{ "USUARIO.ID" | translate }}</th>
                                <td mat-cell *matCellDef="let element"> {{element.ID}} </td>
                            </ng-container>
                            <!-- Name Column -->
                            <ng-container matColumnDef="Rut">
                                <th mat-header-cell *matHeaderCellDef> {{ "USUARIO.RUT" | translate }} </th>
                                <td mat-cell *matCellDef="let element"> {{element.rut}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Nombres">
                                <th mat-header-cell *matHeaderCellDef> {{ "USUARIO.NOMBRES" | translate }} </th>
                                <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Apellidos">
                                <th mat-header-cell *matHeaderCellDef> {{ "USUARIO.APELLIDOS" | translate }} </th>
                                <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Email">
                                <th mat-header-cell *matHeaderCellDef> {{ "USUARIO.EMAIL" | translate }} </th>
                                <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                            </ng-container>
                            <ng-container matColumnDef="SubArea">
                                <th mat-header-cell *matHeaderCellDef> {{ "USUARIO.SUB_AREA" | translate }} </th>
                                <td mat-cell *matCellDef="let element"> {{element.subArea}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Rol">
                                <th mat-header-cell *matHeaderCellDef> {{ "USUARIO.ROLES" | translate }} </th>
                                <td mat-cell *matCellDef="let element"> {{element.role}} </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                </mat-tab>
            </mat-tab-group>


            <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">

                <span class="flex-1"></span>

                <button color="primary" mat-flat-button type="button"
                        (click)="Procesar()">{{ "ACTIONS.PROCESAR" | translate }}</button>

            </div>
        </form>
    </vex-page-layout-content>
</vex-page-layout>
