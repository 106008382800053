import {Observable} from 'rxjs';

export interface SubArea {
    name: string;
    ID: number;
}

export abstract class SubAreasData {

    abstract list(search: string, pageNumber: number, pageSize: number, orderBy: string): Observable<any>;

    abstract get(id: number, cloudId: number): Observable<SubArea>;

    abstract update(user: SubArea): Observable<SubArea>;

    abstract create(user: SubArea): Observable<SubArea>;

    abstract delete(user: SubArea): Observable<SubArea>;

}
