import {Observable} from 'rxjs';
import {NotificationsApi} from "@core/services/backend/common/api/notifications.api";

export interface Notifications {

    read_it: boolean;
    message: string;
}

export abstract class NotificationsData {

    abstract list(search: string, pageNumber: number, pageSize: number, orderBy: string): Observable<any>;

    abstract get(id: number, cloudId: number): Observable<Notifications>;

    abstract update(project: Notifications): Observable<Notifications>;

    abstract create(project: Notifications): Observable<Notifications>;

    abstract delete(project: Notifications): Observable<Notifications>;

}
