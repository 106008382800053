import {Component, Input, OnInit} from '@angular/core';
import {NavigationService} from '@vex/services/navigation.service';
import {LayoutService} from '@vex/services/layout.service';
import {ConfigService} from '@vex/config/config.service';
import {map, startWith, switchMap} from 'rxjs/operators';
import {NavigationLink} from '@vex/interfaces/navigation-item.interface';
import {PopoverService} from '@vex/components/popover/popover.service';
import {Observable, of} from 'rxjs';
import {UserMenuComponent} from '@vex/components/user-menu/user-menu.component';
import {MatDialog} from '@angular/material/dialog';
import {SearchModalComponent} from '@vex/components/search-modal/search-modal.component';
import {getPackageVersionFromPackageJson} from "@angular/material/schematics/ng-add/package-config";
import packageJson from '../../../../package.json';

@Component({
    selector: 'vex-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

    @Input() collapsed: boolean;
    collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
    title$ = this.configService.config$.pipe(map(config => config.sidenav.title));
    imageUrl$ = this.configService.config$.pipe(map(config => config.sidenav.imageUrl));
    showCollapsePin$ = this.configService.config$.pipe(map(config => config.sidenav.showCollapsePin));
    userVisible$ = this.configService.config$.pipe(map(config => config.sidenav.user.visible));
    searchVisible$ = this.configService.config$.pipe(map(config => config.sidenav.search.visible));

    userMenuOpen$: Observable<boolean> = of(false);

    items = this.navigationService.items;
    public version: string = packageJson.version;

    constructor(private navigationService: NavigationService,
                private layoutService: LayoutService,
                private configService: ConfigService,
                private readonly popoverService: PopoverService,
                private readonly dialog: MatDialog) {
    }


    ngOnInit() {
    }

    collapseOpenSidenav() {
        this.layoutService.collapseOpenSidenav();
    }

    collapseCloseSidenav() {
        this.layoutService.collapseCloseSidenav();
    }

    toggleCollapse() {
        this.collapsed ? this.layoutService.expandSidenav() : this.layoutService.collapseSidenav();
    }

    trackByRoute(index: number, item: NavigationLink): string {
        return item.route;
    }

    openProfileMenu(origin: HTMLDivElement): void {
        this.userMenuOpen$ = of(
            this.popoverService.open({
                content: UserMenuComponent,
                origin,
                offsetY: -8,
                width: origin.clientWidth,
                position: [
                    {
                        originX: 'center',
                        originY: 'top',
                        overlayX: 'center',
                        overlayY: 'bottom'
                    }
                ]
            })
        ).pipe(
            switchMap(popoverRef => popoverRef.afterClosed$.pipe(map(() => false))),
            startWith(true),
        );
    }

    openSearch(): void {
        this.dialog.open(SearchModalComponent, {
            panelClass: 'vex-dialog-glossy',
            width: '100%',
            maxWidth: '600px'
        });
    }

}
