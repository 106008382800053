import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AutoHours, AutoHoursData} from "@core/services/backend/common/interface/autoHours";
import {AutoHoursApi} from "@core/services/backend/common/api/autoHours.api";


@Injectable()
export class AutoHoursService extends AutoHoursData {

    constructor(private api: AutoHoursApi) {
        super();
    }

    list(search: string, pageNumber: number, pageSize: number, orderBy: string): Observable<any[]> {
        return this.api.list(search, pageNumber, pageSize, orderBy);
    }

    get(id: number): Observable<AutoHours> {
        return this.api.get(id);
    }

    // tslint:disable-next-line:no-shadowed-variable
    create(item: any): Observable<AutoHours> {
        return this.api.add(item);
    }

    // tslint:disable-next-line:no-shadowed-variable
    update(item: any): Observable<AutoHours> {
        return this.api.update(item);
    }

    delete(item: any): Observable<AutoHours> {
        return this.api.delete(item);
    }


}
