<form (ngSubmit)="save()" [formGroup]="form">
    <div class="flex items-center" mat-dialog-title>

        <h2 *ngIf="form.get('name').value"
            class="headline m-0 flex-auto">{{ form.get('name').value }}</h2>
        <h2 *ngIf="!form.get('name').value"
            class="headline m-0 flex-auto">{{ "LABELS.NUEVA_INSERCION" | translate }}</h2>

    </div>

    <mat-divider class="-mx-6 text-border"></mat-divider>

    <mat-dialog-content class="flex flex-col">

        <div class="flex flex-col sm:flex-row">
            <mat-form-field class=" flex-auto">
                <mat-label>{{ "LABELS.USUARIO" | translate }}</mat-label>

                <input type="text"
                       aria-label="Number"
                       matInput
                       formControlName="user"

                       placeholder="{{ 'USUARIO.SELECCIONAR' | translate }}"
                       [matAutocomplete]="autoUser"
                       style="width: 100% !important;">

                <mat-autocomplete #autoUser="matAutocomplete" [displayWith]="displayUserFn"
                                  (optionSelected)="onSelectionUserChange($event)">
                    <mat-option *ngFor="let option of filteredUsers | async" [value]="option">
                        {{ option.name }}
                    </mat-option>
                </mat-autocomplete>

                <button type="button"  matSuffix mat-icon-button
                        aria-label="Clear" (click)="resetUser() ">
                    <mat-icon>close</mat-icon>
                </button>


            </mat-form-field>
        </div>
        <div class="flex flex-col sm:flex-row">
            <mat-form-field class=" flex-auto">
                <mat-label>{{ "LABELS.PROYECTO" | translate }}</mat-label>

                <input type="text"
                       aria-label="Number"
                       matInput
                       formControlName="project"
                       placeholder="{{ 'PROYECTO.SELECCIONAR' | translate }}"
                       [matAutocomplete]="auto"
                       style="width: 100% !important;">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayProjectFn"
                                  (optionSelected)="onSelectionChange($event)">
                    <mat-option *ngFor="let option of filteredProjectList | async" [value]="option">
                        {{ option.name }}
                    </mat-option>
                </mat-autocomplete>

                <button type="button" *ngIf="form.get('project').value" matSuffix mat-icon-button
                        aria-label="Clear" (click)="resetUser() ">
                    <mat-icon>close</mat-icon>
                </button>

            </mat-form-field>
        </div>

        <div class="flex flex-col sm:flex-row">
            <mat-form-field class="flex-auto">
                <mat-label>{{ "LABELS.LUNES" | translate }}</mat-label>
                <input formControlName="lunes" matInput type="number" />
            </mat-form-field>


            <mat-form-field class="sm:ml-6 flex-auto">
                <mat-label>{{ "LABELS.MARTES" | translate }}</mat-label>
                <input formControlName="martes" matInput type="number">
            </mat-form-field>
        </div>
        <div class="flex flex-col sm:flex-row">
            <mat-form-field class="flex-auto">
                <mat-label>{{ "LABELS.MIERCOLES" | translate }}</mat-label>
                <input formControlName="miercoles" matInput type="number" />
            </mat-form-field>


            <mat-form-field class="sm:ml-6 flex-auto">
                <mat-label>{{ "LABELS.JUEVES" | translate }}</mat-label>
                <input formControlName="jueves" matInput type="number">
            </mat-form-field>
        </div>
        <div class="flex flex-col sm:flex-row">
            <mat-form-field class="flex-auto">
                <mat-label>{{ "LABELS.VIERNES" | translate }}</mat-label>
                <input formControlName="viernes" matInput type="number" />
            </mat-form-field>


            <mat-form-field class="sm:ml-6 flex-auto">
                <mat-label>{{ "LABELS.SABADO" | translate }}</mat-label>
                <input formControlName="sabado" matInput type="number">
            </mat-form-field>
        </div>
        <div class="flex flex-col sm:flex-row">
            <mat-form-field class="flex-auto">
                <mat-label>{{ "LABELS.DOMINGO" | translate }}</mat-label>
                <input formControlName="domingo" matInput type="number" />
            </mat-form-field>
        </div>


    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close type="button">{{ "INSERCION.CANCELAR" | translate }}</button>
        <button *ngIf="isCreateMode()" color="primary" mat-flat-button
                type="submit">{{ "INSERCION.CREAR" | translate }}
        </button>
        <button *ngIf="isUpdateMode()" color="primary" mat-flat-button
                type="submit">{{ "INSERCION.ACTUALIZAR" | translate }}
        </button>
    </mat-dialog-actions>
</form>
