import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, UntypedFormControl} from "@angular/forms";
import {ProjectsService} from "@core/services/backend/common/service/projects.service";
import {Observable, Subject, takeUntil} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {Project} from "@core/services/backend/common/interface/projects";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions} from "@angular/material/form-field";
import {fadeInUp400ms} from "@vex/animations/fade-in-up.animation";
import {stagger40ms} from "@vex/animations/stagger.animation";
import {TableColumn} from "@vex/interfaces/table-column.interface";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {User} from "@core/services/backend/common/interface/users";
import {UsersService} from "@core/services/backend/common/service/users.service";


export const aioTableLabels = [
    {
        text: 'true',
        textClass: 'text-green',
        bgClass: 'bg-green-light',
    },
    {
        text: 'PLANNING',
        textClass: 'text-deep-amber',
        bgClass: 'bg-amber-light',
    },
    {
        text: 'EXECUTION',
        textClass: 'text-teal',
        bgClass: 'bg-teal-light',
    },
    {
        text: 'SUPERVISION',
        textClass: 'text-purple',
        bgClass: 'bg-purple-light',
    },
    {
        text: 'CLOSING',
        textClass: 'text-deep-orange',
        bgClass: 'bg-orange-light',
    },
    {
        text: 'false',
        textClass: 'text-red',
        bgClass: 'bg-red-light',
    },
];

@UntilDestroy()
@Component({
    selector: 'vex-activity-planning',
    templateUrl: './activity-planning.component.html',
    styleUrls: ['./activity-planning.component.scss'],
    animations: [
        fadeInUp400ms,
        stagger40ms
    ],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'standard'
            } as MatFormFieldDefaultOptions
        }
    ]
})
export class ActivityPlanningComponent implements OnInit {
    private readonly destroying$ = new Subject<void>();

    layoutCtrl = new UntypedFormControl('boxed');
    projectSearchCtrl = new FormControl<string | Project>('');
    projectList: Project[] = [];
    projectUsersList: Project[] = [];
    filteredProyectList: Observable<Project[]>;
    dataSource: MatTableDataSource<Project> | null;

    dataSourceUsersProjects: MatTableDataSource<User> | null;
    projectSelected: boolean;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    @ViewChild('MatPaginatorUsersProjects', {static: true}) paginatorUsersProjects: MatPaginator;
    @ViewChild('MatSortUsersProjects', {static: true}) sortUsersProjects: MatSort;

    columns: TableColumn<Project>[] = [
        {label: 'GRIDS.ID', property: 'id', type: 'text', visible: false},
        {label: 'GRIDS.NOMBRE', property: 'name', type: 'text', visible: true, cssClasses: ['font-medium']},
        {label: 'GRIDS.CODIGO', property: 'code', type: 'text', visible: false},
        {label: 'GRIDS.GERENCIA', property: 'gerency', type: 'text', visible: false},
        {label: 'GRIDS.DISCIPLINA', property: 'discipline', type: 'text', visible: false},
        {
            label: 'GRIDS.FECHA_INICIO',
            property: 'startDate',
            type: 'text',
            visible: false,
            cssClasses: ['text-secondary', 'font-medium']
        },
        {
            label: 'GRIDS.FECHA_TERMINO',
            property: 'endDate',
            type: 'text',
            visible: false,
            cssClasses: ['text-secondary', 'font-medium']
        },
        {
            label: 'GRIDS.ESTADO',
            property: 'status',
            type: 'text',
            visible: false,
            cssClasses: ['text-secondary', 'font-medium']
        },
        {label: 'GRIDS.ACCIONES', property: 'actions', type: 'button', visible: false}
    ];

    columnsUsersProjects: TableColumn<Project>[] = [
        {label: 'GRIDS.ID', property: 'id', type: 'text', visible: false},
        {label: 'GRIDS.USER_NAME', property: 'name', type: 'text', visible: true, cssClasses: ['font-medium']},
    ];
    data = {
        projectSelected: ''
    }

    pageSize = 5;
    pageSizeOptions: number[] = [5, 10, 20, 50];
    labels = aioTableLabels;

    constructor(private projects: ProjectsService,
                private users: UsersService) {
        this.initializeData();
    }

    ngOnInit() {
        this.dataSource = new MatTableDataSource();
        this.dataSourceUsersProjects = new MatTableDataSource();
        this.projectSearchCtrl.valueChanges.pipe(
            untilDestroyed(this)
        ).subscribe(value => this.onFilterChange(value));
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.dataSourceUsersProjects.paginator = this.paginatorUsersProjects;
        this.dataSourceUsersProjects.sort = this.sortUsersProjects;
    }

    onFilterChange(value: any) {
        if (!this.dataSource) {
            return;
        }
        value = value.trim();
        value = value.toLowerCase();
        this.dataSource.filter = value;
    }

    async initializeData() {
        return await new Promise<void>((resolve, reject) => {
            const list = this.projects.list('', 0, 10000, '',false);
            list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
                // @ts-ignore
                this.projectList = data.data;
                this.dataSource.data = this.projectList;
                resolve();
            });
        });

    }

    toggleColumnVisibility(column, event) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        column.visible = !column.visible;
    }

    trackByProperty<T>(index: number, column: TableColumn<T>) {
        return column.property;
    }

    get visibleColumns() {
        return this.columns.filter(column => column.visible).map(column => column.property);
    }

    async updateCustomer(project: Project) {
        this.projectSelected = true;
        return await new Promise<void>((resolve, reject) => {
            const list = this.users.list('', 0, 10000, '', false);
            list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
                // @ts-ignore
                this.projectUsersList = data.data;
                //this.dataSourceUsersProjects.data = this.projectUsersList;
                resolve();
            });
        });
    }
}
