<div>
    <div mat-dialog-title>
        <h2 class="headline m-0 flex-auto">{{ "ACTIONS.HORAS_PENDIENTES" | translate }}</h2>
    </div>

    <mat-divider class="-mx-6 text-border"></mat-divider>

    <mat-dialog-content class="flex flex-col">

        <table @stagger [dataSource]="dataSource" class="w-full" mat-table>

            <!-- Text Columns -->
            <ng-container *ngFor="let column of columns; trackBy: trackByProperty">

                <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
                    <th *matHeaderCellDef class="uppercase" mat-header-cell> {{ column.label | translate }}</th>
                    <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] }}</td>
                </ng-container>

                <ng-container *ngIf="column.type === 'button'" [matColumnDef]="column.property">
                    <th mat-header-cell *matHeaderCellDef> {{ "GRIDS.ACCIONES" | translate }}</th>
                    <td mat-cell  *matCellDef="let row" >
                        <button mat-icon-button matTooltip="Seleccionar"
                        (click)="select(row)">
                            <mat-icon svgIcon="mat:done"></mat-icon>
                        </button>
                    </td>
                </ng-container>

            </ng-container>

            <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
            <tr
                    *matRowDef="let row; columns: visibleColumns;"
                    @fadeInUp
                    class="hover:bg-hover trans-ease-out cursor-pointer"
                    mat-row></tr>

        </table>

    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close type="button">{{ "LINKS.CANCELAR" | translate }}</button>
    </mat-dialog-actions>
</div>