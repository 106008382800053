import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import moment from "moment";
import {MatTableDataSource} from "@angular/material/table";
import {Group} from "@core/services/backend/common/interface/groups";
import {TableColumn} from "@vex/interfaces/table-column.interface";
import {Link} from "@core/services/backend/common/interface/links";
import {fadeInUp400ms} from "@vex/animations/fade-in-up.animation";
import {stagger40ms} from "@vex/animations/stagger.animation";

@Component({
    selector: 'vex-activity-assignment-approve',
    templateUrl: './activity-assignment-approve.component.html',
    styleUrls: ['./activity-assignment-approve.component.scss'],
    animations: [
        fadeInUp400ms,
        stagger40ms
    ]
})
export class ActivityAssignmentApproveComponent implements OnInit {

    userName = '';
    userId = '';
    fecha = '';
    origin;

    dataSource: MatTableDataSource<Group> | null;

    columns: TableColumn<Link>[] = [
        {label: 'GRIDS.PROYECTO', property: 'projectName', type: 'text', visible: true},
        {label: 'GRIDS.FECHA_ACTUALIZACION', property: 'date', type: 'text', visible: true},
        {label: 'GRIDS.CANTIDAD', property: 'count', type: 'text', visible: true},
        {label: 'GRIDS.HHEE', property: 'extras', type: 'text', visible: true},
        {label: 'GRIDS.ESTADO', property: 'status', type: 'button', visible: true},
    ];

    statusList = [ "PENDIENTE", "RECHAZADA", "ACEPTADA"]
    constructor(@Inject(MAT_DIALOG_DATA) public defaults: any) {
        console.log(defaults)
        this.userName = defaults.userName;
        this.fecha = moment(defaults.dateStart).format('DD-MM-YYYY') + ' - ' + moment(defaults.dateEnd).format('DD-MM-YYYY')
        this.origin = defaults;
    }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource();
        this.dataSource.data = [...this.origin.hours];
    }

    trackByProperty<T>(index: number, column: TableColumn<T>) {
        return column.property;
    }

    get visibleColumns() {
        return this.columns.filter(column => column.visible).map(column => column.property);
    }
}
