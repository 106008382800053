import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {VexModule} from '@vex/vex.module';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {CustomLayoutModule} from './custom-layout/custom-layout.module';
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {ActivityManagementModule} from "./pages/activity-management/activity-management.module";
import {CoreModule} from "@core/core.module";
import {LoginModule} from "@app/pages/auth/login/login.module";
import {AuthGuard} from "@core/guard/auth.guard";
import {HttpResponseInterceptor} from "@core/interceptors/http-response-interceptor";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions} from "@angular/material/form-field";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MAT_MOMENT_DATE_FORMATS,
    MomentDateAdapter
} from "@angular/material-moment-adapter";
import {MAT_DATE_RANGE_SELECTION_STRATEGY} from "@angular/material/datepicker";
import {RangeSelectionStrategyService} from "@vex/services/range-selection-strategy.service";
import {AdministrationModule} from "@app/pages/administration/administration.module";
import {RedirectGuard} from "@core/guard/RedirectGuard";
import {SocialModule} from "@app/pages/social/social.module";
import {AppRoutingModule} from "@app/app-routing.module";
import {AuthState} from "@core/guard/auth.state";

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NoopAnimationsModule,
        AppRoutingModule,

        HttpClientModule,
        ActivityManagementModule,
        AdministrationModule,
        SocialModule,
        LoginModule,

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
            isolate: false,
        }),

        // Core
        CoreModule,
        // Vex
        VexModule,
        CustomLayoutModule
    ],
    providers: [
        RedirectGuard,
        //AuthService,
        {
            provide: APP_INITIALIZER,
            // dummy factory
            useFactory: () => () => {},
            multi: true,
            // injected depdencies, this will be constructed immidiately
            deps: [AuthState],
        },
        {provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptor, multi: true},
        AuthGuard,

        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'standard'
            } as MatFormFieldDefaultOptions
        },
        {provide: MAT_DATE_LOCALE, useValue: 'en-US'},
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
        {
            provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
            useClass: RangeSelectionStrategyService,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
