<div [class.container]="isVerticalLayout$ | async" class="toolbar w-full px-gutter flex items-center">
  <button (click)="openSidenav()" [class.hidden]="!mobileQuery" mat-icon-button type="button">
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>

  <a *ngIf="isVerticalLayout$ | async"
     [routerLink]="['/']"
     class="ltr:mr-4 rtl:ml-4 block flex items-center">
    <img alt="Logo" class="w-8 select-none" src="assets/img/demo/logo.svg"/>
    <h1 [class.hidden]="!mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">DESSAU</h1>
  </a>


  <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
       [class.hidden]="mobileQuery"
       class="px-gutter flex-none flex items-center">
    <vex-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-navigation-item>
  </div>

  <span class="flex-1"></span>

  <div class="-mx-1 flex items-center">
<!--    <div class="px-1">
      <button (click)="openSearch()" mat-icon-button type="button">
        <mat-icon color="primary" svgIcon="mat:search"></mat-icon>
      </button>
    </div>
-->
    <div class="px-1">
      <vex-toolbar-notifications></vex-toolbar-notifications>
    </div>

<!--    <div class="px-1">-->
<!--      <button (click)="openQuickpanel()" mat-icon-button type="button">-->
<!--        <mat-icon color="primary" svgIcon="mat:bookmarks"></mat-icon>-->
<!--      </button>-->
<!--    </div>-->


    <div class="px-1">
      <button [matMenuTriggerFor]="languageMenu" mat-icon-button type="button">
        <mat-icon svgIcon="flag:us" *ngIf="iconLanguage === 'en-US'"></mat-icon>
        <mat-icon svgIcon="flag:cl" *ngIf="iconLanguage === 'es-CL'"></mat-icon>
        <mat-icon svgIcon="flag:br" *ngIf="iconLanguage === 'br'"></mat-icon>
        <mat-icon svgIcon="flag:fr" *ngIf="iconLanguage === 'fr'"></mat-icon>
        <mat-icon svgIcon="flag:de" *ngIf="iconLanguage === 'de'"></mat-icon>
      </button>
    </div>

<!--      <div *ngIf="userVisible$ | async" class="px-1">-->
    <div class="px-1">
      <vex-toolbar-user></vex-toolbar-user>
    </div>

    <mat-menu #languageMenu="matMenu" overlapTrigger="false" xPosition="before" yPosition="below">
      <button mat-menu-item (click)="setLanguageTo('en-US')">
        <mat-icon svgIcon="flag:us"></mat-icon>
        <span>{{ 'COUNTRIES.en-US' | translate }}</span>
      </button>

      <button mat-menu-item (click)="setLanguageTo('es-CL')">
        <mat-icon svgIcon="flag:cl"></mat-icon>
        <span>{{ 'COUNTRIES.es-CL' | translate }}</span>
      </button>

      <button mat-menu-item (click)="setLanguageTo('br')">
        <mat-icon svgIcon="flag:br"></mat-icon>
        <span>{{ 'COUNTRIES.pt-BR' | translate }}</span>
      </button>

      <button mat-menu-item (click)="setLanguageTo('fr')">
        <mat-icon svgIcon="flag:fr"></mat-icon>
        <span>{{ 'COUNTRIES.fr-FR' | translate }}</span>
      </button>

      <button mat-menu-item (click)="setLanguageTo('de')">
        <mat-icon svgIcon="flag:de"></mat-icon>
        <span>{{ 'COUNTRIES.de-DE' | translate }}</span>
      </button>
    </mat-menu>
  </div>
</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
                [class.hidden]="mobileQuery"></vex-navigation>
