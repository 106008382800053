import {ChangeDetectorRef, Component, Inject, LOCALE_ID, Renderer2} from '@angular/core';
import {Settings} from 'luxon';
import {DOCUMENT} from '@angular/common';
import {Platform} from '@angular/cdk/platform';
import {ActivatedRoute} from '@angular/router';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {MatIconRegistry, SafeResourceUrlWithIconOptions} from '@angular/material/icon';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {TranslateService} from "@ngx-translate/core";
import {LayoutService} from "@vex/services/layout.service";
import {NavigationService} from "@vex/services/navigation.service";
import {SplashScreenService} from "@vex/services/splash-screen.service";
import {VexConfigName} from "@vex/config/config-name.model";
import {ColorSchemeName} from "@vex/config/colorSchemeName";
import {ColorVariable, colorVariables} from "@vex/components/config-panel/color-variables";
import {ConfigService} from "@vex/config/config.service";
import {LinksService} from '@core/services/backend/common/service/links.service';
import {Subject, takeUntil} from "rxjs";
import {Link} from "@core/services/backend/common/interface/links";
import {AuthService} from "@core/guard/auth-service";
import {browser} from "protractor";

@Component({
    selector: 'vex-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    private selectedLanguage: string;
    private readonly destroying$ = new Subject<void>();


    linksList: Link[] = [];

    constructor(private configService: ConfigService,
                private renderer: Renderer2,
                private platform: Platform,
                @Inject(DOCUMENT) private document: Document,
                @Inject(LOCALE_ID) private localeId: string,
                private layoutService: LayoutService,
                private route: ActivatedRoute,
                private navigationService: NavigationService,
                private splashScreenService: SplashScreenService,
                private readonly matIconRegistry: MatIconRegistry,
                private readonly domSanitizer: DomSanitizer,
                public translateService: TranslateService,
                public linksService: LinksService,
                public authService: AuthService,
                public cdr: ChangeDetectorRef) {

        Settings.defaultLocale = this.localeId;

        if (this.platform.BLINK) {
            this.renderer.addClass(this.document.body, 'is-blink');
        }

        this.matIconRegistry.addSvgIconResolver(
            (
                name: string,
                namespace: string
            ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
                switch (namespace) {
                    case 'mat':
                        return this.domSanitizer.bypassSecurityTrustResourceUrl(
                            `assets/img/icons/material-design-icons/two-tone/${name}.svg`
                        );

                    case 'logo':
                        return this.domSanitizer.bypassSecurityTrustResourceUrl(
                            `assets/img/icons/logos/${name}.svg`
                        );

                    case 'flag':
                        return this.domSanitizer.bypassSecurityTrustResourceUrl(
                            `assets/img/icons/flags/${name}.svg`
                        );
                }
            }
        );

        /**
         * Customize the template to your needs with the ConfigService
         * Example:
         *  this.configService.updateConfig({
         *    sidenav: {
         *      title: 'Custom App',
         *      imageUrl: '//placehold.it/100x100',
         *      showCollapsePin: false
         *    },
         *    footer: {
         *      visible: false
         *    }
         *  });
         */

        /**
         * Config Related Subscriptions
         * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
         * Example: example.com/?layout=apollo&style=default
         */
        this.route.queryParamMap.subscribe(queryParamMap => {
            if (queryParamMap.has('layout')) {
                this.configService.setConfig(queryParamMap.get('layout') as VexConfigName);
            }

            if (queryParamMap.has('style')) {
                this.configService.updateConfig({
                    style: {
                        colorScheme: queryParamMap.get('style') as ColorSchemeName
                    }
                });
            }

            if (queryParamMap.has('primaryColor')) {
                const color: ColorVariable = colorVariables[queryParamMap.get('primaryColor')];

                if (color) {
                    this.configService.updateConfig({
                        style: {
                            colors: {
                                primary: color
                            }
                        }
                    });
                }
            }

            if (queryParamMap.has('rtl')) {
                this.configService.updateConfig({
                    direction: coerceBooleanProperty(queryParamMap.get('rtl')) ? 'rtl' : 'ltr'
                });
            }
        });


        // this.navigationService.items.push({
        //
        // })


    }


    ngOnInit(): void {
        this.translateService.setDefaultLang('es-CL');
        this.translateService.addLangs(['en-US', 'es-CL', 'fr-FR', 'pt-BR', 'de-DE']);

        this.selectedLanguage = localStorage.getItem("LOCALE_ID") || 'es-CL';

        if (!this.selectedLanguage) {
            this.setLanguage('es-CL');
        } else {
            this.applyLanguage();
        }

        this.initializeData().then(() => {

            const base = this.linksList.filter(link => link.link === 'BASE_EXTERNAL_LINKS')
            const childrens = this.linksList.filter(link => link.link !== 'BASE_EXTERNAL_LINKS')

            let show = false;
            let isShow = [];

            console.log(childrens)
            let childrenList = [];
            childrens.forEach(link => {

                show = false;
                isShow = [];

                // @ts-ignore
                if (link.ExternalLinksGroups.length > 0) {

                    // @ts-ignore
                    console.log(link.ExternalLinksGroups)
                    // @ts-ignore
                    isShow = link.ExternalLinksGroups.filter(group => {
                        if (this.authService.UsersGroups !== null) {
                            // @ts-ignore
                            isShow = this.authService.UsersGroups.filter(userGroup => {
                                return  userGroup.ID == group.Groups.ID;
                            })
                        }
                        return isShow.length > 0;
                    })

                    show = isShow.length > 0;
                    console.log(this.authService.UsersGroups);
                }


                if (show) {
                    childrenList.push({
                        type: 'link',
                        label: link.name,
                        icon: 'mat:link',
                        route: link.link,
                        isExternal: true,
                        hasRole: ["SUPER_ADMIN","ADMIN","COORDINATOR","USER"],
                        visible: true
                    },)
                }
            })


            console.log(this.authService.UserData)
            // @ts-ignore
            let notRegisterHours: boolean = !this.authService.UserData.notRegisterHours;


            this.navigationService.items.push(
                {
                    type: 'subheading',
                    label: 'MENUS.GESTION_DE_ACTIVIDADES',
                    hasRole: ["SUPER_ADMIN","ADMIN","COORDINATOR","USER"],
                    children: [
                        {
                            type: 'link',
                            label: 'MENUS.BD_HH',
                            route: '/activity-management/activity-assignment',
                            icon: 'fa-duotone:fa-list-check',
                            routerLinkActiveOptions: {exact: true},
                            hasRole: ["SUPER_ADMIN","ADMIN","COORDINATOR"],
                            visible: true,

                        }, {
                            type: 'link',
                            label: 'MENUS.HORAS_TAREAS',
                            route: '/activity-management/hours-worked',
                            icon: 'fa-duotone:fa-calendar-pen',
                            routerLinkActiveOptions: {exact: true},
                            hasRole: ["SUPER_ADMIN","ADMIN","COORDINATOR","USER"],
                            visible: notRegisterHours,
                        },
                        {
                            type: 'dropdown',
                            label: base[0].name,
                            hasRole: ["SUPER_ADMIN","ADMIN","COORDINATOR","USER"],
                            icon: 'mat:link',
                            children: childrenList
                        }
                    ]
                }, {
                    type: 'subheading',
                    label: 'MENUS.ADMINISTRACION',
                    hasRole: ["SUPER_ADMIN","ADMIN"],
                    children: [
                        {
                            type: 'link',
                            label: 'MENUS.PROYECTOS',
                            route: '/administration/projects',
                            icon: 'mat:receipt',
                            routerLinkActiveOptions: {exact: true},
                            hasRole: ["SUPER_ADMIN","ADMIN"],
                            visible: true,
                        }, {
                            type: 'link',
                            label: 'MENUS.USUARIOS',
                            route: '/administration/users',
                            icon: 'fa-duotone:fa-user',
                            routerLinkActiveOptions: {exact: true},
                            hasRole: ["SUPER_ADMIN","ADMIN"],
                            visible: true,
                        }, {
                            type: 'link',
                            label: 'MENUS.GROUPS',
                            route: '/administration/groups',
                            icon: 'mat:group',
                            routerLinkActiveOptions: {exact: true},
                            hasRole: ["SUPER_ADMIN"],
                            visible: true,
                        }, {
                            type: 'link',
                            label: 'MENUS.HOURS_INSERT',
                            route: '/administration/hours-auto',
                            icon: 'mat:hourglass_empty',
                            routerLinkActiveOptions: {exact: true},
                            hasRole: ["SUPER_ADMIN"],
                            visible: true,
                        }, {
                            type: 'link',
                            label: 'MENUS.EXTERNAL_LINKS',
                            route: '/administration/external-links',
                            icon: 'mat:link',
                            routerLinkActiveOptions: {exact: true},
                            hasRole: ["SUPER_ADMIN"],
                            visible: true,
                        }, {
                            type: 'dropdown',
                            label: 'MENUS.RESPONSABLES',
                            icon: 'fa-duotone:fa-check-double',
                            hasRole: ["SUPER_ADMIN"],
                            children: [{
                                type: 'link',
                                label: 'MENUS.USUARIOS',
                                route: '/administration/responsible/users',
                                icon: 'fa-duotone:fa-person-carry-box',
                                routerLinkActiveOptions: {exact: true},
                                hasRole: ["SUPER_ADMIN"],
                                visible: true,
                            }, {
                                type: 'link',
                                label: 'MENUS.PROYECTOS',
                                route: '/administration/responsible/projects',
                                icon: 'fa-duotone:fa-project-diagram',
                                routerLinkActiveOptions: {exact: true},
                                hasRole: ["SUPER_ADMIN"],
                                visible: true,
                            }]

                        }, {
                            type: 'dropdown',
                            label: 'MENUS.PROCESOS',
                            icon: 'mat:sync',
                            hasRole: ["SUPER_ADMIN"],
                            children: [{
                                type: 'link',
                                label: 'MENUS.CARGA_MASIVA_USUARIOS',
                                route: '/administration/process/bulk-users',
                                icon: 'fa-duotone:fa-users-cog',
                                routerLinkActiveOptions: {exact: true},
                                hasRole: ["SUPER_ADMIN"],
                                visible: true,
                            }, {
                                type: 'link',
                                label: 'MENUS.CARGA_MASIVA_PROYECTOS',
                                route: '/administration/process/bulk-projects',
                                icon: 'fa-duotone:fa-folder-plus',
                                routerLinkActiveOptions: {exact: true},
                                hasRole: ["SUPER_ADMIN"],
                                visible: true,
                            }]

                        },
                    ]
                });

            this.cdr.detectChanges();
            this.cdr.markForCheck();
        });
    }

    setLanguage(lang: string): void {
        localStorage.setItem("LOCALE_ID", lang);
        this.selectedLanguage = lang;
        this.applyLanguage();
    }

    applyLanguage() {
        this.translateService.use(this.selectedLanguage);
    }

    async initializeData() {
        const that = this;
        return await new Promise<void>((resolve, reject) => {
            const list = this.linksService.list('', 0, 10000, '');
            list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
                // @ts-ignore
                that.linksList = data.data;
                console.log(data)
                resolve();
            });
        });
    }

}
