import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UsersApi} from "@core/services/backend/common/api/users.api";
import {User, UsersData} from "@core/services/backend/common/interface/users";


@Injectable()
export class UsersService extends UsersData {
    bulk(user: { deletes: User[]; updates: User[]; inserts: User[]; }): Observable<User> {
        return this.api.bulk(user);
    }

    constructor(private api: UsersApi) {
        super();
    }

    list(search: string, pageNumber: number, pageSize: number, orderBy: string, scope: boolean): Observable<any[]> {
        return this.api.list(search, pageNumber, pageSize, orderBy, scope);
    }

    get(id: number): Observable<User> {
        return this.api.get(id);
    }

    // tslint:disable-next-line:no-shadowed-variable
    create(User: any): Observable<User> {
        return this.api.add(User);
    }

    // tslint:disable-next-line:no-shadowed-variable
    update(User: any): Observable<User> {
        return this.api.update(User);
    }

    delete(User: any): Observable<User> {
        return this.api.delete(User);
    }

    reset(User: any): Observable<User> {
        return this.api.reset(User);
    }

}
