import {Component, Inject, OnInit} from '@angular/core';
import {FormGroup, UntypedFormBuilder} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {User} from "@core/services/backend/common/interface/users";
import {ProjectsService} from "@core/services/backend/common/service/projects.service";
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
    selector: 'vex-project-create-update',
    templateUrl: './project-create-update.component.html',
    styleUrls: ['./project-create-update.component.scss']
})
export class ProjectCreateUpdateComponent implements OnInit {

    static id = 100;

    form: FormGroup;
    mode: 'create' | 'update' = 'create';

    comentarioHabilitado: boolean = false;
    activo: boolean = false;

    hh_normales: boolean = false;
    hh_extras: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
                private dialogRef: MatDialogRef<ProjectCreateUpdateComponent>,
                private fb: UntypedFormBuilder,
                private translateService: TranslateService,
                private projects: ProjectsService,
                private snackbar: MatSnackBar) {
    }

    ngOnInit() {
        if (this.defaults) {
            this.mode = 'update';
        } else {
            this.defaults = {} as User;
        }

        this.form = this.fb.group({
            id: [ProjectCreateUpdateComponent.id++],
            name: [this.defaults.name || ''],
            code: [this.defaults.code || '']
        });
        this.comentarioHabilitado = this.defaults.force_comments;
        this.activo = this.defaults.active;
        this.hh_extras = this.defaults.extra_hours;
        this.hh_normales = this.defaults.normal_hours;

    }

    save() {
        console.log(this.mode)
        if (this.mode === 'create') {
            this.create();
        } else if (this.mode === 'update') {
            this.update();
        }
    }

    create() {
        const that = this;
        if (this.form.valid) {
            const project = {
                name: this.form.value.name,
                code: this.form.value.code,
                force_comments: this.comentarioHabilitado,
                active: this.activo,
                normal_hours: this.hh_normales,
                extra_hours: this.hh_extras

            }
            this.projects.create(project).subscribe({
                next(result) {
                    that.handleSuccessResponse();
                    that.dialogRef.close(project);
                },
                error(err) {
                    that.handleWrongResponse(err);
                }
            });
        }

    }

    update() {
        const that = this;
        if (this.form.valid) {
            const project = {
                id: this.defaults.ID,
                name: this.form.value.name,
                code: this.form.value.code,
                force_comments: this.comentarioHabilitado,
                active: this.activo,
                normal_hours: this.hh_normales,
                extra_hours: this.hh_extras
            }
            console.log('project insert', project);
            this.projects.create(project).subscribe({
                next(result) {
                    that.handleSuccessResponse();
                    that.dialogRef.close(project);
                },
                error(err) {
                    that.handleWrongResponse(err);
                }
            });
        }
    }

    isCreateMode() {
        return this.mode === 'create';
    }

    isUpdateMode() {
        return this.mode === 'update';
    }

    handleSuccessResponse() {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            const message = this.isCreateMode() ? res.PROYECTO.SUCCESS : res.PROYECTO.UPDATE
            this.snackbar.open(message, '', {
                duration: 3000
            });
        });
    }

    handleWrongResponse(err) {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            const message = this.isCreateMode() ? res.PROYECTO.ERROR : res.PROYECTO.UPDATE_ERROR
            this.snackbar.open(message, '', {
                duration: 3000
            });
        });
    }

    forzarComentario(event: MatSlideToggleChange) {
        this.comentarioHabilitado = event.checked;
    }

    isActivo(event: MatSlideToggleChange) {
        this.activo = event.checked;
    }

    hh_normal_change(event: MatSlideToggleChange) {
        this.hh_normales = event.checked;
    }

    hh_extras_change(event: MatSlideToggleChange) {
        this.hh_extras = event.checked;
    }

}
