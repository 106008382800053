import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {fadeInUp400ms} from "@vex/animations/fade-in-up.animation";
import {stagger40ms} from "@vex/animations/stagger.animation";
import {FormControl, FormGroup, UntypedFormControl} from "@angular/forms";
import {Subject, takeUntil} from "rxjs";
import {Project} from "@core/services/backend/common/interface/projects";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {TableColumn} from "@vex/interfaces/table-column.interface";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {LinksService} from "@core/services/backend/common/service/links.service";
import {
    ExternalLinksCreateUpdateComponent
} from "@app/pages/administration/external-links-create-update/external-links-create-update.component";
import {Link} from "@core/services/backend/common/interface/links";

@Component({
    selector: 'vex-external-links',
    templateUrl: './external-links.component.html',
    styleUrls: ['./external-links.component.scss'],
    animations: [
        fadeInUp400ms,
        stagger40ms,
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class ExternalLinksComponent implements OnInit {

    layoutCtrl = new UntypedFormControl('boxed');
    private readonly destroying$ = new Subject<void>();

    mode: 'update' | 'delete' = 'update';
    linksList: Link[] = [];
    linksListTitle: Link[] = [];
    dataSource: MatTableDataSource<Link> | null;
    searchForm: FormGroup;
    titleForm: FormGroup;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    columns: TableColumn<Link>[] = [
        {label: 'GRIDS.ID', property: 'id', type: 'text', visible: false},
        {label: 'GRIDS.WEBSITE', property: 'name', type: 'text', visible: true, cssClasses: ['font-medium']},
        {label: 'GRIDS.GROUPS', property: 'groups', type: 'image', visible: true},
        {label: 'GRIDS.LINK', property: 'link', type: 'text', visible: true},
        {label: 'GRIDS.ACCIONES', property: 'actions', type: 'button', visible: true}
    ];

    currentPage = 0;
    pageSize = 5;
    pageSizeOptions: number[] = [5, 10, 20, 50];
    isLoading: boolean;

    constructor(
        private links: LinksService,
        private dialog: MatDialog,
        private translateService: TranslateService,
        private snackbar: MatSnackBar
    ) {
    }

    ngOnInit(): void {

        this.dataSource = new MatTableDataSource();

        this.searchForm = new FormGroup({
            name: new FormControl()
        })

        this.titleForm = new FormGroup({
            name: new FormControl()
        })
        this.loadData()

    }

    loadData() {
        const that = this
        this.initializeData().then(() => {
            this.dataSource.data = that.linksList;
            if (this.linksListTitle.length > 0) {
                this.titleForm.controls["name"].setValue(this.linksListTitle[0].name)
            }
        })
    }

    private getSearch() {
        return {
            name: this.searchForm.get("name").value ? '%' + this.searchForm.get("name").value.toString().toUpperCase() + '%' : '',
            link: this.searchForm.get("name").value ? '%' + this.searchForm.get("name").value.toString().toUpperCase() + '%' : ''
        }
    }

    async initializeData() {
        const that = this;
        return await new Promise<void>((resolve, reject) => {
            const list = this.links.list(JSON.stringify(that.getSearch()), that.currentPage, that.pageSize, '');
            list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
                // @ts-ignore
                that.linksListTitle = data.data.filter((item) => item.link === 'BASE_EXTERNAL_LINKS');
                // @ts-ignore
                that.linksList = data.data.filter((item) => item.link !== 'BASE_EXTERNAL_LINKS');

                that.paginator.pageIndex = that.currentPage;
                // @ts-ignore
                that.paginator.length = data.total_records;
                resolve();
            });
        });
    }

    pageChanged(event: PageEvent) {
        console.log({event});
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        this.loadData();
    }

    trackByProperty<T>(index: number, column: TableColumn<T>) {
        return column.property;
    }

    get visibleColumns() {
        return this.columns.filter(column => column.visible).map(column => column.property);
    }

    buscar() {
        this.loadData();
    }

    create() {
        this.dialog.open(ExternalLinksCreateUpdateComponent, {
            maxWidth: '100vw',
            maxHeight: '100vh',
            //height: '50%',
            width: '60%',
            panelClass: 'full-screen-modal'
        }).afterClosed().subscribe((project: Project) => {
            this.loadData();
        });
    }

    updateProject(project: Project) {
        this.dialog.open(ExternalLinksCreateUpdateComponent, {
            data: project,
            maxWidth: '100vw',
            maxHeight: '100vh',
            //height: '50%',
            width: '60%',
            panelClass: 'full-screen-modal'
        }).afterClosed().subscribe(updatedCustomer => {
            this.loadData();
        });
    }

    deleteProject(project: Project) {
        const that = this;
        this.links.delete(project).subscribe({
            next(result) {
                this.mode = 'delete';
                that.handleSuccessResponse();
                that.loadData();
            },
            error(err) {
                that.handleWrongResponse(err);
            }
        });
    }

    isDeleteMode() {
        return this.mode === 'delete';
    }

    isUpdateMode() {
        return this.mode === 'update';
    }

    handleSuccessResponse() {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            const message = this.isUpdateMode() ? res.LINKS.UPDATE : res.LINKS.DELETE
            this.snackbar.open(message, '', {
                duration: 3000
            });
        });
    }

    handleWrongResponse(err) {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            const message = this.isUpdateMode() ? res.LINKS.UPDATE_ERROR : res.LINKS.DELETE_ERROR
            this.snackbar.open(message, '', {
                duration: 3000
            });
        });
    }

    createParentMenu() {
        let parent = 0;
        this.mode = 'update';
        const that = this;
        if (this.linksList.length > 0) {
            // @ts-ignore
            parent = this.linksList[0].external_links_id;
        }
        const links = {
            id: parent,
            name: this.titleForm.value.name,
            link: 'BASE_EXTERNAL_LINKS',
            active: true
        }
        this.links.create(links).subscribe({
            next(result) {
                that.handleSuccessResponse();
            },
            error(err) {
                that.handleWrongResponse(err);
            }
        });
    }
}
