import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProjectsComponent} from "@app/pages/administration/projects/projects.component";
import {AdministrationRoutingModule} from "@app/pages/administration/administration-routing.module";
import {BreadcrumbsModule} from "@vex/components/breadcrumbs/breadcrumbs.module";
import {PageLayoutModule} from "@vex/components/page-layout/page-layout.module";
import {TranslateModule} from "@ngx-translate/core";
import {UsersComponent} from './users/users.component';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatButtonModule} from "@angular/material/button";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatOptionModule} from "@angular/material/core";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSortModule} from "@angular/material/sort";
import {MatTableModule} from "@angular/material/table";
import {MatTooltipModule} from "@angular/material/tooltip";
import {ReactiveFormsModule} from "@angular/forms";
import {VexModule} from "@vex/vex.module";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatMenuModule} from "@angular/material/menu";
import {UserCreateUpdateComponent} from './user-create-update/user-create-update.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatDividerModule} from "@angular/material/divider";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {ProjectCreateUpdateComponent} from './project-create-update/project-create-update.component';
import {ExternalLinksComponent} from './external-links/external-links.component';
import {
    ExternalLinksCreateUpdateComponent
} from './external-links-create-update/external-links-create-update.component';
import {BulkUsersComponent} from './process/bulk-users/bulk-users.component';
import {BulkProjectsComponent} from './process/bulk-projects/bulk-projects.component';
import {GroupsComponent} from './groups/groups.component';
import {GroupsCreateUpdateComponent} from './groups-create-update/groups-create-update.component';
import {MatChipsModule} from "@angular/material/chips";
import {MatSelectModule} from "@angular/material/select";
import {MatTabsModule} from "@angular/material/tabs";
import {MatBadgeModule} from "@angular/material/badge";

import { MatCardModule } from '@angular/material/card';
import { MatSlideToggle, MatSlideToggleModule } from '@angular/material/slide-toggle';

import { ResponsibleUsersComponent } from './responsible/users/responsible.users.component';
import { ResponsibleProjectsComponent } from './responsible/proyects/responsible-projects.component';
import { HoursAutoComponent } from './hours-auto/hours-auto.component';
import { HoursAutoCreateUpdateComponent } from './hours-auto-create-update/hours-auto-create-update.component';


@NgModule({
    declarations: [ProjectsComponent,
        UsersComponent,
        UserCreateUpdateComponent,
        ProjectCreateUpdateComponent,
        ExternalLinksComponent,
        ExternalLinksCreateUpdateComponent,
        BulkUsersComponent,
        BulkProjectsComponent,
        GroupsComponent,
        GroupsCreateUpdateComponent,
        ResponsibleUsersComponent,
        ResponsibleProjectsComponent,
        HoursAutoComponent,
        HoursAutoCreateUpdateComponent,

    ],
    imports: [
        CommonModule,
        AdministrationRoutingModule,
        BreadcrumbsModule,
        PageLayoutModule,
        TranslateModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatOptionModule,
        MatProgressSpinnerModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        ReactiveFormsModule,
        VexModule,
        MatPaginatorModule,
        MatMenuModule,
        MatDialogModule,
        MatDividerModule,
        MatCheckboxModule,
        MatChipsModule,
        MatSelectModule,
        MatTabsModule,
        MatBadgeModule,
        MatCardModule, 
        MatSlideToggleModule
    ]
})
export class AdministrationModule {
}
