import * as XLSX from "xlsx";

const getFileName = (name: string) => {
    let timeSpan = new Date().toISOString();
    let sheetName = name || "ExportResult";
    let fileName = `${sheetName}-${timeSpan}`;
    return {
        sheetName,
        fileName
    };
};
export class TableUtil {
    static exportTableToExcel(tableId: string, name?: string) {
        let { sheetName, fileName } = getFileName(name);
        let targetTableElm = document.getElementById(tableId);
        let wb = XLSX.utils.table_to_book(targetTableElm, <XLSX.Table2SheetOpts>{
            sheet: sheetName
        });
        XLSX.writeFile(wb, `${fileName}.xlsx`);
    }

    static exportArrayToExcel(arr: any[], name?: string) {
        let { sheetName, fileName } = getFileName(name);



        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(arr);


        //this.formatColumn(ws, 7, "0.0")
        //this.formatColumn(ws, 8, "0.0")

        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        XLSX.writeFile(wb, `${fileName}.xlsx`);
    }

    static formatColumn(worksheet, col, fmt) {
        const range = XLSX.utils.decode_range(worksheet['!ref'])
        // note: range.s.r + 1 skips the header row
        for (let row = range.s.r + 1; row <= range.e.r; ++row) {
            const ref = XLSX.utils.encode_cell({ r: row, c: col })
            if (worksheet[ref] && worksheet[ref].t === 'n') {
                worksheet[ref].z = fmt
            }
        }
    }
}
