import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "@environments/environment";
import {map, switchMap} from "rxjs/operators";
import {BehaviorSubject, Observable} from "rxjs";
import {User} from "@core/services/backend/common/interface/users";
import {JWTTokenService} from "@core/guard/jwttoken.service";
import {IAuthInfo, NewAuthInfo, PrepLogout, PrepSetSession} from "@core/guard/auth.model";
import {AuthState} from "@core/guard/auth.state";
import {LocalStorageService} from "@core/guard/local-storage.service";


interface LoginResponse {
    accessToken: string;
    refreshToken: string;
    user: User;
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    user$ = new BehaviorSubject(null);

    private _loginUrl = '/api/login';
    private _refreshUrl = '/api/refresh';
    private _localSetSession = '/api/session';
    private _localLogout = '/api/logout';
    API_URL = environment.apiUrl;
    TOKEN_KEY = 'token';

    get token() {
        return localStorage.getItem(this.TOKEN_KEY);
    }

    get isAuthenticated() {
        return !!localStorage.getItem(this.TOKEN_KEY);
    }

    get User() {
        this.jwtService.setToken(this.token)
        return this.jwtService.getUser();
    }

    get UserId() {
        this.jwtService.setToken(this.token)
        return this.jwtService.getUserId();
    }

    get Role() {
        this.jwtService.setToken(this.token)
        console.log("Role:")
        return this.jwtService.getRole();
    }

    get UsersGroups() {
        this.jwtService.setToken(this.token)
        return this.jwtService.getUserGroups();
    }

    get UserData() {
        this.jwtService.setToken(this.token)
        return this.jwtService.getUserData();
    }

    constructor(private http: HttpClient,
                private authState: AuthState,
                private localStorageService: LocalStorageService,
                private jwtService: JWTTokenService) {
    }

    login(email: string, password: string): Observable<any> {

        const url = `${environment.apiUrl}` + this._loginUrl
        const headers = {
            headers: new HttpHeaders({'Content-Type': 'application/json'})
        };
        const params = {
            email: email + '%',
            password: password
        };
        console.info(`login( Logging into API "${url}" with creds: ${params.email} / ${params.password} )`);

        return this.http.post(url, params, headers).pipe(
            map((response) => {
                // prepare the response to be handled, then return
                const retUser: IAuthInfo = NewAuthInfo((<any>response));
                this.jwtService.setToken((<any>response).token)
                this.localStorageService.setItem("token",(<any>response).token)

                // save session and return user if needed
                return this.authState.SaveSession(retUser);
                //return retUser;
            }),
            // if we are setting cookie on server, this is the place to call local server
            switchMap((user) => this.SetLocalSession(user))
        );
    }

    RefreshToken(): Observable<IAuthInfo> {
        return this.http
            .post(`${environment.apiUrl}` + this._refreshUrl, {token: this.authState.GetRefreshToken()})
            .pipe(
                map((response) => {
                    // this response has the new refresh token and access token
                    if (!response) {
                        // something terrible happened
                        throw new Error('Oh oh');
                    }

                    // update session
                    const retUser: IAuthInfo = NewAuthInfo((<any>response).data);
                    this.authState.UpdateSession(retUser);

                    return retUser;
                }),
                // if we use set session on local server, then this is the place
                // to call it
                switchMap((response) => this.SetLocalSession(response))
            );
    }

    SetLocalSession(user: IAuthInfo): Observable<IAuthInfo> {
        // prepare the information to use in the cookie
        // basically the auth info and the cookie name
        const data = PrepSetSession(user);
        // notice the relative url, this is the path you need to setup in your server
        // look up an example in server.js
        return this.http.post(`${environment.apiUrl}` + this._localSetSession, data).pipe(
            map((response) => {
                return user;
            })
        );

    }

    Logout(): Observable<boolean> {
        // logout locally
        const data = PrepLogout();

        return this.http.post(`${environment.apiUrl}` + this._localLogout, data).pipe(
            map((response) => {
                return true;
            })
        );
    }

}
