import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Project, ProjectsData} from "@core/services/backend/common/interface/projects";
import {ProjectsApi} from "@core/services/backend/common/api/projects.api";


@Injectable()
export class ProjectsService extends ProjectsData {

    constructor(private api: ProjectsApi) {
        super();
    }

    list(search: string, pageNumber: number, pageSize: number, orderBy: string, scope: boolean): Observable<any[]> {
        return this.api.list(search, pageNumber, pageSize, orderBy, scope);
    }

    get(id: number, cloudId: number): Observable<Project> {
        return this.api.get(id, cloudId);
    }

    // tslint:disable-next-line:no-shadowed-variable
    create(Project: any): Observable<Project> {
        return this.api.add(Project);
    }

    // tslint:disable-next-line:no-shadowed-variable
    update(Project: any): Observable<Project> {
        return this.api.update(Project);
    }

    delete(Project: any): Observable<Project> {
        return this.api.delete(Project);
    }

    bulk(project: { deletes: Project[]; updates: Project[]; inserts: Project[] }): Observable<Project> {
        return this.api.bulk(project);
    }

}
