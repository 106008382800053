import { IUser } from './user.model';
import jwt_decode from "jwt-decode";

// auth mode is what comes back after login
export interface IAuthInfo {
    payload?: IUser;
    accessToken?: string;
    refreshToken?: string;
    expiresAt?: number; // number of milliseconds of its life time
}

// example returnd from server
// {
// 	accessToken: 'access_token',
// 	refreshToken: "refres_token",
// 	payload: {
// 		name: 'maybe name',
// 		id: 'id',
// 		email: 'username'
// 	},
// 	// expires in is an absolute lifetime in seconds
// 	expiresIn: 3600
// }

export const NewAuthInfo = (data: any): IAuthInfo => {
    const tdata = jwt_decode(data.token)
    // @ts-ignore
    return { role: tdata.Role, firstName: tdata.firstName, lastName: tdata.lastName, id: tdata.id , accessToken: data.token, refreshToken: data.token, expiresAt: Date.now() + tdata.Exp * 1000 };
};

export const PrepSetSession = (auth: IAuthInfo): any => {
    // @ts-ignore
    return { role: auth.role, id: auth.id, firstName: auth.firstName, lastName: auth.lastName, expiresAt: auth.expiresAt, cookieName: 'CrCookie', accessToken: auth.accessToken, refreshToken: auth.refreshToken };
};

export const PrepLogout = (): any => {
    return {
        cookieName: 'CrCookie',
    };
};
