import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {HttpService} from './http.service';

@Injectable()
export class WorkedHoursApi {

    private readonly apiController: string = '/api/workedHours';

    constructor(private api: HttpService) {
    }

    list(search: string, pageNumber: number, pageSize: number, orderBy: string): Observable<any[]> {
        const params = new HttpParams()
            .set('page', `${pageNumber}`)
            .set('pageSize', `${pageSize}`)
            .set('search', `${search}`)
            .set('order', `${orderBy}`);

        return this.api.get(this.apiController, {params}).pipe(map((data) => data));
    }

    get(item: any): Observable<any> {
        return this.api.post(`${this.apiController}/${item.id}`, item)
            .pipe(map(data => {
                return {...data};
            }));
    }


    add(item: any): Observable<any> {
        // return this.api.put(`${this.apiController}/${item.id}`, item);
        return this.api.post(`${this.apiController}`, item);
    }

    update(item: any): Observable<any> {
        return this.api.put(`${this.apiController}/${item.id}`, item);
    }

    details(search: string, pageNumber: number, pageSize: number, orderBy: string): Observable<any[]> {
        const params = new HttpParams()
            .set('page', `${pageNumber}`)
            .set('pageSize', `${pageSize}`)
            .set('search', `${search}`)
            .set('order', `${orderBy}`);

        return this.api.get(this.apiController + '/details', {params}).pipe(map((data) => data));

    }

    pendingStatus(search: string, pageNumber: number, pageSize: number, orderBy: string): Observable<any[]> {
        const params = new HttpParams()
            .set('page', `${pageNumber}`)
            .set('pageSize', `${pageSize}`)
            .set('search', `${search}`)
            .set('order', `${orderBy}`);

        return this.api.get(this.apiController + '/status/pending', {params}).pipe(map((data) => data));

    }
}
