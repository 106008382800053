<form (ngSubmit)="save()" [formGroup]="form">
    <div class="flex items-center" mat-dialog-title>

        <h2 *ngIf="form.get('name').value"
            class="headline m-0 flex-auto">{{ form.get('name').value }}</h2>
        <h2 *ngIf="!form.get('name').value"
            class="headline m-0 flex-auto">{{ "PROYECTO.NUEVO" | translate }}</h2>

    </div>

    <mat-divider class="-mx-6 text-border"></mat-divider>

    <mat-dialog-content class="flex flex-col">
        <div class="flex flex-col sm:flex-row">
            <mat-form-field class="mt-6 flex-auto">
                <mat-label>{{ "PROYECTO.NOMBRE" | translate }}</mat-label>
                <input cdkFocusInitial formControlName="name" matInput>

                <mat-icon class="mr-3" matPrefix svgIcon="mat:person"></mat-icon>
            </mat-form-field>

            <mat-form-field class="sm:mt-6 sm:ml-6 flex-auto">
                <mat-label>{{ "PROYECTO.CODIGO" | translate }}</mat-label>
                <input formControlName="code" matInput>

                <mat-icon class="mr-3" matPrefix svgIcon="mat:code"></mat-icon>
            </mat-form-field>
        </div>
        <div class="flex flex-col sm:flex-row">
            <div class="flex-auto">
                 <mat-slide-toggle color="primary" (change)="forzarComentario($event)" [checked]="comentarioHabilitado">{{ "PROYECTO.FORZAR_COMENTARIO" | translate }}</mat-slide-toggle>
            </div>
            <div class="sm:ml-6 flex-auto">
                <mat-slide-toggle color="primary" (change)="isActivo($event)" [checked]="activo">{{ "PROYECTO.ACTIVO" | translate }}</mat-slide-toggle>
            </div>
        </div>
        <div class="flex flex-col sm:flex-row">
            <div class="flex-auto">
                <mat-slide-toggle color="primary" (change)="hh_normal_change($event)" [checked]="hh_normales">{{ "PROYECTO.HORAS_NORMALES" | translate }}</mat-slide-toggle>
            </div>
            <div class="sm:ml-6 flex-auto">
                <mat-slide-toggle color="primary" (change)="hh_extras_change($event)" [checked]="hh_extras">{{ "PROYECTO.HORAS_EXTRAS" | translate }}</mat-slide-toggle>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close type="button">{{ "PROYECTO.CANCELAR" | translate }}</button>
        <button *ngIf="isCreateMode()" color="primary" mat-flat-button type="submit">{{ "PROYECTO.CREAR" | translate }}</button>
        <button *ngIf="isUpdateMode()" color="primary" mat-flat-button type="submit">{{ "PROYECTO.ACTUALIZAR" | translate }}</button>
    </mat-dialog-actions>
</form>

