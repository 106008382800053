import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from './layout/layout.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MaxRangeDirective } from './directives/max-range.directive';
import { MatTableResponsiveDirective } from './directives/mat-table-responsive.directive';
import { DialogComponent } from './components/dialog/dialog.component';
import {MatIconModule} from "@angular/material/icon";
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {TranslateModule} from "@ngx-translate/core";
import { MatErrorMessagesDirective } from './directives/mat-error-messages.directive';


@NgModule({
    imports: [
        CommonModule,
        LayoutModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
        TranslateModule
    ],
    exports: [
        LayoutModule,
        MaxRangeDirective,
        MatTableResponsiveDirective,
        MatErrorMessagesDirective
    ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    }
  ],
  declarations: [
    MaxRangeDirective,
    MatTableResponsiveDirective,
    DialogComponent,
    MatErrorMessagesDirective
  ]
})
export class VexModule {
}
