<form (ngSubmit)="save()" [formGroup]="form">
    <div class="flex items-center" mat-dialog-title>

        <h2 *ngIf="form.get('firstName').value || form.get('lastName').value"
            class="headline m-0 flex-auto">{{ form.get('firstName').value + ' ' + form.get('lastName').value }}</h2>
        <h2 *ngIf="!form.get('firstName').value && !form.get('lastName').value"
            class="headline m-0 flex-auto">{{ "USUARIO.NUEVO" | translate }}</h2>

    </div>

    <mat-divider class="-mx-6 text-border"></mat-divider>

    <mat-dialog-content class="flex flex-col">
        <div class="flex flex-col sm:flex-row">
            <mat-form-field class="mt-6 flex-auto">
                <mat-label>{{ "USUARIO.NOMBRES" | translate }}</mat-label>
                <input cdkFocusInitial formControlName="firstName" matInput>

                <mat-icon class="mr-3" matPrefix svgIcon="mat:person"></mat-icon>
            </mat-form-field>

            <mat-form-field class="sm:mt-6 sm:ml-6 flex-auto">
                <mat-label>{{ "USUARIO.APELLIDOS" | translate }}</mat-label>
                <input formControlName="lastName" matInput>

                <mat-icon class="mr-3" matPrefix svgIcon="mat:person"></mat-icon>
            </mat-form-field>
        </div>

        <div class="flex flex-col sm:flex-row">
            <mat-form-field class="flex-auto">
                <mat-label>{{ "USUARIO.RUT" | translate }}</mat-label>
                <input formControlName="rut" matInput (input)="inputEvent($event)"/>
                <mat-error *ngIf="form.get('rut').hasError('rutnovalido')">
                    {{ "USUARIO.RUT_NO_VALIDO" | translate }}
                </mat-error>
            </mat-form-field>


            <mat-form-field class="sm:ml-6 flex-auto">
                <mat-label>{{ "USUARIO.EMAIL" | translate }}</mat-label>
                <input formControlName="email" matInput type="email">

                <mat-icon class="mr-3" matPrefix svgIcon="mat:email"></mat-icon>
            </mat-form-field>
        </div>
        <div class="flex flex-col sm:flex-row">
            <mat-form-field class="flex-auto">
                <mat-label>{{ "USUARIO.SUB_AREA" | translate }}</mat-label>
                <mat-select formControlName="subArea">
                    <mat-option *ngFor="let subarea of subAreasList" [value]="subarea.ID">
                        {{subarea.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="sm:ml-6 flex-auto">
                <mat-label>{{ "USUARIO.ROLES" | translate }}</mat-label>
                <mat-select formControlName="roles">
                    <mat-option *ngFor="let role of rolesList" [value]="role.ID">
                        {{ "ROLES." + role.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="flex flex-col sm:flex-row">
            <mat-form-field class="flex-auto">
                <mat-label>{{ "USUARIO.CONTRASENA" | translate }}</mat-label>
                <input [type]="inputType" formControlName="password" matInput/>
                <button (click)="toggleVisibility()" mat-icon-button matSuffix
                        matTooltip="Toggle Visibility" type="button">
                    <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
                    <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
                </button>
                <mat-error *ngIf="form.get('password').hasError('minlength')">
                    {{ "USUARIO.PASSWORD_MIN_LEN" | translate }}
                </mat-error>
                <mat-error *ngIf="form.get('password').hasError('maxlength')">
                    {{ "USUARIO.PASSWORD_MIN_LEN" | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="sm:ml-6 flex-auto">
                <mat-label>{{ "USUARIO.VERIFICAR_CONTRASENA" | translate }}</mat-label>
                <input [type]="inputType" formControlName="confirm" matInput/>
                <button (click)="toggleVisibility()" mat-icon-button matSuffix
                        matTooltip="Toggle Visibility" type="button">
                    <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
                    <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
                </button>
                <mat-error *ngIf="form.get('confirm').hasError('minlength')">
                    {{ "USUARIO.PASSWORD_MIN_LEN" | translate }}
                </mat-error>
                <mat-error *ngIf="form.get('confirm').hasError('maxlength')">
                    {{ "USUARIO.PASSWORD_MIN_LEN" | translate }}
                </mat-error>
                <mat-error *ngIf="form.get('confirm').hasError('not_matching')">
                    {{ "USUARIO.PASSWORD_NOT_MATCH" | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="flex flex-col sm:flex-row">
            <div class="sm:ml-6 flex-auto">
                <mat-slide-toggle color="primary" (change)="registraHoras($event)" [checked]="noRegistraHoras">{{ "USUARIO.NO_REGISTRA_HORAS" | translate }}</mat-slide-toggle>
            </div>
            <div class="sm:ml-6 flex-auto">
                <mat-slide-toggle color="primary" (change)="requiereValidacionHoras($event)" [checked]="noRequiereValidacionHoras">{{ "USUARIO.NO_REQUIERE_VALIDACION" | translate }}</mat-slide-toggle>
            </div>
        </div>        
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close type="button">{{ "USUARIO.CANCELAR" | translate }}</button>
        <button *ngIf="isCreateMode()" color="primary" mat-flat-button
                type="submit">{{ "USUARIO.CREAR" | translate }}</button>
        <button *ngIf="isUpdateMode()" color="primary" mat-flat-button
                type="submit">{{ "USUARIO.ACTUALIZAR" | translate }}</button>
    </mat-dialog-actions>
</form>

