import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {fadeInUp400ms} from "@vex/animations/fade-in-up.animation";
import {AuthService} from "@core/guard/auth-service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'vex-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        fadeInUp400ms
    ]
})
export class LoginComponent implements OnInit {

    form: UntypedFormGroup;

    inputType = 'password';
    visible = false;

    iconLanguage: any;
    constructor(private router: Router,
                private fb: UntypedFormBuilder,
                private cd: ChangeDetectorRef,
                private snackbar: MatSnackBar,
                private authService: AuthService,

                private translateService: TranslateService
    ) {

    }

    ngOnInit() {
        this.setLanguageTo(localStorage.getItem("LOCALE_ID") || 'es-CL');

        this.form = this.fb.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    send() {
        this.authService.login(this.form.get("email").value, this.form.get("password").value).subscribe({
            next: data => {
                this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
                    this.snackbar.open(res.LOGIN.SUCCESS, '', {
                        duration: 3000,
                        panelClass: ['green-snackbar']
                    });
                });
               location.href = "/";
            },
            error: err => {
                if (err.status === 401) {
                    this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
                        this.snackbar.open(res.LOGIN.ERROR, '', {
                            duration: 3000,
                            panelClass: ['red-snackbar']
                        });
                    });
                } else {
                    this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
                        this.snackbar.open(res.LOGIN.ERROR_FATAL, '', {
                            duration: 3000,
                            panelClass: ['red-snackbar']
                        });
                    });
                }
                console.log(err);

            }
        });
    }

    toggleVisibility() {
        if (this.visible) {
            this.inputType = 'password';
            this.visible = false;
            this.cd.markForCheck();
        } else {
            this.inputType = 'text';
            this.visible = true;
            this.cd.markForCheck();
        }
    }

    setLanguageTo(language: string): void {
        localStorage.setItem("LOCALE_ID", language);
        this.translateService.use(language)
        this.iconLanguage = language;
        this.cd.markForCheck();
    }
}
