<form (ngSubmit)="save()" [formGroup]="form">
    <div class="flex items-center" mat-dialog-title>

        <h2 *ngIf="form.get('name').value"
            class="headline m-0 flex-auto">{{ form.get('name').value }}</h2>
        <h2 *ngIf="!form.get('name').value"
            class="headline m-0 flex-auto">{{ "LINKS.NUEVO" | translate }}</h2>

    </div>

    <mat-divider class="-mx-6 text-border"></mat-divider>

    <mat-dialog-content class="flex flex-col">
        <div class="flex flex-col sm:flex-row">
            <mat-form-field class="flex-auto">
                <mat-label>{{ "LINKS.NOMBRE" | translate }}</mat-label>
                <input cdkFocusInitial formControlName="name" matInput>

                <mat-icon class="mr-3" matPrefix svgIcon="mat:person"></mat-icon>
            </mat-form-field>
        </div>
        <div class="flex flex-col sm:flex-row">
            <mat-form-field class="flex-auto">
                <mat-label>{{ "LINKS.URL" | translate }}</mat-label>
                <input formControlName="link" matInput>

                <mat-icon class="mr-3" matPrefix svgIcon="mat:link"></mat-icon>
            </mat-form-field>
        </div>
        <div class="flex flex-col sm:flex-row">
            <mat-form-field class="example-chip-list flex-auto">
                <mat-label>{{ "LINKS.GRUPOS" | translate }}</mat-label>
                <mat-chip-list #chipList [placeholder]="'GRUPOS.GRUPOS_SELECCION' | translate">
                    <mat-chip
                        *ngFor="let group of selectedGroups"
                        (removed)="remove(group)">
                        {{ group.name }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <input
                        [placeholder]="'ACTIONS.BUSCAR_GROUPS' | translate"
                        #groupInput
                        formControlName="group"
                        [formControl]="groupsCtrl"
                        [matAutocomplete]="auto"
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="add($event)">
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)"
                                  [displayWith]="displayUserFn">
                    <mat-option *ngFor="let group of filteredGroups | async" [value]="group">
                        {{group.name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="flex flex-col sm:flex-row">
            <mat-checkbox formControlName="active">{{ "LINKS.ACTIVO" | translate }}</mat-checkbox>
        </div>

    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close type="button">{{ "LINKS.CANCELAR" | translate }}</button>
        <button *ngIf="isCreateMode()" color="primary" mat-flat-button
                type="submit">{{ "LINKS.CREAR" | translate }}</button>
        <button *ngIf="isUpdateMode()" color="primary" mat-flat-button
                type="submit">{{ "LINKS.ACTUALIZAR" | translate }}</button>
    </mat-dialog-actions>
</form>

