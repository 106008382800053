<vex-page-layout mode="card">

    <vex-page-layout-header class="pb-16 flex flex-col items-start justify-center">
        <div [class.container]="layoutCtrl.value === 'boxed'"
             [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
             class="w-full flex flex-col sm:flex-row justify-between">
            <div>
                <h1 class="title mt-0 mb-1">{{ "MENUS.PLANIFICACION_ACTIVIDADES" | translate }}</h1>
                <vex-breadcrumbs
                    [crumbs]="['MENUS.GESTION_DE_ACTIVIDADES', 'MENUS.PLANIFICACION_ACTIVIDADES']"></vex-breadcrumbs>
            </div>

        </div>
    </vex-page-layout-header>

    <vex-page-layout-content [class.container]="layoutCtrl.value === 'boxed'"
                             [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
                             class="-mt-6">

        <div class="card overflow-auto -mt-16">
            <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">

                <div
                    class="bg-foreground rounded-full px-4 h-10 max-w-[400px] flex-auto flex items-center border border-gray-300">
                    <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
                    <input [formControl]="projectSearchCtrl"
                           class="px-4 py-2 border-0 outline-none w-full bg-transparent"
                           [placeholder]="'ACTIONS.BUSCAR_PROYECTOS' | translate"
                           type="search">
                    <button type="button" *ngIf="data.projectSelected" matSuffix mat-icon-button
                            aria-label="Clear" (click)="data.projectSelected = ''">
                        <mat-icon class="icon-sm text-secondary" svgIcon="mat:close"></mat-icon>
                    </button>
                </div>

                <span class="flex-1"></span>

            </div>

            <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>

                <!-- Text Columns -->
                <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
                    <ng-container *ngIf="column.type === 'text' && column.property !== 'status'"
                                  [matColumnDef]="column.property">
                        <th *matHeaderCellDef class="uppercase" mat-header-cell
                            mat-sort-header> {{ column.label | translate }}</th>
                        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] }}</td>
                    </ng-container>
                    <ng-container *ngIf="column.property === 'status'" [matColumnDef]="column.property">
                        <th *matHeaderCellDef class="uppercase" mat-header-cell
                            mat-sortaaahh oka-header> {{ column.label | translate }}</th>

                        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
                            <div *ngFor="let label of labels">
                                <div [ngClass]="[label.textClass, label.bgClass]"
                                     class="rounded px-2 py-1 font-medium text-xs flex-none"
                                     *ngIf="label.text == row[column.property]">
                                    {{ label.text | translate }}
                                </div>
                            </div>
                        </td>
                    </ng-container>
                </ng-container>

                <!-- Action Column -->
                <ng-container matColumnDef="actions">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
                    <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
                        <button (click)="$event.stopPropagation()"
                                [matMenuTriggerData]="{ customer: row }"
                                [matMenuTriggerFor]="actionsMenu"
                                mat-icon-button
                                type="button">
                            <mat-icon svgIcon="mat:more_horiz"></mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
                <tr (click)="updateCustomer(row)"
                    *matRowDef="let row; columns: visibleColumns;"
                    @fadeInUp
                    class="hover:bg-hover trans-ease-out cursor-pointer"
                    mat-row></tr>

            </table>

            <mat-paginator  [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
                           class="sticky left-0"></mat-paginator>

            <div *ngIf="projectSelected">
                <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">

                    <div
                        class="bg-foreground rounded-full px-4 h-10 max-w-[400px] flex-auto flex items-center border border-gray-300">
                        <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
                        <input [formControl]="projectSearchCtrl"
                               class="px-4 py-2 border-0 outline-none w-full bg-transparent"
                               [placeholder]="'ACTIONS.BUSCAR_USUARIOS' | translate"
                               type="search">

                    </div>

                    <span class="flex-1"></span>

                    <button
                        class="ml-4 flex-none"
                        color="primary"
                        mat-mini-fab
                        [matTooltip]="'ACTIONS.AGREGAR_USUARIO' | translate "
                        type="button">
                        <mat-icon svgIcon="mat:add"></mat-icon>
                    </button>

                </div>



                <table @stagger [dataSource]="dataSourceUsersProjects" class="w-full" mat-table matSort>

                    <!-- Text Columns -->
                    <ng-container *ngFor="let column of columnsUsersProjects; trackBy: trackByProperty">
                        <ng-container *ngIf="column.type === 'text' && column.property !== 'status'"
                                      [matColumnDef]="column.property">
                            <th *matHeaderCellDef class="uppercase" mat-header-cell
                                mat-sort-header> {{ column.label | translate }}</th>
                            <td *matCellDef="let row" [ngClass]="column.cssClasses"
                                mat-cell>{{ row[column.property] }}</td>
                        </ng-container>
                        <ng-container *ngIf="column.property === 'status'" [matColumnDef]="column.property">
                            <th *matHeaderCellDef class="uppercase" mat-header-cell
                                mat-sortaaahh oka-header> {{ column.label | translate }}</th>

                            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
                                <div *ngFor="let label of labels">
                                    <div [ngClass]="[label.textClass, label.bgClass]"
                                         class="rounded px-2 py-1 font-medium text-xs flex-none"
                                         *ngIf="label.text == row[column.property]">
                                        {{ label.text | translate }}
                                    </div>
                                </div>
                            </td>
                        </ng-container>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="actions">
                        <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
                        <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
                            <button (click)="$event.stopPropagation()"
                                    [matMenuTriggerData]="{ customer: row }"
                                    [matMenuTriggerFor]="actionsMenu"
                                    mat-icon-button
                                    type="button">
                                <mat-icon svgIcon="mat:more_horiz"></mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
                    <tr (click)="updateCustomer(row)"
                        *matRowDef="let row; columns: visibleColumns;"
                        @fadeInUp
                        class="hover:bg-hover trans-ease-out cursor-pointer"
                        mat-row></tr>

                </table>

                <mat-paginator #MatPaginatorUsersProjects [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
                               class="sticky left-0"></mat-paginator>

            </div>
        </div>
    </vex-page-layout-content>

</vex-page-layout>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
    <button (click)="toggleColumnVisibility(column, $event)" *ngFor="let column of columns"
            class="checkbox-item mat-menu-item">
        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="column.visible" color="primary">
            {{ column.label | translate }}
        </mat-checkbox>
    </button>
</mat-menu>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
    <ng-template let-customer="customer" matMenuContent>
        <button mat-menu-item>
            <mat-icon svgIcon="mat:edit"></mat-icon>
            <span>Modify</span>
        </button>
        <button mat-menu-item>
            <mat-icon svgIcon="mat:delete"></mat-icon>
            <span>Delete</span>
        </button>
    </ng-template>
</mat-menu>
