import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { AuthService } from '@app/core/guard/auth-service';
import {PopoverService} from '../../../components/popover/popover.service';
import {ToolbarUserDropdownComponent} from './toolbar-user-dropdown/toolbar-user-dropdown.component';

@Component({
    selector: 'vex-toolbar-user',
    templateUrl: './toolbar-user.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserComponent implements OnInit {

    dropdownOpen: boolean;
    activeUser: any;
    activeRole: string;

    constructor(private popover: PopoverService,
                private cd: ChangeDetectorRef,
                private authService: AuthService) {

        this.activeUser = this.authService.User;
        this.activeRole = this.authService.Role;
    }

    ngOnInit() {

    }

    showPopover(originRef: HTMLElement) {
        this.dropdownOpen = true;
        this.cd.markForCheck();

        const popoverRef = this.popover.open({
            content: ToolbarUserDropdownComponent,
            origin: originRef,
            offsetY: 12,
            position: [
                {
                    originX: 'center',
                    originY: 'top',
                    overlayX: 'center',
                    overlayY: 'bottom'
                },
                {
                    originX: 'end',
                    originY: 'bottom',
                    overlayX: 'end',
                    overlayY: 'top',
                },
            ]
        });

        popoverRef.afterClosed$.subscribe(() => {
            this.dropdownOpen = false;
            this.cd.markForCheck();
        });
    }
}
