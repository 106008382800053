import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Observable, Subject, takeUntil} from "rxjs";
import {FormBuilder, FormControl, FormGroup, UntypedFormControl} from "@angular/forms";
import {ProjectsService} from "@core/services/backend/common/service/projects.service";
import {UsersService} from "@core/services/backend/common/service/users.service";
import {fadeInUp400ms} from "@vex/animations/fade-in-up.animation";
import {stagger40ms} from "@vex/animations/stagger.animation";
import {DateAdapter, MAT_DATE_LOCALE} from "@angular/material/core";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {WorkedHoursService} from "@core/services/backend/common/service/workedHours.service";
import moment from "moment";
import {WorkedHour} from "@core/services/backend/common/interface/workedHours";
import {User} from "@core/services/backend/common/interface/users";
import {SubAreasService} from "@core/services/backend/common/service/subAreas.service";
import {SubArea} from "@core/services/backend/common/interface/subAreas";
import {TableUtil} from "@vex/services/tableUtil";
import {TableColumn} from "@vex/interfaces/table-column.interface";
import {MatDialog} from "@angular/material/dialog";
import {
    ActivityAssignmentCommentsComponent
} from "@app/pages/activity-management/activity-assignment-comments/activity-assignment-comments.component";
import {
    ActivityAssignmentApproveComponent
} from "@app/pages/activity-management/activity-assignment-approve/activity-assignment-approve.component";
import {SelectionModel} from "@angular/cdk/collections";
import {ResponsibleService} from "@core/services/backend/common/service/responsible.service";
import {AuthService} from "@core/guard/auth-service";
import {Responsible} from "@core/services/backend/common/interface/responsible";
import {
    ActivityAssigmentDeclineComponent
} from "@app/pages/activity-management/activity-assigment-decline/activity-assigment-decline.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DialogComponent} from "@vex/components/dialog/dialog.component";
import {Project} from "@core/services/backend/common/interface/projects";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {map, startWith} from "rxjs/operators";
import {
    ActivityAssigmentPendingComponent
} from "@app/pages/activity-management/activity-assigment-pending/activity-assigment-pending.component";
import {da} from "date-fns/locale";

export class Group {
    level = 0;
    parent: Group;
    expanded = true;

    totalCounts = 0;
    totalExtras = 0;
    totalHours = 0;
    subArea = '';

    get visible(): boolean {
        return !this.parent || (this.parent.visible && this.parent.expanded);
    }
}

@Component({
    selector: 'vex-activity-assignment',
    templateUrl: './activity-assignment.component.html',
    styleUrls: ['./activity-assignment.component.scss'],
    animations: [
        fadeInUp400ms,
        stagger40ms
    ]
})
export class ActivityAssignmentComponent implements OnInit {

    private readonly destroying$ = new Subject<void>();
    layoutCtrl = new UntypedFormControl('boxed');

    _alldata: any[];
    columns: any[];
    displayedColumns: string[];
    groupByColumns: string[] = [];
    notSelection = false;
    viewAllUsers: boolean = false;

    filteredProjectList: Observable<Project[]>;
    dataSourceNotResponsible: MatTableDataSource<User> | null;
    dataSourceTodosResponsible: MatTableDataSource<User> | null;
    projectListAuto: Project[] = [];
    dataSource = new MatTableDataSource<any | Group>([]);
    workedHoursList: WorkedHour[] = [];
    workedHoursListEx: WorkedHour[] = [];
    usersList: User[] = [];
    projectsList: Project[] = [];
    responsibleList: any[] = [];
    searchForm: FormGroup;
    _allGroup: any[];
    selection = new SelectionModel<WorkedHour>(true, []);
    userSelectionMap: Map<number, SelectionModel<any>> = new Map<number, SelectionModel<any>>();

    expandedCar: any[] = [];
    expandedSubCar: WorkedHour[] = [];
    pendientesList: WorkedHour[] = [];
    pendientesListPending: WorkedHour[] = [];
    usersResponsibleList: any[] = [];
    projectResponsibleList: any[] = [];


    subAreasList: SubArea[] = [];
    estadoList = ['ACEPTADA', 'RECHAZADA', 'PENDIENTE'];
    tipoHHList = [{
        "name": "Horas Trabajadas", "group": [{"key": "MAS", "value": 'Mas de 40 Horas'},
            {"key": "MENOS", "value": 'Menos de 40 Horas'},
            {"key": "IGUAL", "value": '40 Horas'}]
    },
        {"name": "Horas Extras", "group": [{"key": "EXTRAS", "value": 'Horas Extras Registradas'}]}
    ];
    parentResponsible: Responsible;

    startOfWeek: moment.Moment;
    endOfWeek: moment.Moment;

    IS_SUPER_ADMIN: boolean = false;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    /*    columns: TableColumn<Link>[] = [
            {label: 'GRIDS.PROYECTO', property: 'projectName', type: 'text', visible: true},
            {label: 'GRIDS.FECHA', property: 'date', type: 'text', visible: true},
            {label: 'GRIDS.CANTIDAD', property: 'count', type: 'text', visible: true},
            {label: 'GRIDS.HHEE', property: 'extras', type: 'text', visible: true},
            {label: 'GRIDS.SUBAREA', property: 'subArea', type: 'text', visible: true},
            {label: 'GRIDS.ESTADO', property: 'status', type: 'text', visible: true},
            {label: 'GRIDS.SELECTED', property: 'selected', type: 'checkbox', visible: true},
            {label: 'GRIDS.ACCIONES', property: 'actions', type: 'button', visible: true}];
        displayedColumns = this.columns.map(column => column.property);*/

    pageSize = 200;
    pageSizeOptions: number[] = [5, 10, 20, 50, 100, 200];

    pendientes = 0;
    pendientesTotal = 0;
    rechazadas = 0;
    aceptadas = 0;
    total_hh = 0;

    constructor(private projects: ProjectsService,
                private users: UsersService,
                private fb: FormBuilder,
                private _formBuilder: FormBuilder,
                private workedHoursService: WorkedHoursService,
                private responsible: ResponsibleService,
                private dateAdapter: DateAdapter<any>,
                private translateService: TranslateService,
                private _adapter: DateAdapter<any>,
                @Inject(MAT_DATE_LOCALE) private _locale: string,
                private subAreasService: SubAreasService,
                private dialog: MatDialog,
                private authService: AuthService,
                private snackbar: MatSnackBar,
    ) {

        this.IS_SUPER_ADMIN = authService.Role === 'SUPER_ADMIN'

        this.columns = [{
            label: 'GRIDS.PROYECTO', field: 'projectName', type: 'text'
        }, {
            label: 'GRIDS.FECHA', field: 'date', type: 'text'
        }, {
            label: 'GRIDS.CANTIDAD', field: 'count', type: 'text'
        }, {
            label: 'GRIDS.HHEE', field: 'extras', type: 'text'
        }, {
            label: 'GRIDS.SUBAREA', field: 'subAreaName', type: 'text'
        }, {
            label: 'GRIDS.ESTADO', field: 'status', type: 'text'
        }, {
            label: 'GRIDS.SELECTED', field: 'selected', type: 'checkbox'
        }];
        this.displayedColumns = this.columns.map(column => column.field);
        this.groupByColumns = ['userName'];

        //this.groupByColumns = ['userName'];
        translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.updateLanguage();
        });
    }

    ngOnInit() {
        const that = this;
        this.dateAdapter.getFirstDayOfWeek = () => 1;
        this.updateLanguage();

        this.dataSourceTodosResponsible = new MatTableDataSource();
        this.dataSourceNotResponsible = new MatTableDataSource();

        this.startOfWeek = moment().add(-1, 'day').startOf('week');
        this.endOfWeek = moment().add(-1, 'day').endOf('week');

        this.searchForm = new FormGroup({
            startDate: new FormControl(this.startOfWeek.add(1, 'day').set("hour",0).set("minute",0).format('YYYY-MM-DDTHH:mm:ss')),
            endDate: new FormControl(this.endOfWeek.utc().set("hour",23).set("minute",59).set("second",59).format('YYYY-MM-DDTHH:mm:ss')),
            user: new FormControl(),
            subArea: new FormControl("0"),
            project: new FormControl(),
            estado: new FormControl(),
            tipohh: new FormControl()
        })

        this.loadData();
    }

    loadData() {
        const that = this;

        this.pendientes = 0;
        this.rechazadas = 0;
        this.aceptadas = 0;
        this.total_hh = 0;

        const result = [];
        let list = this.users.list('', 0, 1000000, '', false);
        list.pipe(takeUntil(this.destroying$)).subscribe((data) => {

            // @ts-ignore
            let userList = data.data;

            const list = this.responsible.list('', 0, 100000, '');
            list.pipe(takeUntil(this.destroying$)).subscribe((data) => {


                // @ts-ignore
                that.responsibleList = data.data;

                let resposibleList = []
                let users = '';
                let usersNot = [];
                // @ts-ignore
                data.data.forEach(record => {
                    const responsable = userList.filter(user => user.ID === record.user_id);
                    if (responsable.length > 0) {
                        if (record.users !== '') {
                            if (record.users === 'TODOS') {
                                resposibleList.push(responsable[0])
                            } else {
                                users+= record.users + ',';
                            }
                        }
                    }

                });

                usersNot = users.split(",")
                usersNot = userList.filter(user => !usersNot.includes(user.ID.toString()));

                that.dataSourceNotResponsible.data = usersNot;
                that.dataSourceTodosResponsible.data = resposibleList;

            });
        });


        let listWh = this.workedHoursService.pendingStatus(JSON.stringify(this.getSearch()), 0, 10000000, 'date');
        listWh.pipe(takeUntil(this.destroying$)).subscribe((response) => {
            // @ts-ignore
            this.pendientesList = response.data.hours;
            // @ts-ignore
            this.pendientesListPending = response.data.hoursPending;
            // @ts-ignore
            this.pendientesTotal = response.data.pendingTotal;

            this.pendientesList.forEach(hours => {
                switch (hours.status) {
                    case 'PENDIENTE':
                        this.pendientes += Number(hours.count);
                        break;
                    case 'RECHAZADA':
                        this.rechazadas += Number(hours.count);
                        break;
                    case 'ACEPTADA':
                        this.aceptadas += Number(hours.count);
                        break;
                }
            });
        });

        list = this.projects.list('', 0, 100000, '', false);
        list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
            // @ts-ignore
            that.projectList = data.data;

            // @ts-ignore
            this.projectListAuto = data.data;
            this.projectListAuto.forEach(project => {
                project.name = project.code + " - " + project.name;
            })


            this.filteredProjectList = this.searchForm.get('project')!.valueChanges.pipe(
                startWith(''),
                map(value => {
                    const name = typeof value === 'string' ? value : value?.name;
                    return name ? this._filter(name as string) : this.projectListAuto.slice();
                }),
            );
        });

        list = this.subAreasService.list('', 0, 10000, '');
        list.pipe(takeUntil(this.destroying$)).subscribe((data) => {

            // @ts-ignore
            that.subAreasList = data.data;

            const list = this.users.list(JSON.stringify(that.getSearchUsers()), 0, 100000, 'last_Name', true);
            list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
                // @ts-ignore
                that.usersList = data.data;

            });
        });

        list = this.workedHoursService.details(JSON.stringify(that.getSearch()), 0, 100000, 'date');
        list.pipe(takeUntil(this.destroying$)).subscribe((data) => {

            // @ts-ignore
            that.workedHoursList = data.data;
            //that.w
            //that.workedHoursList = that.workedHoursList.filter(workHours => that.usersResponsibleList.map(x => x).includes(workHours.userId))
            // let filteredWorkedHoursList = []

            // @ts-ignore
            /*const list = this.responsible.get(that.authService.jwtService.decodedToken.id);
            list.pipe(takeUntil(this.destroying$)).subscribe((data) => {

                // @ts-ignore
                that.parentResponsible = data;

                let users = that.parentResponsible.users.toString().split(',');
                let projects = that.parentResponsible.projects.toString().split(',');

                if (that.parentResponsible.users.toString() === 'TODOS') {
                    users = that.usersList.map((user) => user.ID.toString())
                }
                that.notSelection = that.parentResponsible.users === '';
                filteredWorkedHoursList.push(...that.workedHoursList.filter(hours => {
                    return users.some((user) => {
                        return user === hours.userId.toString()
                    })
                }));

                that.pendientesListSelected.push(...that.pendientesList.filter(hours => {
                    return users.some((user) => {
                        return user === hours.userId.toString()
                    })
                }));

                if (that.parentResponsible.projects.toString() === 'TODOS') {
                    // @ts-ignore
                    projects = that.projectList.map((project) => project.ID.toString())
                }
                filteredWorkedHoursList.push(...that.workedHoursList.filter(hours => {
                    return projects.some((project) => {
                        return project === hours.projectId.toString()
                    })
                }));

                that.pendientesListSelected.push(...that.pendientesList.filter(hours => {
                    return projects.some((project) => {
                        return project === hours.projectId.toString()
                    })
                }));*/

            // filteredWorkedHoursList = that.workedHoursList.filter((value, index) => that.workedHoursList.indexOf(value) === index);
            if (that.workedHoursList != null && that.workedHoursList.length !== 0) {
                that.workedHoursList.sort((a, b) => a.date.localeCompare(b.date));
            } else {
                that.workedHoursList = [];
            }

            let filtered = that.workedHoursList;
            /*                    if (this.searchForm.get("tipohh").value !== null) {
                                    switch (this.searchForm.get("tipohh").value) {

                                        case "EXTRAS":
                                            filtered = filtered.filter(hour => hour.extras > 0);
                                            break;
                                    }
                                }*/

            /*// @ts-ignore
            filtered.forEach((item, index) => {
                // item.id = index + 1;
                // @ts-ignore
                switch (item.status) {
                    case 'PENDIENTE':
                        that.pendientes += 1;
                        break;
                    case 'ACEPTADA':
                        that.aceptadas += 1;
                        break;
                    case 'RECHAZADA':
                        that.rechazadas += 1;
                        break;
                }
                this.total_hh += 1

                item.userName = item.user.firstName + ' ' + item.user.lastName;
                // @ts-ignore
                item.projectName = item.project.code + ' ' + item.project.name;
                item.dateCreated = item.date;
                item.date = moment(item.date).format('DD-MM-YYYY')
                // @ts-ignore
                //item.type = users.findIndex(user => user === item.userId.toString()) !== -1 ? 'USER' : projects.findIndex(project => project === item.projectId.toString()) !== -1 ? 'PROJECT' : '';
                // @ts-ignore
                let subArea = that.subAreasList.filter(subarea => subarea.ID === item.user.subAreasId)
                if (subArea.length != 0) {
                    // @ts-ignore
                    item.subAreaName = subArea[0].name;
                }
            });*/

            /*that.usersList.forEach((user) => {
                // @ts-ignore
                if (user.DeletedAt === null) {
                    const workHour: WorkedHour = {
                        ID: 0,
                        projectId: 0,
                        userId: user.ID,
                        date: '',
                        count: 0,
                        comments: '',
                        statusComment: '',
                        extras: 0,
                        status: 'PENDIENTE',
                        selected: false
                    }
                    // @ts-ignore
                    workHour.user = user;
                    // @ts-ignore
                    workHour.userName = user.lastName + ' ' + user.firstName;
                    // @ts-ignore
                    let subArea = that.subAreasList.filter(subarea => subarea.ID === user.subAreasId)
                    if (subArea.length != 0) {
                        // @ts-ignore
                        workHour.subAreaName = subArea[0].name;
                    }

                    //if (!that.viewAllUsers) {
                    /!*const exists = that.workedHoursList.filter(hour => hour.userId === user.ID)
                    if (exists.length === 0) {
                        if (that.parentResponsible.users === 'TODOS' || (workHour.userId != 0 && that.parentResponsible.users.indexOf(workHour.userId.toString()) !== -1)) {
                            if (users.findIndex(item => item === workHour.userId.toString()) !== -1) {
                                filtered.push(workHour);
                            }
                        } else if (that.parentResponsible.projects === 'TODOS' || (workHour.projectId != 0 && that.parentResponsible.projects.indexOf(workHour.projectId.toString()) !== -1)) {
                            if (projects.findIndex(item => item === workHour.projectId.toString()) === -1) {
                                filtered.push(workHour);
                            }
                        }
                    }*!/
                    //}
                }
            })
*/

            // @ts-ignore
            that._alldata = filtered;
            that.dataSource.data = that.addGroups(that._alldata, that.groupByColumns);
            that.dataSource.filterPredicate = that.customFilterPredicate.bind(that);
            that.dataSource.filter = performance.now().toString();

            that.collapseAll();

            //});
            //   });
        });


    }

    /*
        groupBy(event, column) {
            event.stopPropagation();
            this.checkGroupByColumn(column.field, true);
            this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
            this.dataSource.filter = performance.now().toString();
        }
    */

    private _filter(name: string): Project[] {
        const filterValue = name.toLowerCase();
        return this.projectListAuto.filter(option => option.name.toLowerCase().indexOf(filterValue) != -1);
    }

    displayProjectFn(project: Project) {
        if (project) {
            return project.name;
        }
    }

    onSelectionChange(event: MatAutocompleteSelectedEvent) {
        this.loadData();
    }


    checkGroupByColumn(field, add) {
        let found = null;
        for (const column of this.groupByColumns) {
            if (column === field) {
                found = this.groupByColumns.indexOf(column, 0);
            }
        }
        if (found != null && found >= 0) {
            if (!add) {
                this.groupByColumns.splice(found, 1);
            }
        } else {
            if (add) {
                this.groupByColumns.push(field);
            }
        }
    }

    /*
        unGroupBy(event, column) {
            event.stopPropagation();
            this.checkGroupByColumn(column.field, false);
            this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
            this.dataSource.filter = performance.now().toString();
        }
    */


    customFilterPredicate(data: any | Group, filter: string): boolean {
        // @ts-ignore
        return (data instanceof Group) ? data.visible : this.getDataRowVisible(data);
    }

    getDataRowVisible(data: any): boolean {
        const groupRows = this.dataSource.data.filter(
            row => {
                if (!(row instanceof Group)) {
                    return false;
                }
                let match = true;
                this.groupByColumns.forEach(column => {
                    if (!row[column] || !data[column] || row[column] !== data[column]) {
                        match = false;
                    }
                });
                return match;
            }
        );

        if (groupRows.length === 0) {
            return true;
        }
        const parent = groupRows[0] as Group;
        // @ts-ignore
        return parent.visible && parent.expanded;
    }

    groupHeaderClick(row) {
        row.expanded = !row.expanded;
        this.dataSource.filter = performance.now().toString();  // bug here need to fix
    }

    addGroups(data: any[], groupByColumns: string[]): any[] {
        const rootGroup = new Group();
        rootGroup.expanded = true;
        return this.getSublevel(data, 0, groupByColumns, rootGroup);
    }

    getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
        if (level >= groupByColumns.length) {
            return data;
        }
        let groups = this.uniqueBy(
            data.map(
                row => {
                    const result = new Group();
                    result.level = level + 1;
                    // @ts-ignore
                    result.parent = parent;
                    for (let i = 0; i <= level; i++) {
                        result[groupByColumns[i]] = row[groupByColumns[i]];
                    }
                    return result;
                }
            ),
            JSON.stringify);

        const currentColumn = groupByColumns[level];
        let subGroups = [];

        groups.forEach(group => {
            const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
            let totalHours = 0;
            let totalExtras = 0;
            let subArea = '';
            rowsInGroup.forEach((row) => {
                subArea = row.subAreaName;
                totalHours += Number(row.count);
                totalExtras += Number(row.extras);
            })
            group.totalCounts = rowsInGroup.length;
            group.totalHours = totalHours;
            group.totalExtras = totalExtras;
            group.subArea = subArea;
            group.selected = false;
        });

        if (this.searchForm.get("tipohh").value !== null) {
            switch (this.searchForm.get("tipohh").value) {
                case "MAS":
                    groups = groups.filter(group => group.totalHours > 40);
                    break;
                case "MENOS":
                    groups = groups.filter(group => group.totalHours < 40);
                    break;
                case "IGUAL":
                    groups = groups.filter(group => group.totalHours === 40);
                    break;
                case "EXTRAS":
                    groups = groups.filter(group => group.totalExtras > 0);
                    break;
            }
        }
        groups.sort((a, b) => {
            if (b.totalHours === a.totalHours) {
                return b.userName - a.userName;
            }
            return b.totalHours > a.totalHours ? 1 : -1;
        });
        groups.forEach(group => {
            const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
            const subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
            subGroup.unshift(group);
            subGroups = subGroups.concat(subGroup);
        });
        return subGroups;
    }

    uniqueBy(a, key) {
        const seen = {};
        return a.filter((item) => {
            const k = key(item);
            return seen.hasOwnProperty(k) ? false : (seen[k] = true);
        });
    }

    isGroup(index, item): boolean {
        return item.level;
    }

    expandAll() {
        this.dataSource.data.forEach((row) => {
            row.expanded = true;
            this.dataSource.filter = performance.now().toString();
        });
    }

    collapseAll() {
        this.dataSource.data.forEach((row) => {
            row.expanded = false;
            this.dataSource.filter = performance.now().toString();
        });
    }

    private getHorasPendientes() {
        return {
            status: 'PENDIENTE',
        }
    }


    private getSearchUsers() {
        return {
            name: this.searchForm.get("user").value ? '%' + this.searchForm.get("user").value.toString().toUpperCase() + '%' : '',
            subArea: parseInt(this.searchForm.get("subArea").value)
        }
    }

    private getSearch() {
        return {
            user: this.searchForm.get("user").value ? '%' + this.searchForm.get("user").value.toString().toUpperCase() + '%' : '',
            startDate: this.searchForm.get("startDate").value,
            endDate: this.searchForm.get("endDate").value,
            subArea: parseInt(this.searchForm.get("subArea").value),
            projectId: this.searchForm.get("project").value ? parseInt(this.searchForm.get("project").value.ID) : null,
            status: this.searchForm.get("estado").value,
            tipoHH: this.searchForm.get("tipohh").value
        }
    }

    private updateLanguage() {
        this._locale = this.translateService.currentLang;
        this.dateAdapter.setLocale(this._locale);
    }

    buscar() {
        this.loadData();
    }

    isCheater(row) {
        const data = row.totalHours;
        return true;
    }

    export() {
        // console.log('export')
        const result = [];
        const that = this;

        if (this.IS_SUPER_ADMIN) {

            const list = this.workedHoursService.list('', 0, 10000000, 'date');
            list.pipe(takeUntil(this.destroying$)).subscribe((response) => {

                // @ts-ignore
                response.data.forEach((hour) => {
                    const data = {
                        IdRegistro: 0,
                        Proyecto: '',
                        CodigoProyecto: '',
                        Rut: '',
                        Usuario: '',
                        SubArea: '',
                        Horas: 0,
                        Horas_Extras: 0,
                        Fecha: null,
                        Comentarios: '',
                        Estado: '',
                        Usuario_Responsable: ''
                    }

                    data.IdRegistro = hour.ID;
                    // @ts-ignore
                    if (hour.project) {
                        // @ts-ignore
                        data.Proyecto = hour.project.name
                        // @ts-ignore
                        data.CodigoProyecto = hour.project.code
                    }
                    // @ts-ignore
                    if (hour.user) {
                        // @ts-ignore

                        // @ts-ignore
                        if (hour.user.rut !== '') {
                            // @ts-ignore
                            if (hour.user.rut.indexOf('-') === -1) {
                                // @ts-ignore
                                data.Rut = hour.user.rut.substring(0, hour.user.rut.length - 1) + '-' + hour.user.rut.substring(hour.user.rut.length - 1, hour.user.rut.length);
                            } else {

                                // @ts-ignore
                                data.Rut = hour.user.rut;
                            }
                        }
                        // @ts-ignore
                        data.Rut = data.Rut.replaceAll(".", "");
                        const subArea = this.subAreasList.filter(subArea => subArea.ID === hour.user.subAreasId);
                        if (subArea.length !== 0) {
                            hour.subAreaName = subArea[0].name;
                        }
                        // @ts-ignore
                        data.SubArea = hour.subAreaName
                    }
                    // @ts-ignore
                    data.Usuario = hour.user.lastName + ' ' + hour.user.firstName;
                    data.Estado = hour.status;
                    data.Usuario_Responsable = hour.userApproved;
                    data.Horas = Number(hour.count);
                    data.Horas_Extras = Number(hour.extras);

                    if (hour.date !== '') {
                        let fechaHora = hour.date.split('T')
                        let fecha = fechaHora[0].split('-')
                        data.Fecha = new Date(fecha[0], Number(fecha[1]) - 1, Number(fecha[2]) + 1)
                    }

                    data.Comentarios = hour.comments;


                    result.push(data)
                });
                TableUtil.exportArrayToExcel(result, "WorkedHours");
            });

        } else {

            this._alldata.forEach((hour) => {

                const data = {
                    Proyecto: '',
                    CodigoProyecto: '',
                    Rut: '',
                    Usuario: '',
                    SubArea: '',
                    Horas: 0,
                    Horas_Extras: 0,
                    Fecha: null,
                    Comentarios: '',
                    Estado: '',
                    Usuario_Responsable: ''
                }

                // @ts-ignore
                hour.project = that.projectList.filter(project => project.ID === hour.projectId)[0]
                // @ts-ignore
                if (hour.project) {
                    // @ts-ignore
                    data.Proyecto = hour.project.name
                    // @ts-ignore
                    data.CodigoProyecto = hour.project.code
                }

                hour.user = that.usersList.filter(user => user.ID === hour.userId)[0]
                // @ts-ignore
                if (hour.user) {
                    // @ts-ignore

                    // @ts-ignore
                    if (hour.user.rut !== '') {
                        // @ts-ignore
                        if (hour.user.rut.indexOf('-') === -1) {
                            // @ts-ignore
                            data.Rut = hour.user.rut.substring(0, hour.user.rut.length - 1) + '-' + hour.user.rut.substring(hour.user.rut.length - 1, hour.user.rut.length);
                        } else {

                            // @ts-ignore
                            data.Rut = hour.user.rut;
                        }
                    }
                    // @ts-ignore
                    data.Rut = data.Rut.replaceAll(".", "");
                    // @ts-ignore
                    data.SubArea = hour.subAreaName
                }
                // @ts-ignore
                data.Usuario = hour.user.lastName + ' ' + hour.user.firstName;
                data.Estado = hour.status;
                data.Usuario_Responsable = hour.userApproved;
                data.Horas = Number(hour.count);
                data.Horas_Extras = Number(hour.extras);

                if (hour.date !== '') {
                    let fechaHora = hour.date.split('T')
                    let fecha = fechaHora[0].split('-')
                    data.Fecha = new Date(fecha[2], Number(fecha[1]) - 1, Number(fecha[0]) + 1)
                }
                data.Comentarios = hour.comments;


                result.push(data)
            })
            TableUtil.exportArrayToExcel(result, "WorkedHours");

        }


    }

    reset() {
        console.log("reset")
        this.searchForm.reset();
        this.searchForm.controls["startDate"].setValue(this.startOfWeek.toDate());
        this.searchForm.controls["endDate"].setValue(this.endOfWeek.toDate());
        this.loadData();
    }

    dateSelected() {
        let start = this.searchForm.get("startDate").value;
        let end = this.searchForm.get("endDate").value;
        if (start > end && end != null) {
            this.searchForm.get("startDate").setValue(end);
            this.searchForm.get("endDate").setValue(start);
        }
        console.log("dateSelected")
    }

    trackByProperty<T>(index: number, column: TableColumn<T>) {
        return column.property;
    }

    Comments(project: any) {
        console.log(project)
        this.dialog.open(ActivityAssignmentCommentsComponent, {
            data: project,
            maxWidth: '100vw',
            maxHeight: '100vh',
            //height: '50%',
            width: '60%',
            panelClass: 'full-screen-modal'
        }).afterClosed().subscribe(updatedCustomer => {
            this.selection.clear()
            this.loadData();
        });
    }

    Approved($event: { stopPropagation: () => void; }, group: any) {
        $event.stopPropagation();
        console.log(group)
        this.dialog.open(ActivityAssignmentApproveComponent, {
            data: group,
            maxWidth: '200vw',
            maxHeight: '100vh',
            //height: '50%',
            width: '80%',
            panelClass: 'full-screen-modal'
        }).afterClosed().subscribe(updatedCustomer => {
            this.selection.clear()
            this.loadData();
        });
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }


    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    groupToggle(group) {
        console.log(group);
        group.selected = !group.selected;
        this.dataSource.data.forEach(row => {
            if (group.selected === true) {
                if (row.userName && row.userName === group.userName) {
                    this.selection.select(row);
                }
            } else {
                if (row.userName && row.userName === group.userName) {
                    this.selection.deselect(row);
                }
            }
        });
    }

    rechazarSelected() {
        let selected = this.selection.selected;
        selected = selected.filter((row) => typeof (row.ID) !== 'undefined')
        const data = {
            hours: selected,
            tipo: 'RECHAZAR'
        }
        this.dialog.open(ActivityAssigmentDeclineComponent, {
            data: data,
            maxWidth: '200vw',
            maxHeight: '100vh',
            //height: '50%',
            width: '80%',
            panelClass: 'full-screen-modal'
        }).afterClosed().subscribe(updatedCustomer => {
            this.selection.clear()
            this.loadData();
        });
    }

    comentarSelected() {
        let selected = this.selection.selected;
        selected = selected.filter((row) => typeof (row.ID) !== 'undefined')
        const data = {
            hours: selected,
            tipo: 'COMENTAR'
        }
        this.dialog.open(ActivityAssigmentDeclineComponent, {
            data: data,
            maxWidth: '200vw',
            maxHeight: '100vh',
            //height: '50%',
            width: '80%',
            panelClass: 'full-screen-modal'
        }).afterClosed().subscribe(updatedCustomer => {
            this.selection.clear()
            this.loadData();
        });
    }

    pendingSelected() {
        let selected = this.selection.selected;
        const data = {
            pendingHours: this.pendientesListPending,
        }
        this.dialog.open(ActivityAssigmentPendingComponent, {
            data: data,
            maxWidth: '200vw',
            maxHeight: '100vh',
            //height: '50%',
            width: '80%',
            panelClass: 'full-screen-modal'
        }).afterClosed().subscribe(weekSelected => {
            console.log(weekSelected)
            if (typeof weekSelected !== 'undefined' && weekSelected !== '') {
                this.searchForm.controls["startDate"].setValue(weekSelected.startDate);
                this.searchForm.controls["endDate"].setValue(weekSelected.endDate);
                this.searchForm.controls["estado"].setValue("PENDIENTE")
                this.loadData();
            } else {
                this.selection.clear()
                this.reset();
            }
        });
    }

    aprobarSelected() {

        const that = this;

        const data = {
            title: "Aprobar Horas",
            message: "Esta seguro de aprobar las horas seleccionadas..."
        }
        this.dialog.open(DialogComponent, {
            data: data,
            disableClose: false,
            width: '400px'
        }).afterClosed().subscribe(result => {
            if (!result) {
                this.selection.clear()
                this.loadData();
                return
            }

            const payload = []
            let selected = this.selection.selected.filter((row) => typeof (row.ID) !== 'undefined')

            selected.forEach((hour) => {

                const record: {} = {}

                // @ts-ignore
                record.id = hour.ID;
                // @ts-ignore
                record.projectId = hour.projectId;
                // @ts-ignore
                record.userId = hour.userId;
                // @ts-ignore
                record.date = hour.dateCreated;
                // @ts-ignore
                record.count = hour.count;
                // @ts-ignore
                record.extras = hour.extras;
                // @ts-ignore
                record.comments = hour.comments;
                // @ts-ignore
                record.isAddOrUpdate = true
                // @ts-ignore
                record.statusComments = hour.statusComment + moment().format("DD-MM-YYYY") + "|" + this.authService.User + "|ACEPTADA" + "|Aceptada##";
                // @ts-ignore
                record.userApprovedId = that.authService.UserId;
                // @ts-ignore
                record.userUpdatedId = that.authService.UserId;
                // @ts-ignore
                record.userApproved = that.authService.User;
                // @ts-ignore
                record.status = "ACEPTADA";

                payload.push(record);

            });

            this.workedHoursService.create(payload).subscribe({
                next(result) {
                    that.handleSuccessResponse();
                    that.loadData();
                    this.loading = false;
                    that.selection.clear();
                },
                error(err) {
                    that.selection.clear();
                    this.loading = false;
                    that.loadData();
                    that.handleWrongResponse(err);
                }
            });

        });

    }

    handleSuccessResponse() {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            this.snackbar.open(res.LABELS.SUCCESS, '', {
                duration: 3000,
                panelClass: ['green-snackbar']
            });
        });

    }

    handleWrongResponse(err) {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            this.snackbar.open(res.LABELS.ERROR, '', {
                duration: 3000,
                panelClass: ['red-snackbar']
            });
        });
    }

    verComentarios(row) {

        const data = {
            user: row.userName,
            project: row.projectName,
            date: row.date,
            status: row.status,
            comments: row.comments,
            statusComments: row.statusComment
        }
        this.dialog.open(ActivityAssignmentCommentsComponent, {
            data: data,
            disableClose: false,
            width: '800px',
            height: '400px'
        }).afterClosed().subscribe(result => {
        });

    }

    verTodos() {
        this.viewAllUsers = !this.viewAllUsers;
        this.loadData();

    }
}


