<div class="w-full h-full bg-pattern flex flex-col items-center justify-center">
    <div @fadeInUp class="card rounded-b-none shadow-none  overflow-hidden w-full max-w-md">

        <div class="p-6 pb-0 flex flex-col fa-pull-right px-1 ">

            <button [matMenuTriggerFor]="languageMenu" mat-icon-button type="button">
                <mat-icon svgIcon="flag:us" *ngIf="iconLanguage === 'en-US'"></mat-icon>
                <mat-icon svgIcon="flag:cl" *ngIf="iconLanguage === 'es-CL'"></mat-icon>
                <mat-icon svgIcon="flag:br" *ngIf="iconLanguage === 'br'"></mat-icon>
                <mat-icon svgIcon="flag:fr" *ngIf="iconLanguage === 'fr'"></mat-icon>
                <mat-icon svgIcon="flag:de" *ngIf="iconLanguage === 'de'"></mat-icon>
            </button>
        </div>

    </div>

    <div @fadeInUp class="card rounded-t-none shadow-none overflow-hidden w-full max-w-md">
    <div class="p-6 pb-0 flex flex-col items-center justify-center">
      <div class="fill-current text-center">
          <img class="w-64" src="assets/img/Logo-Dessau-400px.png" alt="">
      </div>
    </div>

    <div class="text-center mt-4">
      <h2 class="title m-0">{{ "LOGIN.TITULO" | translate }}</h2>
      <h4 class="body-2 text-secondary m-0">{{ "LOGIN.SUBTITULO" | translate }}</h4>
    </div>

    <div [formGroup]="form" class="p-6 flex flex-col gap-4">
      <div class="flex flex-col">
        <mat-form-field class="flex-1">
          <mat-label>{{ "LOGIN.EMAIL" | translate }}</mat-label>
          <input formControlName="email" matInput required>
          <mat-error *ngIf="form.get('email').hasError('required')">We need an email address to log you in</mat-error>
        </mat-form-field>
        <mat-form-field class="flex-1">
          <mat-label>{{ "LOGIN.CONTRASENA" | translate }}</mat-label>
          <input [type]="inputType" formControlName="password" matInput required>
          <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Toggle Visibility" type="button">
            <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
            <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
          </button>
          <mat-hint>{{ "LOGIN.CONTRASENA_HINT" | translate }}</mat-hint>
          <mat-error *ngIf="form.get('password').hasError('required')">{{ "LOGIN.CONTRASENA_ERROR_HINT" | translate }}</mat-error>
        </mat-form-field>
      </div>

      <div class="flex items-center justify-between">
        <mat-checkbox class="caption" color="primary">{{ "LOGIN.RECORDAR" | translate }}</mat-checkbox>
        <a [routerLink]="['/forgot-password']" class="caption">{{ "LOGIN.OLVIDASTE" | translate }}</a>
      </div>

      <button (click)="send()" color="primary" mat-raised-button type="button">
          {{ "LOGIN.INICIAR_SESSION" | translate }}
      </button>

     <!-- <p class="text-secondary text-center">
          {{ "LOGIN.SIN_CUENTA" | translate }}<br/>
        <a [routerLink]="['/register']">{{ "LOGIN.PRESIONE" | translate }}</a>
      </p>-->
    </div>
  </div>
</div>

<mat-menu #languageMenu="matMenu" overlapTrigger="false">
    <button mat-menu-item (click)="setLanguageTo('en-US')">
        <mat-icon svgIcon="flag:us"></mat-icon>
        <span>{{ 'COUNTRIES.en-US' | translate }}</span>
    </button>

    <button mat-menu-item (click)="setLanguageTo('es-CL')">
        <mat-icon svgIcon="flag:cl"></mat-icon>
        <span>{{ 'COUNTRIES.es-CL' | translate }}</span>
    </button>

    <button mat-menu-item (click)="setLanguageTo('br')">
        <mat-icon svgIcon="flag:br"></mat-icon>
        <span>{{ 'COUNTRIES.pt-BR' | translate }}</span>
    </button>

    <button mat-menu-item (click)="setLanguageTo('fr')">
        <mat-icon svgIcon="flag:fr"></mat-icon>
        <span>{{ 'COUNTRIES.fr-FR' | translate }}</span>
    </button>

    <button mat-menu-item (click)="setLanguageTo('de')">
        <mat-icon svgIcon="flag:de"></mat-icon>
        <span>{{ 'COUNTRIES.de-DE' | translate }}</span>
    </button>
</mat-menu>
