<div class="container py-gutter">
    <div class="card overflow-hidden">
        <div class="h-64 relative overflow-hidden">
            <img class="w-full h-full object-cover" src="assets/img/demo/landscape.jpg" alt="">
            <div class="absolute bg-contrast-black opacity-25 top-0 right-0 bottom-0 left-0 w-full h-full z-0"></div>

            <img class="avatar h-24 w-24 absolute top-6 ltr:left-4 rtl:right-4 sm:hidden"
                 src="assets/img/avatars/1.jpg"
                 alt="">
        </div>

        <div class="z-10 relative -mt-16 px-gutter flex items-center">

            <img @scaleIn
                 class="avatar h-24 w-24 flex-none align-start hidden sm:block border-2 border-white"
                 src="assets/img/avatars/1.jpg"
                 alt="">

            <div class="max-w-full flex-auto sm:ltr:ml-6 sm:rtl:mr-6">
                <div class="h-16 flex items-end">
                    <h1 @fadeInRight class="headline text-contrast-white pb-2 m-0">{{ activeUser }}</h1>
                </div>

                <nav class="vex-tabs vex-tabs-dense border-0" mat-tab-nav-bar>
                    <a #rla="routerLinkActive"
                       *ngFor="let link of links"
                       [active]="rla.isActive"
                       [disabled]="link.disabled"
                       [routerLink]="link.route"
                       mat-tab-link
                       queryParamsHandling="preserve"
                       [routerLinkActiveOptions]="link.routerLinkActiveOptions || { exact: false }"
                       routerLinkActive>
                        {{ link.label | translate }}
                    </a>
                </nav>
            </div>
        </div>
    </div>

    <router-outlet></router-outlet>
</div>
