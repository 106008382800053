import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {LinksApi} from "@core/services/backend/common/api/links.api";
import {Link, LinksData} from "@core/services/backend/common/interface/links";


@Injectable()
export class LinksService extends LinksData {


    constructor(private api: LinksApi) {
        super();
    }

    list(search: string, pageNumber: number, pageSize: number, orderBy: string): Observable<any[]> {
        return this.api.list(search, pageNumber, pageSize, orderBy);
    }

    get(id: number, cloudId: number): Observable<Link> {
        return this.api.get(id, cloudId);
    }

    // tslint:disable-next-line:no-shadowed-variable
    create(Link: any): Observable<Link> {
        return this.api.add(Link);
    }

    // tslint:disable-next-line:no-shadowed-variable
    update(Link: any): Observable<Link> {
        return this.api.update(Link);
    }

    delete(Link: any): Observable<Link> {
        return this.api.delete(Link);
    }

}
