import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, UntypedFormControl} from "@angular/forms";
import {Subject, takeUntil} from "rxjs";
import {fadeInUp400ms} from "@vex/animations/fade-in-up.animation";
import {stagger40ms} from "@vex/animations/stagger.animation";
import {User} from "@core/services/backend/common/interface/users";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {TableColumn} from "@vex/interfaces/table-column.interface";
import {Project} from "@core/services/backend/common/interface/projects";
import {UsersService} from "@core/services/backend/common/service/users.service";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SubAreasService} from "@core/services/backend/common/service/subAreas.service";
import {RolesService} from "@core/services/backend/common/service/roles.service";
import {AuthService} from "@core/guard/auth-service";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {ResponsibleService} from "@core/services/backend/common/service/responsible.service";
import {TableUtil} from "@vex/services/tableUtil";

@Component({
    selector: 'vex-responsible.users',
    templateUrl: './responsible.users.component.html',
    styleUrls: ['./responsible.users.component.scss'],
    animations: [
        fadeInUp400ms,
        stagger40ms
    ]
})
export class ResponsibleUsersComponent implements OnInit {

    layoutCtrl = new UntypedFormControl('boxed');
    private readonly destroying$ = new Subject<void>();

    userList: User[] = [];
    userListUsers: User[] = [];
    subAreasList = []
    rolesList = []
    dataSource: MatTableDataSource<User> | null;
    dataSourceNotResponsible: MatTableDataSource<User> | null;
    dataSourceTodosResponsible: MatTableDataSource<User> | null;
    dataSourceUsers: MatTableDataSource<User> | null;
    searchForm: FormGroup;
    searchFormUsers: FormGroup;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    @ViewChild('MatPaginatorUsers', {static: true}) paginatorUsers: MatPaginator;
    @ViewChild('MatSortUsers', {static: true}) sortUsers: MatSort;

    data = {
        projectSelected: ''
    }
    isSuperAdmin = false;
    currentPage = 0;
    currentPageUsers = 0;

    pageSize = 20;
    pageSizeUsers = 2000;

    pageSizeOptions: number[] = [5, 10, 20, 50];
    pageSizeOptionsUsers: number[] = [5, 10, 20, 50];

    optionsSelected = 'ALL';
    selectedUser: string[] = [];
    responsible: User;
    projectData = ''

    columns: TableColumn<Project>[] = [
        {label: 'GRIDS.ID', property: 'ID', type: 'text', visible: false},
        {
            label: 'GRIDS.NOMBRE_USUARIO',
            property: 'firstName',
            type: 'text',
            visible: true,
            cssClasses: ['font-medium']
        },
        {label: 'GRIDS.APELLIDOS', property: 'lastName', type: 'text', visible: true},
        {label: 'GRIDS.EMAIL', property: 'email', type: 'text', visible: false},
        {label: 'GRIDS.SUBAREA', property: 'subAreaName', type: 'text', visible: false},
        {label: 'GRIDS.ROL', property: 'roleName', type: 'text', visible: false},
    ];

    columnsSelected: TableColumn<Project>[] = [
        {label: 'GRIDS.ID', property: 'ID', type: 'text', visible: false},
        {
            label: 'GRIDS.NOMBRE_USUARIO',
            property: 'firstName',
            type: 'text',
            visible: true,
            cssClasses: ['font-medium']
        },
        {label: 'GRIDS.APELLIDOS', property: 'lastName', type: 'text', visible: true},
        {label: 'GRIDS.ACCIONES', property: 'actions', type: 'button', visible: true}
    ];

    constructor(private users: UsersService,
                private responsibleService: ResponsibleService,
                private dialog: MatDialog,
                private translateService: TranslateService,
                private snackbar: MatSnackBar,
                private subAreasService: SubAreasService,
                private rolesService: RolesService,
                private authService: AuthService
    ) {

    }

    ngOnInit(): void {

        this.dataSource = new MatTableDataSource();
        this.dataSourceUsers = new MatTableDataSource();
        this.dataSourceTodosResponsible = new MatTableDataSource();
        this.dataSourceNotResponsible = new MatTableDataSource();

        this.searchForm = new FormGroup({
            user: new FormControl()
        })

        this.searchFormUsers = new FormGroup({
            user: new FormControl(),
            subArea: new FormControl()
        })

        this.loadData()
        this.loadDataUsers()

    }

    loadData() {
        const that = this
        this.initializeData().then(() => {
            this.dataSource.data = that.userList;
        })
        const result = [];
        const list = this.users.list('', 0, 1000000, '', false);
        list.pipe(takeUntil(this.destroying$)).subscribe((data) => {

            // @ts-ignore
            let userList = data.data;

            const list = this.responsibleService.list('', 0, 100000, '');
            list.pipe(takeUntil(this.destroying$)).subscribe((data) => {


                let resposibleList = []
                let users = '';
                let usersNot = [];
                // @ts-ignore
                data.data.forEach(record => {

                    console.log(record)
                    const responsable = userList.filter(user => user.ID === record.user_id);
                    if (responsable.length > 0) {
                        if (record.users !== '') {
                            if (record.users === 'TODOS') {
                                resposibleList.push(responsable[0])
                            } else {
                                users+= record.users + ',';
                            }
                        }
                    }
                });

                usersNot = users.split(",")
                usersNot = userList.filter(user => !usersNot.includes(user.ID.toString()));

                that.dataSourceNotResponsible.data = usersNot;
                that.dataSourceTodosResponsible.data = resposibleList;


            });
        });
    }

    loadDataUsers() {
        const that = this
        this.initializeDataUsers().then(() => {
            this.dataSourceUsers.data = that.userListUsers;
        })
    }


    private getSearch() {
        return {
            name: this.searchForm.get("user").value ? '%' + this.searchForm.get("user").value.toString().toUpperCase() + '%' : ''
        }
    }

    private getSearchUsers() {
        return {
            name: this.searchFormUsers.get("user").value ? '%' + this.searchFormUsers.get("user").value.toString().toUpperCase() + '%' : '',
            subArea: parseInt(this.searchFormUsers.get("subArea").value)
        }
    }

    async initializeData() {
        const that = this;
        return await new Promise<void>((resolve, reject) => {
            const list = this.users.list(JSON.stringify(that.getSearch()), that.currentPage, that.pageSize, '', false);
            list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
                // @ts-ignore
                that.userList = data.data;
                that.paginator.pageIndex = that.currentPage;
                // @ts-ignore
                that.paginator.length = data.total_records;
                resolve();
            });
        });
    }

    async initializeDataUsers() {
        const that = this;
        return await new Promise<void>((resolve, reject) => {
            const list = this.users.list(JSON.stringify(that.getSearchUsers()), that.currentPageUsers, that.pageSizeUsers, '', false);
            list.pipe(takeUntil(this.destroying$)).subscribe((data) => {

                // @ts-ignore
                that.userListUsers = data.data;

                if (that.responsible) {
                    if (that.optionsSelected === 'SELECTED') {
                        that.userListUsers = that.userListUsers.filter(user => this.getChecked(user))
                    } else if (that.optionsSelected === 'NOT_SELECTED') {
                        that.userListUsers = that.userListUsers.filter(user => !this.getChecked(user))
                    }
                }

                that.paginatorUsers.pageIndex = that.currentPageUsers;
                // @ts-ignore
                that.paginatorUsers.length = data.total_records;

                let list = this.subAreasService.list('', 0, 10000, '');
                list.pipe(takeUntil(this.destroying$)).subscribe((data) => {

                    // @ts-ignore
                    that.subAreasList = data.data;
                    resolve();
                });

            });
        });
    }

    pageChanged(event: PageEvent) {
        console.log({event});
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        this.loadData();
    }

    pageChangedUsers(event: PageEvent) {
        this.pageSizeUsers = event.pageSize;
        this.currentPageUsers = event.pageIndex;
        this.loadDataUsers();
    }

    trackByProperty<T>(index: number, column: TableColumn<T>) {
        return column.property;
    }

    get visibleColumns() {
        return this.columns.filter(column => column.visible).map(column => column.property);
    }

    get visibleColumnsSelected() {
        return this.columnsSelected.filter(column => column.visible).map(column => column.property);
    }

    selectResponsible(user: User) {
        //console.log(user)
        const that = this;
        // @ts-ignore
        this.responsible = user;
        this.selectedUser = [];
        this.loadDataUsers();
        const list = this.responsibleService.get(user.ID);
        list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
            that.projectData = data.projects;
            // @ts-ignore
            if (data.users === 'TODOS') {
                const list = this.users.list('', 0, 10000, '', false);
                list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
                    // @ts-ignore
                    that.selectedUser = data.data.map(user => user.ID.toString())
                });

            } else {
                that.selectedUser = data.users.split(',');
            }
            that.loadDataUsers();
        });
    }

    buscar() {
        this.loadData();
    }

    buscarUsers() {
        this.loadDataUsers();
    }

    selectUser(event: MatSlideToggleChange, user: User) {
        //console.log('toggle', event);
        if (event.checked) {
            // @ts-ignore
            this.selectedUser.push(user.ID);
        } else {
            // @ts-ignore
            this.selectedUser.splice(this.selectedUser.findIndex(item => item === user.ID), 1)
        }
    }

    getChecked(user: User) {
        // @ts-ignore
        return this.selectedUser.findIndex(item => item === user.ID.toString()) !== -1;
    }

    isResponsible(user: User) {
        if (!this.responsible) return false;
        return this.responsible.ID === user.ID;
    }

    mostrar(tipo: any) {
        this.optionsSelected = tipo;
        this.loadDataUsers();
    }

    guardar() {

        const that = this;
        if (this.selectedUser.length > 0) {

            this.selectedUser = this.selectedUser.filter(function (item) {
                return item !== ''
            })

            const responsible = {
                user_id: this.responsible.ID,
                users: this.selectedUser.join(","),
                projects: this.projectData
            }
            this.responsibleService.update(responsible).subscribe({
                next(result) {
                    that.handleSuccessResponse();
                },
                error(err) {
                    that.handleWrongResponse(err);
                }
            });
        }

    }

    handleSuccessResponse() {
        this.reset();
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            const message = res.USUARIO.UPDATE
            this.snackbar.open(message, '', {
                duration: 3000
            });
        });
    }

    handleWrongResponse(err) {
        this.reset();
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            const message = res.USUARIO.UPDATE_ERROR
            this.snackbar.open(message, '', {
                duration: 3000
            });
        });
    }

    deselectAll() {
        const that = this;
        const responsible = {
            user_id: this.responsible.ID,
            users: '',
            projects: this.projectData
        }
        this.responsibleService.update(responsible).subscribe({
            next(result) {
                that.handleSuccessResponse();
            },
            error(err) {
                that.handleWrongResponse(err);
            }
        });
    }

    selectAll() {
        const that = this;
        const responsible = {
            user_id: this.responsible.ID,
            users: 'TODOS',
            projects: this.projectData
        }
        this.responsibleService.update(responsible).subscribe({
            next(result) {
                that.handleSuccessResponse();
            },
            error(err) {
                that.handleWrongResponse(err);
            }
        });
    }


    reset() {
        this.optionsSelected = 'ALL';
        this.selectedUser = [];
        this.responsible = null;
        this.searchForm.get("user").reset()
        this.searchFormUsers.get("user").reset();
        this.searchFormUsers.get("subArea").reset();
        this.loadDataUsers();
        this.loadData();
    }

    selectAllSubArea() {
        this.selectedUser = this.userListUsers.map(user => user.ID.toString())
    }

    export() {
        let that = this;
        const result = [];
        const list = this.users.list('', 0, 1000000, '', false);
        list.pipe(takeUntil(this.destroying$)).subscribe((data) => {

            // @ts-ignore
            let userList = data.data;

            const list = this.responsibleService.list('', 0, 100000, '');
            list.pipe(takeUntil(this.destroying$)).subscribe((data) => {


                // that.userList
                // @ts-ignore
                data.data.forEach(record => {

                    // console.log(record)

                    const responsable = userList.filter(user => user.ID === record.user_id);
                    if (responsable.length > 0) {
                        if (record.users !== '') {
                            if (record.users === 'TODOS') {
                                userList.forEach(user => {
                                    const data = {
                                        Rut_Responsable: '',
                                        Responsable: '',
                                        Rut_Usuario: '',
                                        Usuario: '',
                                    }
                                    data.Rut_Responsable = responsable[0].rut;
                                    data.Responsable = responsable[0].lastName + ' ' + responsable[0].firstName;
                                    data.Rut_Usuario = user.rut;
                                    data.Usuario = user.lastName + ' ' + user.firstName;
                                    result.push(data)
                                })
                            } else {
                                const users = record.users.split(',');
                                users.forEach(userId => {
                                    const user = userList.filter(user => user.ID === Number(userId));
                                    if (user.length !== 0) {
                                        const data = {
                                            Rut_Responsable: '',
                                            Responsable: '',
                                            Rut_Usuario: '',
                                            Usuario: '',
                                        }
                                        data.Rut_Responsable = responsable[0].rut;
                                        data.Responsable = responsable[0].lastName + ' ' + responsable[0].firstName;
                                        data.Usuario = user[0].lastName + ' ' + user[0].firstName;
                                        data.Rut_Usuario = user[0].rut;
                                        result.push(data)
                                    }
                                })
                            }
                        }
                    }
                });
                TableUtil.exportArrayToExcel(result, "Responsible_users");
            });

        });
    }
}
