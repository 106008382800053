import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CommonBackendModule} from "@core/services/backend/common-backend.module";
import {throwIfAlreadyLoaded} from "@core/guard/module-import.guard";


export const NB_CORE_PROVIDERS = [
  ...CommonBackendModule.forRoot().providers
];

@NgModule({
  declarations: [],
  imports: [CommonModule,
    CommonBackendModule],
  providers: [
    ...NB_CORE_PROVIDERS,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
