import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {QuicklinkModule} from "ngx-quicklink";
import {HoursWorkedComponent} from "@app/pages/activity-management/hours-worked/hours-worked.component";
import {ActivityAssignmentComponent} from './activity-assignment/activity-assignment.component';
import {AuthGuard} from "@core/guard/auth.guard";

const routes: Routes = [
    // {
    //     path: "activity-planning",
    //     component: ActivityPlanningComponent,
    //     canActivate: [AuthGuard],
    //     data: {role: 'ROLE_ADMIN'}
    // },
    {
        path: "activity-assignment",
        component: ActivityAssignmentComponent,
        canActivate: [AuthGuard],
        data: {role: 'SUPER_ADMIN,ADMIN,COORDINATOR'}
    },
    {
        path: "hours-worked",
        component: HoursWorkedComponent,
        canActivate: [AuthGuard],
        data: {role: 'SUPER_ADMIN,ADMIN,COORDINATOR,USER'}
    },

];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule, QuicklinkModule]
})
export class ActivityManagementRoutingModule {
}
