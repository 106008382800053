import {Component, OnInit} from '@angular/core';
import {Notification} from '../interfaces/notification.interface';
import {Subject, takeUntil} from "rxjs";
import {NotificationsService} from "@core/services/backend/common/service/notifications.service";
import {DateTime} from "luxon";
import {AuthService} from "@core/guard/auth-service";
import {
    ActivityAssignmentCommentsComponent
} from "@app/pages/activity-management/activity-assignment-comments/activity-assignment-comments.component";
import {MatDialog} from "@angular/material/dialog";
import {WorkedHoursService} from "@core/services/backend/common/service/workedHours.service";
import moment from "moment/moment";

@Component({
    selector: 'vex-toolbar-notifications-dropdown',
    templateUrl: './toolbar-notifications-dropdown.component.html',
    styleUrls: ['./toolbar-notifications-dropdown.component.scss']
})
export class ToolbarNotificationsDropdownComponent implements OnInit {

    private readonly destroying$ = new Subject<void>();

    notifications: Notification[] = []
    length: number = 0;

    constructor(private notificationsService: NotificationsService,
                private dialog: MatDialog,
                private workedHoursService: WorkedHoursService,
                private authService: AuthService) {

        let list = this.notificationsService.list('', 0, 10000, 'id desc');
        list.pipe(takeUntil(this.destroying$)).subscribe((data) => {

            let newData = [];
            this.length = 0;
            // @ts-ignore
            data.data.forEach(reg => {

                const date1 = DateTime.fromISO(reg.UpdatedAt)
                const date2 = DateTime.now()
                const diff = date2.diff(date1, ["months", "days", "hours"])

                if (this.authService.UserId === reg.user_id) {
                    if (!reg.read_it) {
                        this.length++;
                    }
                    newData.push({
                        id: reg.ID,
                        type: reg.type,
                        message: reg.message,
                        aux_id: reg.aux_id,
                        read_it: reg.read_it,
                        user_id: reg.user_id,
                        label: reg.message,
                        icon: !reg.read_it ? 'mat:check_circle' : '',
                        colorClass: reg.message.indexOf('_OK') != -1 ? 'text-primary' : 'text-red',
                        datetime: DateTime.fromISO(reg.UpdatedAt).minus({
                            month: diff.months,
                            hour: diff.hours,
                            minute: diff.minutes
                        })
                    });
                    //console.log(DateTime.fromISO(reg.UpdatedAt))
                }
            });

            this.notifications = newData;
        });

    }

    ngOnInit() {
    }

    markAllAsRead() {

        let notifications = []
        this.notifications.forEach((notification) => {
            let noti = {}
            // @ts-ignore
            noti.id = notification.id;
            // @ts-ignore
            noti.type = notification.type;
            // @ts-ignore
            noti.read_it = true;
            // @ts-ignore
            noti.user_id = notification.user_id;
            // @ts-ignore
            noti.message = notification.message;
            // @ts-ignore
            noti.aux_id = notification.aux_id;

            notifications.push(noti);
        })

        this.notificationsService.update(notifications).subscribe({
            next(result) {
            },
            error(err) {
            }
        });
    }

    goNotification(notification: Notification) {

        const that = this;
        // @ts-ignore
        switch (notification.type) {

            case  'WORKED_HOURS':

                const notificationSearch = {}
                // @ts-ignore
                notificationSearch.id = notification.aux_id
                // @ts-ignore
                this.workedHoursService.get(notificationSearch).subscribe({
                    next(result) {

                        // @ts-ignore
                        const data = {}
                        // @ts-ignore
                        data.id = result.id
                        // @ts-ignore
                        data.user= result.user.firstName + ' ' + result.user.lastName;
                        // @ts-ignore
                        data.project = result.project.code + ' ' + result.project.name;
                        // @ts-ignore
                        data.date= moment(result.date).format('DD-MM-YYYY');
                        // @ts-ignore
                        data.status= result.status;
                        // @ts-ignore
                        data.comments= result.comments;
                        // @ts-ignore
                        data.statusComments= result.statusComment;

                        that.dialog.open(ActivityAssignmentCommentsComponent, {
                            data: data,
                            disableClose: false,
                            width: '800px',
                            height: '400px'
                        }).afterClosed().subscribe(result => {
                        });
                    },
                    error(err) {
                    }
                });

                const data = {
                    aaa: ''
                }

                break;

        }

    }
}
