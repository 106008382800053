import {mergeDeep} from '../utils/merge-deep';
import {VexConfigName} from './config-name.model';
import {VexConfig} from './vex-config.interface';
import {ColorSchemeName} from './colorSchemeName';
import {colorVariables} from '../components/config-panel/color-variables';

const defaultConfig: VexConfig = {
    id: VexConfigName.ares,
    name: 'Ares',
    style: {
        colorScheme: ColorSchemeName.light,
        colors: {
            primary: colorVariables.blue
        },
        borderRadius: {
            value: 0.25,
            unit: 'rem'
        },
        button: {
            borderRadius: undefined
        }
    },
    direction: 'ltr',
    imgSrc: '',
    layout: 'horizontal',
    boxed: false,
    sidenav: {
        title: '',
        imageUrl: 'assets/img/dessau.png',
        showCollapsePin: false,
        user: {
            visible: false
        },
        search: {
            visible: false
        },
        state: 'expanded'
    },
    toolbar: {
        fixed: false,
        user: {
            visible: true
        }
    },
    navbar: {
        position: 'in-toolbar'
    },
    footer: {
        visible: true,
        fixed: false
    }
};

export const configs: VexConfig[] = [
    defaultConfig,
    mergeDeep({...defaultConfig}, {
        id: VexConfigName.poseidon,
        name: 'Poseidon',
        imgSrc: '//vex-landing.visurel.com/assets/img/layouts/poseidon.png',
        style: {
            borderRadius: {
                value: 0.5,
                unit: 'rem'
            },
            button: {
                borderRadius: {
                    value: 9999,
                    unit: 'px'
                }
            }
        },
        sidenav: {
            user: {
                visible: true
            },
            search: {
                visible: true
            }
        },
        toolbar: {
            user: {
                visible: false
            }
        },
        footer: {
            fixed: false
        }
    }),
    mergeDeep({...defaultConfig}, {
        id: VexConfigName.hermes,
        name: 'Hermes',
        imgSrc: '//vex-landing.visurel.com/assets/img/layouts/hermes.png',
        layout: 'vertical',
        boxed: true,
        sidenav: {
            user: {
                visible: false
            },
            search: {
                visible: false
            },
        },
        toolbar: {
            fixed: false
        },
        footer: {
            fixed: false
        },
    }),
    mergeDeep({...defaultConfig}, {
        id: VexConfigName.ares,
        name: 'Ares',
        imgSrc: '//vex-landing.visurel.com/assets/img/layouts/ares.png',
        sidenav: {
            user: {
                visible: false
            },
            search: {
                visible: false
            },
        },
        toolbar: {
            fixed: false
        },
        navbar: {
            position: 'in-toolbar'
        },
        footer: {
            fixed: false
        },
    }),
    mergeDeep({...defaultConfig}, {
        id: VexConfigName.zeus,
        name: 'Zeus',
        imgSrc: '//vex-landing.visurel.com/assets/img/layouts/zeus.png',
        sidenav: {
            state: 'collapsed'
        },
    }),
    mergeDeep({...defaultConfig}, {
        id: VexConfigName.ikaros,
        name: 'Ikaros',
        imgSrc: '//vex-landing.visurel.com/assets/img/layouts/ikaros.png',
        layout: 'vertical',
        boxed: true,
        sidenav: {
            user: {
                visible: false
            },
            search: {
                visible: false
            },
        },
        toolbar: {
            fixed: false
        },
        navbar: {
            position: 'in-toolbar'
        },
        footer: {
            fixed: false
        }
    })
];
