import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivityPlanningComponent} from "./activity-planning/activity-planning.component";
import {ActivityManagementRoutingModule} from "./activity-management-routing.module";
import {TranslateModule} from "@ngx-translate/core";
import {MatIconModule} from "@angular/material/icon";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatButtonModule} from "@angular/material/button";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatMenuModule} from "@angular/material/menu";
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {HoursWorkedComponent} from './hours-worked/hours-worked.component';
import {MatTabsModule} from "@angular/material/tabs";
import {MatSortModule} from "@angular/material/sort";
import {MatDialogModule} from "@angular/material/dialog";
import {MatDividerModule} from "@angular/material/divider";
import {SidenavModule} from "@vex/layout/sidenav/sidenav.module";
import {ToolbarModule} from "@vex/layout/toolbar/toolbar.module";
import {ConfigPanelModule} from "@vex/components/config-panel/config-panel.module";
import {VexModule} from "@vex/vex.module";
import {QuickpanelModule} from "@vex/layout/quickpanel/quickpanel.module";
import {SidebarModule} from "@vex/components/sidebar/sidebar.module";
import {BreadcrumbsModule} from "@vex/components/breadcrumbs/breadcrumbs.module";
import {PageLayoutModule} from "@vex/components/page-layout/page-layout.module";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { ActivityAssignmentCommentsComponent } from './activity-assignment-comments/activity-assignment-comments.component';
import {MatChipsModule} from "@angular/material/chips";
import { ActivityAssignmentApproveComponent } from './activity-assignment-approve/activity-assignment-approve.component';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {
    ActivityAssignmentComponent
} from "@app/pages/activity-management/activity-assignment/activity-assignment.component";
import { ActivityAssigmentDeclineComponent } from './activity-assigment-decline/activity-assigment-decline.component';
import { ActivityAssigmentPendingComponent } from './activity-assigment-pending/activity-assigment-pending.component';
import {MatBadgeModule} from "@angular/material/badge";

@NgModule({
    declarations: [ActivityPlanningComponent, HoursWorkedComponent, ActivityAssignmentComponent, ActivityAssignmentCommentsComponent, ActivityAssignmentApproveComponent, ActivityAssigmentDeclineComponent, ActivityAssigmentPendingComponent],
    imports: [
        CommonModule,
        SidenavModule,
        ToolbarModule,
        QuickpanelModule,
        VexModule,
        ConfigPanelModule,
        SidebarModule,
        ActivityManagementRoutingModule,
        PageLayoutModule,
        TranslateModule,
        BreadcrumbsModule,
        MatIconModule,
        ReactiveFormsModule,
        MatTooltipModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatInputModule,
        MatSelectModule,
        MatMenuModule,
        MatTableModule,
        MatPaginatorModule,
        MatCheckboxModule,
        FormsModule,
        MatTabsModule,
        MatSortModule,
        MatDialogModule,
        MatDividerModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        MatChipsModule,
        MatSlideToggleModule,
        MatBadgeModule,
    ]
})
export class ActivityManagementModule {
}
