import {AfterViewInit, Directive, ElementRef, Injector, Input} from '@angular/core';
import {MatInput} from "@angular/material/input";
import {MatFormField, MatFormFieldControl} from "@angular/material/form-field";
import {TranslateService} from "@ngx-translate/core";

@Directive({
    selector: '[matErrorMessages]'
})
export class MatErrorMessagesDirective implements AfterViewInit {

    @Input('matErrorMessages') error: string;
    inputRef: MatFormFieldControl<MatInput>;

    constructor(private _inj: Injector,
                private el: ElementRef,
                private translate: TranslateService) {
    }

    // Setup all initial tooling
    ngAfterViewInit() {
        // grab reference to MatFormField directive, where form control is accessible.
        let container = this._inj.get(MatFormField);
        this.inputRef = container._control;

        // sub to the control's status stream
        this.inputRef.ngControl.statusChanges.subscribe(this.updateErrors);
    }

    // This grabs a single active error instead of multiple.
    private updateErrors = (state: 'VALID' | 'INVALID') => {
        if (state === 'INVALID') {
            let controlErrors = this.inputRef.ngControl.errors;
            const firstError = Object.keys(controlErrors)[0];
            console.log(controlErrors);
            if (firstError === 'required' || firstError === 'conditionalRequired')
                this.translate.getTranslation(this.translate.currentLang).subscribe(res => {
                    this.el.nativeElement.innerHTML = res.LABELS.PROYECTO_COMENTARIOS_REQUERIDO
                });
            this.error = 'This field is required.';

            if (firstError === 'minlength')
                this.error = 'This field should be longer.';

            if (firstError === 'pattern')
                this.error = 'Numbers only!';

            if (firstError === 'error from my own custom validator')
                this.error = 'You get the point.';
            // .....
        }
    }
}
