import {Component, Inject, OnInit} from '@angular/core';
import moment from "moment/moment";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {MatTableDataSource} from "@angular/material/table";
import {Group} from "@core/services/backend/common/interface/groups";
import {TableColumn} from "@vex/interfaces/table-column.interface";
import {Link} from "@core/services/backend/common/interface/links";
import {fadeInUp400ms} from "@vex/animations/fade-in-up.animation";
import {stagger40ms} from "@vex/animations/stagger.animation";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {WorkedHoursService} from "@core/services/backend/common/service/workedHours.service";
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "@core/guard/auth-service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'vex-activity-assigment-decline',
    templateUrl: './activity-assigment-decline.component.html',
    styleUrls: ['./activity-assigment-decline.component.scss'],
    animations: [
        fadeInUp400ms,
        stagger40ms
    ]
})
export class ActivityAssigmentDeclineComponent implements OnInit {

    userName = '';
    userId = '';
    fecha = '';
    origin;
    tipo;
    declineForm: FormGroup;

    dataSource: MatTableDataSource<Group> | null;

    columns: TableColumn<Link>[] = [
        {label: 'GRIDS.USUARIO', property: 'userName', type: 'text', visible: true},
        {label: 'GRIDS.PROYECTO', property: 'projectName', type: 'text', visible: true},
        {label: 'GRIDS.FECHA_ACTUALIZACION', property: 'date', type: 'text', visible: true},
        {label: 'GRIDS.ESTADO', property: 'status', type: 'text', visible: true},
        {label: 'GRIDS.CANTIDAD', property: 'count', type: 'text', visible: true},
        {label: 'GRIDS.HHEE', property: 'extras', type: 'text', visible: true},
    ];

    statusList = ["PENDIENTE", "RECHAZADA", "ACEPTADA"]

    constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
                private fb: FormBuilder,
                private workedHoursService: WorkedHoursService,
                private translateService: TranslateService,
                private snackbar: MatSnackBar,
                private authService: AuthService) {
        console.log(defaults)
        this.fecha = moment(defaults.dateStart).format('DD-MM-YYYY') + ' - ' + moment(defaults.dateEnd).format('DD-MM-YYYY')
        this.tipo = defaults.tipo;
        this.origin = defaults.hours;
    }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource();
        this.dataSource.data = [...this.origin];

        this.declineForm = this.fb.group({
                comments: new FormControl('', Validators.required)
            }
        );

    }

    trackByProperty<T>(index: number, column: TableColumn<T>) {
        return column.property;
    }

    get visibleColumns() {
        return this.columns.filter(column => column.visible).map(column => column.property);
    }

    save() {
        const that = this;
        if (this.declineForm.valid) {

            const payload = []
            that.origin.forEach((hour) => {

                const record: {} = {}

                // @ts-ignore
                record.id = hour.ID;
                // @ts-ignore
                record.projectId = hour.projectId;
                // @ts-ignore
                record.userId = hour.userId;
                // @ts-ignore
                record.date = hour.dateCreated;
                // @ts-ignore
                record.count = hour.count;
                // @ts-ignore
                record.extras = hour.extras;
                // @ts-ignore
                record.comments = hour.comments;
                // @ts-ignore
                record.isAddOrUpdate = true

                if (this.tipo === 'RECHAZAR') {
                    // @ts-ignore
                    record.status = "RECHAZADA";
                    // @ts-ignore
                    record.statusComments = hour.statusComment + moment().format("DD-MM-YYYY") + "|" + this.authService.User + "|RECHAZADA|" + this.declineForm.value.comments + "##";
                } else if (this.tipo === 'COMENTAR') {
                    // @ts-ignore
                    record.status = hour.status;
                    // @ts-ignore
                    record.statusComments = hour.statusComment + moment().format("DD-MM-YYYY") + "|" + this.authService.User + "|" + hour.status + "|" + this.declineForm.value.comments + "##";
                }
                // @ts-ignore
                record.userUpdated = that.authService.User;
                // @ts-ignore
                record.userUpdatedId = that.authService.UserId;
                // @ts-ignore
                record.userApproved = that.authService.User;


                payload.push(record);

            });

            this.workedHoursService.create(payload).subscribe({
                next(result) {
                    that.handleSuccessResponse();
                    this.loading = false;
                },
                error(err) {
                    this.loading = false;
                    that.handleWrongResponse(err);
                }
            });

        }

    }

    handleSuccessResponse() {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            this.snackbar.open(res.LABELS.SUCCESS, '', {
                duration: 3000,
                panelClass: ['green-snackbar']
            });
        });

    }

    handleWrongResponse(err) {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            this.snackbar.open(res.LABELS.ERROR, '', {
                duration: 3000,
                panelClass: ['red-snackbar']
            });
        });
    }

}
