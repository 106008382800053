import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SubAreasApi} from "@core/services/backend/common/api/subAreas.api";
import {SubArea, SubAreasData} from "@core/services/backend/common/interface/subAreas";


@Injectable()
export class SubAreasService extends SubAreasData {

    constructor(private api: SubAreasApi) {
        super();
    }

    list(search: string, pageNumber: number, pageSize: number, orderBy: string): Observable<any[]> {
        return this.api.list(search, pageNumber, pageSize, orderBy);
    }

    get(id: number, cloudId: number): Observable<SubArea> {
        return this.api.get(id, cloudId);
    }

    // tslint:disable-next-line:no-shadowed-variable
    create(param: any): Observable<SubArea> {
        return this.api.add(param);
    }

    // tslint:disable-next-line:no-shadowed-variable
    update(param: any): Observable<SubArea> {
        return this.api.update(param);
    }

    delete(param: any): Observable<SubArea> {
        return this.api.delete(param);
    }


}
