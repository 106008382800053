import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {NotificationsApi} from "@core/services/backend/common/api/notifications.api";
import {Notifications, NotificationsData} from "@core/services/backend/common/interface/notifications";


@Injectable()
export class NotificationsService extends NotificationsData {

    constructor(private api: NotificationsApi) {
        super();
    }

    list(search: string, pageNumber: number, pageSize: number, orderBy: string): Observable<any[]> {
        return this.api.list(search, pageNumber, pageSize, orderBy);
    }

    get(id: number, cloudId: number): Observable<Notifications> {
        return this.api.get(id);
    }

    // tslint:disable-next-line:no-shadowed-variable
    create(notifications: any): Observable<Notifications> {
        return this.api.add(notifications);
    }

    // tslint:disable-next-line:no-shadowed-variable
    update(notifications: any): Observable<Notifications> {
        return this.api.update(notifications);
    }

    delete(notifications: any): Observable<Notifications> {
        return this.api.delete(notifications);
    }


}
