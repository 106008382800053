import {Observable} from 'rxjs';
import {Project} from "@core/services/backend/common/interface/projects";

export interface AutoHours {
    userId: number;
    days: string;
}

export abstract class AutoHoursData {

    abstract list(search: string, pageNumber: number, pageSize: number, orderBy: string): Observable<any>;

    abstract get(id: number): Observable<AutoHours>;

    abstract update(item: AutoHours): Observable<AutoHours>;

    abstract create(item: AutoHours): Observable<AutoHours>;

    abstract delete(item: AutoHours): Observable<AutoHours>;


}
