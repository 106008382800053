import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, UntypedFormBuilder} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {ProjectsService} from "@core/services/backend/common/service/projects.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {User} from "@core/services/backend/common/interface/users";
import {LinksService} from "@core/services/backend/common/service/links.service";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {Group} from "@core/services/backend/common/interface/groups";
import {GroupsService} from "@core/services/backend/common/service/groups.service";
import {map, startWith} from "rxjs/operators";
import {Subject, takeUntil} from "rxjs";
import {MatChipInputEvent} from "@angular/material/chips";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";

@Component({
  selector: 'vex-external-links-create-update',
  templateUrl: './external-links-create-update.component.html',
  styleUrls: ['./external-links-create-update.component.scss']
})
export class ExternalLinksCreateUpdateComponent implements OnInit {

    private readonly destroying$ = new Subject<void>();
    static id = 100;

    form: FormGroup;
    mode: 'create' | 'update' = 'create';
    groupList: Group[] = [];
    separatorKeysCodes: number[] = [ENTER, COMMA];
    groupsCtrl = new FormControl('');
    selectedGroups: Group[] = [];
    filteredGroups;

    @ViewChild('groupInput') userInput: ElementRef<HTMLInputElement>;

    constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
                private dialogRef: MatDialogRef<ExternalLinksCreateUpdateComponent>,
                private fb: UntypedFormBuilder,
                private translateService: TranslateService,
                private links: LinksService,
                private snackbar: MatSnackBar,
                private groups: GroupsService) {

        this.filteredGroups = this.groupsCtrl.valueChanges.pipe(
            startWith<string | any>(''),
            //map(value => typeof value === 'string' ? value : value.firstName + ' ' + value.lastName),
            map(user => (user ? this._filter(user) : this.groupList.slice())),
        );

        console.log(defaults);
        if (defaults != null) {
            defaults.ExternalLinksGroups.forEach(data => {
                this.selectedGroups.push(data.Groups)
            });
        }

    }

    ngOnInit() {
        if (this.defaults) {
            this.mode = 'update';
        } else {
            this.defaults = {} as User;
        }

        this.initializeData();

        this.form = this.fb.group({
            id: [ExternalLinksCreateUpdateComponent.id++],
            name: [this.defaults.name || ''],
            link: [this.defaults.link || ''],
            group: [this.defaults.group || ''],
            active:  [this.defaults.active || false],
        });
    }

    async initializeData() {
        const that = this;
        return await new Promise<void>((resolve, reject) => {
            const list = this.groups.list('', 0, 100000, '');
            list.pipe(takeUntil(this.destroying$)).subscribe((data) => {
                // @ts-ignore
                that.groupList = data.data;
                resolve();
            });
        });
    }

    save() {
        // console.log(this.mode)
        if (this.mode === 'create') {
            this.create();
        } else if (this.mode === 'update') {
            this.update();
        }
    }

    create() {
        const that = this;
        if (this.form.valid) {
            const links = {
                name: this.form.value.name,
                link: this.form.value.link,
                active: this.form.value.active,
                groups: this.selectedGroups,
                parent: 'BASE_EXTERNAL_LINKS'
            }
            this.links.create(links).subscribe({
                next(result) {
                    that.handleSuccessResponse();
                    that.dialogRef.close(links);
                },
                error(err) {
                    that.handleWrongResponse(err);
                }
            });
        }

    }

    update() {
        const that = this;
        if (this.form.valid) {
            const project = {
                id: this.defaults.ID,
                name: this.form.value.name,
                link: this.form.value.link,
                active: this.form.value.active,
                groups: this.selectedGroups,
                externalLinksDetails: []
            }
            this.links.create(project).subscribe({
                next(result) {
                    that.handleSuccessResponse();
                    that.dialogRef.close(project);
                },
                error(err) {
                    that.handleWrongResponse(err);
                }
            });
        }
    }

    isCreateMode() {
        return this.mode === 'create';
    }

    isUpdateMode() {
        return this.mode === 'update';
    }

    handleSuccessResponse() {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            const message = this.isCreateMode() ? res.LINKS.SUCCESS : res.LINKS.UPDATE
            this.snackbar.open(message, '', {
                duration: 3000
            });
        });
    }

    handleWrongResponse(err) {
        this.translateService.getTranslation(this.translateService.currentLang).subscribe(res => {
            const message = this.isCreateMode() ? res.LINKS.ERROR : res.LINKS.UPDATE_ERROR
            this.snackbar.open(message, '', {
                duration: 3000
            });
        });
    }

    add(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        console.log(value);
        if (value) {
            this.selectedGroups.push(null);
        }
        event.chipInput!.clear();
        this.groupsCtrl.setValue(null);
    }

    remove(group: Group): void {
        const index = this.selectedGroups.indexOf(group);
        if (index >= 0) {
            this.selectedGroups.splice(index, 1);
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        if ( !this.selectedGroups.includes(event.option.value)) {
            this.selectedGroups.push(event.option.value);
        }
        this.userInput.nativeElement.value = '';
        this.groupsCtrl.setValue(null);
    }

    private _filter(value: string): any[] {
        console.log('aca')
        // @ts-ignore
        const filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
        return this.groupList.filter(option => option.name.toLowerCase().indexOf(filterValue) != -1 );
    }

    displayUserFn(group: Group) {
        if (group) {
            return group.name;
        }
    }
}
