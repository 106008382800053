import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@environments/environment';

@Injectable()
export class HttpService {

  constructor(private http: HttpClient) {
  }

  get apiUrl(): string {
    return environment.apiUrl;
  }

  get(endpoint: string, options?): Observable<any> {
    return this.http.get(`${this.apiUrl}${endpoint}`, options);
  }

  post(endpoint: string, data, options?): Observable<any> {
    return this.http.post(`${this.apiUrl}${endpoint}`, data, options);
  }

  put(endpoint: string, data, options?): Observable<any> {
    return this.http.put(`${this.apiUrl}${endpoint}`, data, options);
  }

  delete(endpoint: string, options?): Observable<any> {
    return this.http.delete(`${this.apiUrl}${endpoint}`, options);
  }

}
