import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProjectsApi} from "@core/services/backend/common/api/projects.api";
import {HttpService} from "@core/services/backend/common/api/http.service";
import {ProjectsData} from "@core/services/backend/common/interface/projects";
import {ProjectsService} from "@core/services/backend/common/service/projects.service";
import {UsersApi} from "@core/services/backend/common/api/users.api";
import {UsersData} from './common/interface/users';
import {UsersService} from "@core/services/backend/common/service/users.service";
import {WorkedHoursApi} from "@core/services/backend/common/api/workedHours.api";
import {WorkedHoursData} from './common/interface/workedHours';
import {WorkedHoursService} from './common/service/workedHours.service';
import {SubAreasApi} from "@core/services/backend/common/api/subAreas.api";
import {SubAreasData} from './common/interface/subAreas';
import {SubAreasService} from './common/service/subAreas.service';
import {LinksApi} from "@core/services/backend/common/api/links.api";
import {LinksData} from './common/interface/links';
import {LinksService} from './common/service/links.service';
import {GroupsApi} from "@core/services/backend/common/api/groups.api";
import {GroupsData} from "@core/services/backend/common/interface/groups";
import {GroupsService} from "@core/services/backend/common/service/groups.service";
import {RolesApi} from "@core/services/backend/common/api/roles.api";
import {RoleData} from "@core/services/backend/common/interface/roles";
import {RolesService} from "@core/services/backend/common/service/roles.service";
import {NotificationsApi} from "@core/services/backend/common/api/notifications.api";
import {NotificationsData} from "@core/services/backend/common/interface/notifications";
import {NotificationsService} from "@core/services/backend/common/service/notifications.service";
import {ResponsibleApi} from "@core/services/backend/common/api/responsible.api";
import {ResponsibleData} from "@core/services/backend/common/interface/responsible";
import {ResponsibleService} from "@core/services/backend/common/service/responsible.service";
import {AutoHoursApi} from "@core/services/backend/common/api/autoHours.api";
import {AutoHoursData} from "@core/services/backend/common/interface/autoHours";
import {AutoHoursService} from "@core/services/backend/common/service/autoHours.service";


const API = [
    ProjectsApi,
    UsersApi,
    WorkedHoursApi,
    SubAreasApi,
    LinksApi,
    GroupsApi,
    RolesApi,
    NotificationsApi,
    ResponsibleApi,
    AutoHoursApi,
    HttpService];

const SERVICES_DATA = [
    {
        provide:
        ProjectsData,
        UsersData,
        WorkedHoursData,
        SubAreasData,
        LinksData,
        GroupsData,
        RoleData,
        NotificationsData,
        AutoHoursData,
        ResponsibleData,

        useClass:
        ProjectsService,
        UsersService,
        WorkedHoursService,
        SubAreasService,
        GroupsService,
        LinksService,
        RolesService,
        NotificationsService,
        ResponsibleService,
        AutoHoursService,

    }
];

const SERVICES = [

    ProjectsService,
    UsersService,
    WorkedHoursService,
    SubAreasService,
    GroupsService,
    LinksService,
    RolesService,
    NotificationsService,
    ResponsibleService,
    AutoHoursService,

];

@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ]
})

export class CommonBackendModule {
    static forRoot(): ModuleWithProviders<CommonBackendModule> {
        return {
            ngModule: CommonBackendModule,
            providers: [
                ...API,
                ...SERVICES_DATA,
                ...SERVICES
            ],
        };
    }
}
