import {Component, Inject, OnInit} from '@angular/core';
import {FormGroup, UntypedFormBuilder} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatTableDataSource} from "@angular/material/table";
import {Group} from "@core/services/backend/common/interface/groups";
import {TableColumn} from "@vex/interfaces/table-column.interface";
import {Link} from "@core/services/backend/common/interface/links";
import {fadeInUp400ms} from "@vex/animations/fade-in-up.animation";
import {stagger40ms} from "@vex/animations/stagger.animation";

@Component({
    selector: 'vex-activity-assignment-comments',
    templateUrl: './activity-assignment-comments.component.html',
    styleUrls: ['./activity-assignment-comments.component.scss'],
    animations: [
        fadeInUp400ms,
        stagger40ms
    ]
})
export class ActivityAssignmentCommentsComponent implements OnInit {

    userName = '';
    projectName = '';
    date     = ''
    comments=[];

    dataSource: MatTableDataSource<Group> | null;

    columns: TableColumn<Link>[] = [
        {label: 'GRIDS.FECHA_ACTUALIZACION',property: 'date', type: 'text', visible: true},
        {label: 'GRIDS.USUARIO', property: 'user', type: 'text', visible: true},
        {label: 'GRIDS.ESTADO', property: 'status', type: 'text', visible: true},
        {label: 'GRIDS.COMENTARIOS', property: 'comments', type: 'text', visible: true},
    ];

    constructor(@Inject(MAT_DIALOG_DATA) public defaults: any) {

        this.userName = defaults.user;
        this.projectName =  defaults.project;
        this.date = defaults.date;

        const status = defaults.statusComments ? defaults.statusComments.split("##"): [];
        status.forEach(status => {
            const comment = status.split("|");
            if ( comment.length > 0 && comment[0] !== '') {
                const comments = {
                    date: comment[0],
                    user: comment[1],
                    status: comment[2],
                    comments: comment[3]
                }
                // @ts-ignore
                this.comments.push(comments)
            }
        })

        this.dataSource = new MatTableDataSource();
        this.dataSource.data = [...this.comments];
    }

    ngOnInit(): void {

    }

    trackByProperty<T>(index: number, column: TableColumn<T>) {
        return column.property;
    }

    get visibleColumns() {
        return this.columns.filter(column => column.visible).map(column => column.property);
    }
}
