import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Group, GroupsData} from "@core/services/backend/common/interface/groups";
import {GroupsApi} from "@core/services/backend/common/api/groups.api";


@Injectable()
export class GroupsService extends GroupsData {

    constructor(private api: GroupsApi) {
        super();
    }

    list(search: string, pageNumber: number, pageSize: number, orderBy: string): Observable<any[]> {
        return this.api.list(search, pageNumber, pageSize, orderBy);
    }

    get(id: number, cloudId: number): Observable<Group> {
        return this.api.get(id, cloudId);
    }

    // tslint:disable-next-line:no-shadowed-variable
    create(Group: any): Observable<Group> {
        return this.api.add(Group);
    }

    // tslint:disable-next-line:no-shadowed-variable
    update(Group: any): Observable<Group> {
        return this.api.update(Group);
    }

    delete(Group: any): Observable<Group> {
        return this.api.delete(Group);
    }

}
